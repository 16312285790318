<div class="dropcart">
  <div class="dropcart__products-list">
    <div class="dropcart__product">
      <div class="dropcart__product-info">
        <a>Effettua una ricerca</a>
        <form [formGroup]="formRicerca" (ngSubmit)="effettuaLaRicerca(formRicerca.value)">
          <mat-form-field class="example-full-width w-100 pl-0 mt-3">
            <mat-label>Nome Prodotto</mat-label>
            <input matInput formControlName="nomeProdotto" autocomplete="off">
            <button mat-button *ngIf="nomeProdotto.value" matSuffix mat-icon-button aria-label="Cerca">
              <mat-icon style="font-size: 21px;">search</mat-icon>
            </button>
          </mat-form-field>
        </form>
      </div>
    </div>
  </div>
</div>
