import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { pageService } from '../account/pages/pageService';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  providers:[pageService]
})
export class HeaderComponent implements OnInit {
  @Input() layout: 'classic' | 'compact' = 'classic';

  constructor(
    public authService: AuthService,
  ) { }
  ngOnInit(): void {

  }
}
