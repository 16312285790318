import { Component, OnDestroy, OnInit, Output } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ProdottoModels } from 'src/app/shared/models/ProdottoModels';
import { pageService } from 'src/app/modules/account/pages/pageService';
import { PageCategoryService } from 'src/app/modules/shop/services/page-category.service';
import { filter, merge, switchMap, takeUntil, tap } from 'rxjs/operators';
import { BlockHeaderGroup } from 'src/app/modules/blocks/block-products-carousel/block-products-carousel.component';

export class NostriProdottiModel {
  src: string;
  link: string;
  titolo: string;
}

interface ProductsCarouselGroup extends BlockHeaderGroup {
  products$: ProdottoModels[];
}

interface ProductsCarouselData {
  abort$: Subject<void>;
  loading: boolean;
  products: ProdottoModels[];
  groups: ProductsCarouselGroup[];
}

@Component({
  selector: 'app-home',
  templateUrl: './page-home-one.component.html',
  styleUrls: ['./page-home-one.component.scss'],
  providers: [PageCategoryService]
})
export class PageHomeOneComponent implements OnInit, OnDestroy {

  @Output() header = 'Scegli il tuo sistema';


  destroy$: Subject<void> = new Subject<void>();
  sistema: any;

  featuredProducts!: ProductsCarouselData;


  public listaNostriProdotti: NostriProdottiModel[] = [
    {
      link: '/shop/catalog/kit__accessori',
      src: 'https://firebasestorage.googleapis.com/v0/b/boutiquerafactory.appspot.com/o/kit-accessori-caff%C3%A8.jpg?alt=media&token=799c2d07-8aa8-4bf1-8479-acce0ebd5fea',
      titolo: 'Kit Accessori'
    },
    {
      link: '/shop/catalog/food',
      src: 'https://firebasestorage.googleapis.com/v0/b/boutiquerafactory.appspot.com/o/img-prodotti%2Famirali-mirhashemian-RCVIlSXhYI0-unsplash-1.jpg?alt=media&token=2e0843b1-b928-4e09-bc39-9e05ef5b35c7',
      titolo: 'Cioccolato'
    },
    {
      link: '/shop/catalog/caffè_in_capsule',
      src: 'https://firebasestorage.googleapis.com/v0/b/boutiquerafactory.appspot.com/o/img-prodotti%2Fguia_cafe-png-1.jpg?alt=media&token=7930bfad-def3-43f4-8c99-44b350d53e53',
      titolo: 'Caffè in Capsule'
    },
    {
      link: '/shop/catalog/caffè_in_cialde',
      src: 'https://firebasestorage.googleapis.com/v0/b/boutiquerafactory.appspot.com/o/img-prodotti%2Fcalda-1-1.jpg?alt=media&token=cae84ca7-8502-47a3-b76b-7341840395ed',
      titolo: 'Caffè in Cialde'
    },
    {
      link: '/shop/catalog/macchinette',
      src: 'https://firebasestorage.googleapis.com/v0/b/boutiquerafactory.appspot.com/o/img-prodotti%2Fcaffeeeee-1.jpg?alt=media&token=38236be3-4ca4-4ea4-8c6d-a2134112c18b',
      titolo: 'Macchine'
    },
    {
      link: '/shop/catalog/the_e_tisane',
      src: 'https://firebasestorage.googleapis.com/v0/b/boutiquerafactory.appspot.com/o/img-prodotti%2Fnathan-dumlao-8yBQQqH3q8Q-unsplash-1.jpg?alt=media&token=54e42166-2bfc-46cc-befe-fc4b2e0f3705',
      titolo: 'Thè e Tisane'
    },
  ];

  public hidden: boolean = false;

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    responsive: {
      0: {
        items: 2
      },
      400: {
        items: 3
      },
      740: {
        items: 4
      },
      940: {
        items: 6
      }
    },
    nav: true
  }

  customOptionsAll: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 3
      },
      740: {
        items: 4
      },
      940: {
        items: 4
      }
    },
    nav: true
  }

  customOptionsOfferte: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 4
      }
    },
    nav: true
  }

  public loader: boolean = true;
  public listaProdotti: ProdottoModels[];
  public isDragging: boolean = false;

  constructor(private service: PageCategoryService) { }

  ngOnInit(): void {
    this.hidden = true;
    this.service.getAllProdottiInOfferta().pipe(
      filter((v) => !!v),
      tap((v) => { this.listaProdottiOfferta = v; this.hidden = false })
    ).subscribe();

    this.featuredProducts = {
      abort$: new Subject<void>(),
      loading: false,
      products: [],
      groups: [
        {
          name: 'Caffè in Cialde',
          current: true,
          products$: this.recuperaProdotti('Caffè in Cialde'),
        },
        {
          name: 'Caffè in Capsule',
          current: false,
          products$: this.recuperaProdotti('Caffè in Capsule'),
        },
        {
          name: 'Cioccolata',
          current: false,
          products$: this.recuperaProdotti('Cioccolata'),
        },
        {
          name: 'Kit Accessori',
          current: false,
          products$: this.recuperaProdotti('Kit Accessori'),
        },
        {
          name: 'Macchine',
          current: false,
          products$: this.recuperaProdotti('Macchine'),
        },
        {
          name: 'Thè e Tisane',
          current: false,
          products$: this.recuperaProdotti('Thè e Tisane'),
        },
      ],
    };
    this.groupChange(this.featuredProducts, this.featuredProducts.groups[0]);
  }

  recuperaProdotti(nomeCategoria: string): ProdottoModels[] {

    let prodottoRecuperato: ProdottoModels[];
    this.loader = true;


    switch (nomeCategoria) {
      case 'Caffè in Cialde':
        this.service.getProdottiHomePage('caffè_in_cialde').pipe(
          filter((v) => !!v),
          tap((v) => { prodottoRecuperato = v; this.listaProdotti = v })
        ).subscribe();
        return prodottoRecuperato;
        break;

      default:
        break;
    }

    setTimeout(() => {
      this.loader = false;
    }, 1);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onDrag(drag: boolean) {
    setTimeout(() => {
      this.isDragging = drag
    }, 100);
  }

  groupChange(carousel: ProductsCarouselData, group: BlockHeaderGroup): void {
    carousel.loading = true;
    this.loader = true;
    carousel.products = [];

    switch (group.name) {
      case 'Caffè in Cialde':
        this.service.getProdottiHomePage('caffè_in_cialde').pipe(
          filter((v) => !!v),
          tap((v) => carousel.products = v),
          tap((v) => this.listaProdotti = v)
        ).subscribe();
        break;

      case 'Caffè in Capsule':
        this.service.getProdottiHomePage('caffè_in_capsule').pipe(
          filter((v) => !!v),
          tap((v) => carousel.products = v),
          tap((v) => this.listaProdotti = v)
        ).subscribe();
        break;

      case 'Cioccolata':
        this.service.getProdottiHomePage('food').pipe(
          filter((v) => !!v),
          tap((v) => carousel.products = v),
          tap((v) => this.listaProdotti = v)
        ).subscribe();
        break;

      case 'Kit Accessori':
        this.service.getProdottiHomePage('kit__accessori').pipe(
          filter((v) => !!v),
          tap((v) => carousel.products = v),
          tap((v) => this.listaProdotti = v)
        ).subscribe();
        break;

      case 'Macchine':
        this.service.getProdottiHomePage('macchinette').pipe(
          filter((v) => !!v),
          tap((v) => carousel.products = v),
          tap((v) => this.listaProdotti = v)
        ).subscribe();
        break;

      case 'Thè e Tisane':
        this.service.getProdottiHomePage('the_e_tisane').pipe(
          filter((v) => !!v),
          tap((v) => carousel.products = v),
          tap((v) => this.listaProdotti = v)
        ).subscribe();
        break;

      default:
        break;
    }

    setTimeout(() => {
      this.loader = false;
    }, 500);

  }



  public listaProdottiOfferta: ProdottoModels[];

  slidesStore = [
    { src: 'https://firebasestorage.googleapis.com/v0/b/boutiquerafactory.appspot.com/o/img-prodotti%2Fa-modo-mio.png?alt=media&token=acedc2dd-2853-45f2-8e83-220a28da37d0', Titolo: 'A Modo Mio', link: '/shop/catalog/caffè_in_capsule/a_modo_mio' },
    { src: 'https://firebasestorage.googleapis.com/v0/b/boutiquerafactory.appspot.com/o/img-prodotti%2Fnespresso.png?alt=media&token=b2830e18-691e-4fc1-88e3-c42206c074ea', Titolo: 'Nespresso', link: '/shop/catalog/caffè_in_capsule/nespresso' },
    { src: 'https://firebasestorage.googleapis.com/v0/b/boutiquerafactory.appspot.com/o/img-prodotti%2Fdolce-gusto.png?alt=media&token=ad8193ee-fd83-4776-a7a9-a57b57561d48', Titolo: 'Dolce Gusto', link: '/shop/catalog/caffè_in_capsule/dolce_gusto' },
    { src: 'https://firebasestorage.googleapis.com/v0/b/boutiquerafactory.appspot.com/o/img-prodotti%2Flavazza-firma.png?alt=media&token=93744b5b-d559-4f0f-a91c-25913c9a33fa', Titolo: 'Lavazza Firma', link: '/shop/catalog/caffè_in_capsule/lavazza_firma' },
    { src: 'https://firebasestorage.googleapis.com/v0/b/boutiquerafactory.appspot.com/o/img-prodotti%2Fespresso-point.png?alt=media&token=0fbe42ec-0905-4554-bcf3-9e8ae02ce9cd', Titolo: 'Lavazza Point', link: '/shop/catalog/caffè_in_capsule/espresso_point' },
    { src: 'https://firebasestorage.googleapis.com/v0/b/boutiquerafactory.appspot.com/o/img-prodotti%2Fcaffitaly.png?alt=media&token=7809035c-9d83-42ad-ad73-97901aa178b6', Titolo: 'Caffitaly', link: '/shop/catalog/caffè_in_capsule/caffitaly' },
    { src: 'https://firebasestorage.googleapis.com/v0/b/boutiquerafactory.appspot.com/o/img-prodotti%2Fbialetti.png?alt=media&token=eeffca94-db75-4f33-aed7-4011b701b300', Titolo: 'Bialetti', link: '/shop/catalog/caffè_in_capsule/bialetti' },
    { src: 'https://firebasestorage.googleapis.com/v0/b/boutiquerafactory.appspot.com/o/img-prodotti%2Funo-system.png?alt=media&token=54d79026-f067-4ada-872a-bec2bf7ea88c', Titolo: 'Uno System', link: '/shop/catalog/caffè_in_capsule/uno_system' },
  ]

  slidesLoghi = [
    { src: 'https://firebasestorage.googleapis.com/v0/b/boutiquerafactory.appspot.com/o/img-prodotti%2Flavazza-2.jpg?alt=media&token=f3c6288d-dfc6-4156-8c5c-84c1942782e8' },
    { src: 'https://firebasestorage.googleapis.com/v0/b/boutiquerafactory.appspot.com/o/img-prodotti%2Ftoraldo.png?alt=media&token=16b7eec3-37cd-40e8-950b-6df56ab42bc4' },
    { src: 'https://firebasestorage.googleapis.com/v0/b/boutiquerafactory.appspot.com/o/img-prodotti%2Flollo-caffe-point-argento-pz-100-e1589546623908.png?alt=media&token=f94129df-2210-4653-a023-ee948d1827c2' },
    { src: 'https://firebasestorage.googleapis.com/v0/b/boutiquerafactory.appspot.com/o/img-prodotti%2Fcaffe-borbone-logo-e1589546609687.png?alt=media&token=3cc82b83-66a5-4ab0-9e13-f35fa2f7e9ed' },
    { src: 'https://firebasestorage.googleapis.com/v0/b/boutiquerafactory.appspot.com/o/img-prodotti%2Fcapsula-toda-caffe-blu-100-pz-e1589546641783.png?alt=media&token=0d8bbde8-293c-41a6-ac58-056b0c97fbb8' },
    { src: 'https://firebasestorage.googleapis.com/v0/b/boutiquerafactory.appspot.com/o/img-prodotti%2Flogo-ristora-e1589546538611.png?alt=media&token=cc23fd62-1b64-442a-b8c5-5dcac084eff9' },
    { src: 'https://firebasestorage.googleapis.com/v0/b/boutiquerafactory.appspot.com/o/img-prodotti%2Fgattopardo1-logo-2.jpg?alt=media&token=a2abbcdc-9a27-4900-85ac-1daac611ac4a' }
  ]
}
