<div class="account-menu">
  <div *ngIf="authService.user$ | async as user; else login">
    <div class="account-menu__divider"></div>
    <a routerLink="/account/dashboard" class="account-menu__user" (click)="chiudiMenu()">
      <div class="account-menu__user-avatar">
        <span *ngIf="!user.photoURL" class="material-icons d-flex align-items-center justify-content-center mt-3"
          style="height: 44px;font-size: 3rem;color: rgba(0,0,0,.325);"> account_circle </span>
        <div *ngIf="!!user.photoURL" class="customIconAccountMenu"
          [ngStyle]="{ 'background-image': 'url(' + user.photoURL + ')'}"></div>
      </div>
      <div class="account-menu__user-info">
        <div class="account-menu__user-name">{{user.email}}</div>
      </div>
    </a>
    <div class="account-menu__divider"></div>
    <ul class="account-menu__links">
      <li><a routerLink="/account/profile" (click)="chiudiMenu()">Modifica Profilo</a></li>
      <li><a routerLink="/account/orders" (click)="chiudiMenu()">Ordini effettuati</a></li>
      <li><a routerLink="/account/addresses" (click)="chiudiMenu()">Indirizzi</a></li>
      <li>
        <a routerLink="/account/notifiche" class="d-flex" (click)="chiudiMenu()">
          Notifiche
          <mat-chip *ngIf="countNotifiche > 0" class="example-box ml-3 pl-2 pr-2 text-white"
            style="background: #422951;">{{countNotifiche}}</mat-chip>
        </a>
      </li>
      <li *ngIf="user.email == isAdmin"><a class="d-flex" routerLink="/account/amministrazione" (click)="chiudiMenu()">
          Amministrazione
          <mat-chip *ngIf="listaOrdiniRicevuti > 0" class="example-box ml-3 pl-2 pr-2 text-white"
            style="background: #422951;">{{listaOrdiniRicevuti}}</mat-chip>
        </a>
      </li>
      <li *ngIf="user.email == isAdmin"><a class="d-flex" routerLink="/account/gestione-categorie"
          (click)="chiudiMenu()">
          Gestione Categorie
        </a>
      </li>
      <li *ngIf="user.email == isAdmin">
        <a routerLink="/account/messaggi" class="d-flex" (click)="chiudiMenu()">Messaggi
          <mat-chip *ngIf="listaMsg > 0" class="example-box ml-3 pl-2 pr-2 text-white" style="background: #422951;">
            {{listaMsg}}</mat-chip>
        </a>
      </li>
      <li *ngIf="user.email == isAdmin">
        <a routerLink="/account/magazzino" class="d-flex" (click)="chiudiMenu()">Magazzino
        </a>
      </li>
      <li *ngIf="user.email == isAdmin">
        <a routerLink="/account/utenti" class="d-flex" (click)="chiudiMenu()">Utenti
        </a>
      </li>
    </ul>
    <div class="account-menu__divider"></div>
    <ul class="account-menu__links">
      <li><a (click)="logOut()" style="cursor: pointer;">Esci</a></li>
    </ul>
  </div>
  <ng-template #login>
    <div class="account-menu__form">
      <div class="account-menu__form-title">Accedi al tuo Account</div>
      <app-user-login [isAccountMenu]="true"></app-user-login>
    </div>
  </ng-template>

</div>