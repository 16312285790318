import { Component, Inject, Input } from '@angular/core';
import { PageHeaderInterface, PAGE_HEADER_TOKEN } from './page-header.interface';

@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss']
})
export class PageHeaderComponent {
  @Input() header: string;
  @Input() breadcrumbs: any = [];
  @Input() isInDettaglioProdotto: boolean;

  constructor(
    @Inject(PAGE_HEADER_TOKEN) public pageHeaderInterface: PageHeaderInterface
  ) { }

  naviga(value) {
    switch (value.id) {
      case 0:
        this.pageHeaderInterface.clearPadre$.next();
        break;
      case 1:
        this.pageHeaderInterface.clearFiglio$.next(true);
        break;
      case 2:
        this.pageHeaderInterface.clearSottoCategoria$.next(true);
        break;

      default:
        break;
    }
  }
}
