import { Component, ElementRef, forwardRef, HostBinding, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { filter, tap } from 'rxjs/operators';
import { CarrelloFire, DatiCarrelloFire } from '../../models/CarrelloFire';
import { DatiUtente } from '../../models/UtenteModels';
import { AuthService } from '../../services/auth/auth.service';
import { CartService } from '../../services/cart.service';
import { InputNumberInterface, INPUT_NUMBER_TOKEN } from './input-number.interface';

function parseNumber<T>(value: any, def: T): number | T {
  if (typeof value === 'string') {
    value = parseFloat(value);
  } else if (typeof value !== 'number') {
    value = def;
  }

  return isNaN(value) ? def : value;
}

@Component({
  selector: '[input-number]',
  templateUrl: './input-number.component.html',
  styleUrls: ['./input-number.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputNumberComponent),
      multi: true
    },
  ],
  exportAs: 'input-number'
})
export class InputNumberComponent implements ControlValueAccessor, OnInit {
  options = {
    step: 1,
    min: null,
    max: null,
    disabled: false,
    readonly: false
  };

  @HostBinding('class.input-number') class = true;

  @Input() size: 'sm' | 'lg' = null;

  @Input() set step(value: number) {
    this.options.step = parseNumber(value, 1);
  }

  @Input() set min(value: number) {
    this.options.min = parseNumber(value, null);
  }

  @Input() set max(value: number) {
    this.options.max = parseNumber(value, null);
  }

  @Input() set disabled(value: boolean) {
    this.options.disabled = !!value;
  }

  @Input() set readonly(value: boolean) {
    this.options.readonly = !!value;
  }

  @Input('product') product: CarrelloFire;
  @Input('carrelloFire') carrelloFire: boolean;
  public utente: DatiUtente;

  @ViewChild('inputElement', { static: true }) inputElementRef: ElementRef;

  get inputElement(): HTMLInputElement {
    return this.inputElementRef.nativeElement;
  }

  get value(): '' | number {
    return this.inputElement.value === '' ? '' : parseFloat(this.inputElement.value);
  }
  set value(value: '' | number) {
    this.writeValue(value);
  }


  onChange = (_: any) => { };
  onTouched = () => { };

  constructor(
    public cartService: CartService,
    private auth: AuthService,
    @Inject(INPUT_NUMBER_TOKEN) public numberInterface: InputNumberInterface
  ) {
    this.auth.user$.pipe(
      tap((v) => this.utente = v)
    ).subscribe();
  }
  ngOnInit(): void {
    if (this.carrelloFire == true) {
      this.cartService.getProdottoByID(this.product.data.idProdotto).pipe(
        filter((v) => !!v),
        tap((v) => {
          this.max = v.disponibilitaReale;
          if (this.value > v.disponibilitaReale && this.product.data.visibility == true) {
            this.value = v.disponibilitaReale;
            let visibility: boolean = true;
            if(v.disponibilitaReale == 0){
              visibility = false;
            }
            let obj: DatiCarrelloFire = {
              NomeProdotto: this.product.data.NomeProdotto,
              disponibilitaReale: v.disponibilitaReale,
              emailUtente: this.product.data.emailUtente,
              idProdotto: this.product.data.idProdotto,
              idUtente: this.product.data.idUtente,
              immagineProdotto: this.product.data.immagineProdotto,
              prezzo: this.product.data.prezzo,
              quantita: v.disponibilitaReale,
              slugProdotto: this.product.data.slugProdotto,
              prodotto: this.product.data.prodotto,
              visibility: visibility,
              quantitaDesiderata: this.product.data.quantitaDesiderata,
              idNotifica: this.product.data.idNotifica
            }
            this.cartService.editProdottoSuCarrelloFire(this.product.id, obj)
          }
        })
      ).subscribe();
    }
  }

  add(): void {
    this.change(1);
    if (!!this.utente) {
      let obj: DatiCarrelloFire = {
        NomeProdotto: this.product.data.NomeProdotto,
        disponibilitaReale: this.product.data.disponibilitaReale,
        emailUtente: this.product.data.emailUtente,
        idProdotto: this.product.data.idProdotto,
        idUtente: this.product.data.idUtente,
        immagineProdotto: this.product.data.immagineProdotto,
        prezzo: this.product.data.prezzo,
        quantita: this.product.data.quantita + 1,
        slugProdotto: this.product.data.slugProdotto,
        prodotto: this.product.data.prodotto,
        visibility: this.product.data.visibility,
        quantitaDesiderata: this.product.data.quantitaDesiderata + 1,
        idNotifica: this.product.data.idNotifica
      }
      this.cartService.editProdottoSuCarrelloFire(this.product.id, obj)
    }
    this.changeByTimer(1);
  }

  sub(): void {
    this.change(-1);
    if (!!this.utente) {
      let obj: DatiCarrelloFire = {
        NomeProdotto: this.product.data.NomeProdotto,
        disponibilitaReale: this.product.data.disponibilitaReale,
        emailUtente: this.product.data.emailUtente,
        idProdotto: this.product.data.idProdotto,
        idUtente: this.product.data.idUtente,
        immagineProdotto: this.product.data.immagineProdotto,
        prezzo: this.product.data.prezzo,
        quantita: this.product.data.quantita - 1,
        slugProdotto: this.product.data.slugProdotto,
        prodotto: this.product.data.prodotto,
        visibility: this.product.data.visibility,
        quantitaDesiderata: this.product.data.quantitaDesiderata - 1,
        idNotifica: this.product.data.idNotifica
      }
      this.cartService.editProdottoSuCarrelloFire(this.product.id, obj)
    }
    this.changeByTimer(-1);
  }

  input(): void {
    this.onChange(this.value);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(obj: any): void {
    if (typeof obj === 'number') {
      this.inputElement.value = obj.toString();
    } else {
      this.inputElement.value = '';
    }
  }

  /**
   * @param direction - one of [-1, 1]
   */
  private change(direction: number): void {
    let value = (this.value === '' || isNaN(this.value) ? 0 : this.value) + this.options.step * direction;

    if (this.options.max !== null) {
      value = Math.min(this.options.max, value);
    }
    if (this.options.min !== null) {
      value = Math.max(this.options.min, value);
    }

    if (value !== this.value) {
      this.onChange(value);
      this.value = value;
    }
  }

  /**
   * @param direction - one of [-1, 1]
   */
  private changeByTimer(direction: number): void {
    let interval;
    const timer = setTimeout(() => {
      interval = setInterval(() => this.change(direction), 50);
    }, 300);

    const documentMouseUp = () => {
      clearTimeout(timer);
      clearInterval(interval);

      document.removeEventListener('mouseup', documentMouseUp, false);
    };

    document.addEventListener('mouseup', documentMouseUp, false);
  }
}
