import { DatePipe } from '@angular/common';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth/auth.service';
import { UserLoginInterface, USER_LOGIN_TOKEN } from './user-login.interface';
import { pageService } from 'src/app/modules/account/pages/pageService';
import { DatiUtente, UtenteModels } from '../../models/UtenteModels';
import { ToastrService } from 'ngx-toastr';
import * as firebase from 'firebase/app';

@Component({
  selector: 'app-user-login',
  templateUrl: './user-login.component.html',
  styleUrls: ['./user-login.component.scss'],
  providers: [DatePipe, pageService]
})
export class UserLoginComponent implements OnInit {

  accedi: FormGroup;
  public utenteConnesso;
  hide1 = true;

  public utente: UtenteModels[];

  @Input() datiFormLogin;
  @Input() isCheckout;
  @Input() isAccountMenu;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    public datepipe: DatePipe,
    @Inject(USER_LOGIN_TOKEN) public userLoginInterface: UserLoginInterface,
    public pageService: pageService,
    private toastr: ToastrService,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.createFormAccedi();
    if (!!this.datiFormLogin) {
      let form = {
        Email: this.datiFormLogin.Email,
        Password: this.datiFormLogin.Password
      }
      this.accedi.patchValue(form);
    }
  }

  createFormAccedi() {
    this.accedi = this.fb.group({
      Email: ['', [Validators.required, Validators.email]],
      Password: ['', [Validators.required]],
    })
  }

  goToRegistrazione() {
    if (this.isCheckout) {
      this.userLoginInterface.goToRegistrazione$.next(true);
    } else this.router.navigate(['/account/registrazione']);
    if (this.isAccountMenu == true) {
      this.userLoginInterface.chiudiMenu$.next(true);
    }
  }

  SignIn(email, password) {
    this.authService.SignIn(email,password).then(res => {
      if(this.router.url == '/account/login'){
        this.router.navigate(['/account/dashboard'])
      }
    })
  }

  private updateUserData({ uid, email, displayName, photoURL, dataRegistrazione, dataUltimoAccesso }) {
    const data: DatiUtente = {
      uid,
      email,
      displayName,
      photoURL,
      dataRegistrazione,
      dataUltimoAccesso
    }
    this.utenteConnesso = data;
    return firebase.firestore().doc(`users/${uid}`).set(this.utenteConnesso, { merge: true });
  }

  async accediConGoogle() {
    this.authService.googleSignIn(this.isCheckout).then(res => {
      if(this.router.url == '/account/login'){
        this.router.navigate(['/account/dashboard'])
      }
    }).catch();
  }

}
