import { InjectionToken } from '@angular/core';
import { Subject } from 'rxjs';
import { CommonInterface } from 'src/app/shared/interfaces/common.interface';

export interface WidgetFilterInterface {
    clearPadre$: Subject<any>;
    clearFiglio$: Subject<any>;
    salvaDato$: Subject<any>;
    listaCategoriePrincipali: any;
}

export const WIDGET_FILTER_INTERFACE = new InjectionToken<WidgetFilterInterface>('WidgetFilterInterface');