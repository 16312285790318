<div>
  <form [formGroup]="nuovoIndirizzo">
    <div class="form-row">
      <mat-form-field class="example-full-width col-12 col-lg-6 pl-0 pr-0 pr-lg-2">
        <mat-label>Nome</mat-label>
        <input matInput formControlName="Nome" #Nome autocomplete="off">
      </mat-form-field>
      <mat-form-field class="example-full-width col-12 col-lg-6 pl-lg-2 pl-0 pr-0">
        <mat-label>Cognome</mat-label>
        <input matInput formControlName="Cognome" #Cognome autocomplete="off">
      </mat-form-field>
    </div>
    <div class="form-row">
      <mat-form-field class="example-full-width col-12 col-lg-8 pl-0 pr-0 pr-lg-2">
        <mat-label>Via e Numero Civico</mat-label>
        <input matInput formControlName="indirizzoSpedizione" #indirizzoSpedizione autocomplete="off">
      </mat-form-field>
      <mat-form-field class="example-full-width col-6 col-lg-2 pl-0 pr-0 pr-lg-2">
        <mat-label>Palazzina</mat-label>
        <input matInput formControlName="palazzina" autocomplete="off">
      </mat-form-field>
      <mat-form-field class="example-full-width col-6 col-lg-2 pl-0 pr-0 pr-lg-2">
        <mat-label>Interno</mat-label>
        <input matInput formControlName="interno" autocomplete="off">
      </mat-form-field>
    </div>
    <div class="form-row">
      <mat-form-field class="example-full-width col-6 col-lg-6 pl-0 pr-1 pr-lg-2">
        <mat-label>Paese</mat-label>
        <mat-select formControlName="Paese">
          <mat-option [value]="1">
            Italia
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="example-full-width col-6 col-lg-6 pl-lg-2 pl-1 pr-0">
        <mat-label>Citta</mat-label>
        <input matInput formControlName="Citta" #Citta autocomplete="off">
      </mat-form-field>

    </div>
    <div class="form-row">
      <mat-form-field class="example-full-width col-6 col-lg-6 pl-0 pr-1 pr-lg-2">
        <mat-label>Provincia</mat-label>
        <input matInput formControlName="provincia" #provincia autocomplete="off">
      </mat-form-field>
      <mat-form-field class="example-full-width col-6 col-lg-6 pl-lg-2 pl-1 pr-0">
        <mat-label>Codice Postale</mat-label>
        <input matInput formControlName="codicePostale" maxlength="5" appOnlynumber #codicePostale autocomplete="off">
        <mat-hint align="end">{{codicePostale.value.length}} / 5</mat-hint>
      </mat-form-field>
    </div>
    <div class="form-row">
      <mat-form-field class="example-full-width col-12 col-lg-9 pl-0 pr-0 pr-lg-2">
        <mat-label>Indirizzo Email</mat-label>
        <input matInput formControlName="indirizzoEmail" #indirizzoEmail autocomplete="off">
      </mat-form-field>
      <mat-form-field class="example-full-width col-12 col-lg-3 pl-lg-2 pl-0 pr-0">
        <mat-label>Telefono</mat-label>
        <input matInput formControlName="telefono" #telefono autocomplete="off" appOnlynumber maxlength="10">
        <mat-hint align="end">{{telefono.value.length}} / 10</mat-hint>
      </mat-form-field>
    </div>
    <div class="d-flex justify-content-center w-100">
      <button class="btn btn-primary text-white submit-button mt-3 col-md-6 col-12" color="primary" type="submit"
        [disabled]="!nuovoIndirizzo.valid" (click)="salvaNuovoIndirizzo(nuovoIndirizzo.value)">
        <span class="material-icons">
          add
        </span>
        Inserisci</button>
    </div>
  </form>
</div>
