<ul class="mobile-links mobile-links--level--1" appCollapse>
  <li *ngFor="let link of items">
    <div class="mobile-links__item" appCollapseItem="mobile-links__item--open" #item="appCollapseItem">
      <div class="mobile-links__item-title d-flex align-items-center categoryElement">
        <a class="mobile-links__item-link" [ngStyle]="{'cursor': isAdministration ? 'default' : 'pointer' }"
          (click)="onItemClick(link)">{{ link.data.nomeCategoria }}</a>
        <div class="d-flex mr-3" *ngIf="isAdministration">
          <span class="material-icons d-none mr-1" style="cursor: pointer;" id="modifica" (click)="modifica(link)">
            create
          </span>
          <span class="material-icons d-none text-danger" id="cancella" style="cursor: pointer;"
            (click)="cancella(link)">
            delete
          </span>
        </div>
        <button *ngIf="link.data.sottoCategoria == 'true'" class="mobile-links__item-toggle pt-3 pb-3" type="button"
          (click)="item.toggle()">
          <app-icon class="mobile-links__item-arrow" name="arrow-rounded-down-12x7" size="12x7"></app-icon>
        </button>
      </div>
      <div *ngIf="link.data.sottoCategoria == 'true'" class="mobile-links__item-sub-links" appCollapseContent>
        <app-link-last-child [lastChild]="link" [isMobile]="isMobile" [isAdministration]="isAdministration"
          (itemClick)="itemClick.emit($event)" (dato)="inviaDato($event)" (addUltimaCategoriaEmit)="inviaDatoSubCategory($event)"></app-link-last-child>
      </div>
    </div>
    <div *ngIf="link.type === 'divider'" class="mobile-links__divider"></div>
  </li>
  <li *ngIf="isAdministration">
    <div class="mobile-links__item" appCollapseItem="mobile-links__item--open" #item="appCollapseItem">
      <div class="mobile-links__item-title d-flex align-items-center categoryElement">
        <a class="mobile-links__item-link" style="cursor: pointer;" (click)="apriStepper()">Aggiungi categoria
          figlio</a>
      </div>
    </div>
  </li>
</ul>
