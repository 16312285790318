<div class="block block-banner">
    <div class="container-xxl">
      <div>Some tags before</div>
      <owl-carousel-o [options]="customOptions">
        <ng-template carouselSlide>Slide 1</ng-template>
        <ng-template carouselSlide>Slide 2</ng-template>
        <ng-template carouselSlide>Slide 3</ng-template>
        <ng-template carouselSlide>Slide 2</ng-template>
        <ng-template carouselSlide>Slide 3</ng-template>
        <ng-template carouselSlide>Slide 2</ng-template>
        <ng-template carouselSlide>Slide 3</ng-template>
      </owl-carousel-o>
      <div>Some tags after</div>
    </div>
  </div>
