import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth/auth.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor(
    public auth: AuthService,
    private router: Router
  ) { }

  utenteConnesso;


  ngOnInit(): void {
    this.auth.user$.subscribe(
      res => this.salvaUtente(res)
    )
  }

  salvaUtente(value) {
    this.utenteConnesso = value
  }

  go() {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    setTimeout(() => {
      this.router.navigateByUrl("/shop/catalog/in_offerta")
    }, 400);
  }
}
