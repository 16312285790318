import { InjectionToken } from "@angular/core";
import { Subject } from "rxjs";
import { CategoriaFiglioModels } from "../../models/CategoriaFiglioModels";
import { CategoriaGenitoreModels } from "../../models/CategoriaGenitoreModels";
import { UltimaCategoriaModels } from "../../models/UltimaCategoriaModels";

export interface IEditProduct {
  listaCategoriePrincipali: CategoriaGenitoreModels[];
  listaCategorieFiglio: CategoriaFiglioModels[];
  listaUltimaCategoria: UltimaCategoriaModels[];
}

export const EDIT_PRODUCT_TOKEN = new InjectionToken<IEditProduct>('IEditProduct');