import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { filter, tap } from 'rxjs/operators';
import { pageService } from 'src/app/modules/account/pages/pageService';
import { DatiProdotto, ProdottoModels } from '../../models/ProdottoModels';
import { RecensioneModels } from '../../models/RecensioneModels';
import { AuthService } from '../../services/auth/auth.service';

export enum StarRatingColor {
  primary = "primary",
  accent = "accent",
  warn = "warn"
}

@Component({
  selector: 'app-reviews',
  templateUrl: './reviews.component.html',
  styleUrls: ['./reviews.component.scss'],
  providers: [pageService, DatePipe]
})
export class ReviewsComponent implements OnInit {

  public reviewsForm: FormGroup;
  @Input() Prodotto: ProdottoModels;
  @ViewChild('formDirective') private formDirective: NgForm;

  messageOk: string = 'Recensione pubblicata!';
  actionButtonLabel: string = '';
  action: boolean = true;
  setAutoHide: boolean = true;
  autoHide: number = 2000;
  horizontalPosition: MatSnackBarHorizontalPosition = 'right';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';


  public utenteConnesso;
  public showLoader: boolean = false;
  public listaReviews: RecensioneModels[];
  public ratingArr = [];

  rating:number = 1;
  starCount:number = 5;
  starColor:StarRatingColor = StarRatingColor.accent;
  starColorP:StarRatingColor = StarRatingColor.primary;
  starColorW:StarRatingColor = StarRatingColor.warn;
  public color: string = 'accent';

  constructor(
    public auth: AuthService,
    public fb: FormBuilder,
    public pageService: pageService,
    public datepipe: DatePipe,
    public snackBar: MatSnackBar,

  ) { }

  ngOnInit(): void {
    this.showLoader = true;
    this.initForm();
    this.auth.user$.pipe(
      filter((v) => !!v),
      tap((v) => this.utenteConnesso = v)
    ).subscribe();

    this.pageService.getAllCommentoByIdProdotto(this.Prodotto.id).pipe(
      filter((v) => !!v),
      tap((v) => {
        this.listaReviews = v;
      })
    ).subscribe();

    for (let index = 0; index < this.starCount; index++) {
      this.ratingArr.push(index);
    }

    this.showLoader = false;
  }

  showIcon(index:number) {
    if (this.rating >= index + 1) {
      return 'star';
    } else {
      return 'star_border';
    }
  }

  onClick(rating:number) {
    this.rating = rating;
    return false;
  }

  initForm() {
    this.reviewsForm = this.fb.group({
      descrizione: ['', Validators.required]
    })
  }

  salvaRecensione(value) {

    if (this.Prodotto.data.reviews == 0) {
      let obj: DatiProdotto = {
        NomeProdotto: this.Prodotto.data.NomeProdotto,
        categoria: this.Prodotto.data.categoria,
        categoriaDefault: this.Prodotto.data.categoriaDefault,
        categoriaFiglio: this.Prodotto.data.categoriaFiglio,
        descrizione: this.Prodotto.data.descrizione,
        disponibilita: this.Prodotto.data.disponibilita,
        disponibilitaReale: this.Prodotto.data.disponibilitaReale,
        immagineProdotto: this.Prodotto.data.immagineProdotto,
        prezzo: this.Prodotto.data.prezzo,
        slugProdotto: this.Prodotto.data.slugProdotto,
        ultimaCategoria: this.Prodotto.data.ultimaCategoria,
        stelle: this.rating,
        reviews: 1,
        dataInserimento: this.Prodotto.data.dataInserimento,
        inOfferta: this.Prodotto.data.inOfferta,
        prezzoPrecedente: this.Prodotto.data.prezzoPrecedente,
        scontoPercentuale: this.Prodotto.data.scontoPercentuale
      }
      this.pageService.editProdottoInserito(this.Prodotto.id, obj).then();
    } else {
      let i = 1;
      let somma = 0;
      this.listaReviews.forEach(element => {
        i++;
        somma = somma + element.data.stelle
      });
      somma = this.rating + somma;
      let media = somma / i;
      let obj: DatiProdotto = {
        NomeProdotto: this.Prodotto.data.NomeProdotto,
        categoria: this.Prodotto.data.categoria,
        categoriaDefault: this.Prodotto.data.categoriaDefault,
        categoriaFiglio: this.Prodotto.data.categoriaFiglio,
        descrizione: this.Prodotto.data.descrizione,
        disponibilita: this.Prodotto.data.disponibilita,
        disponibilitaReale: this.Prodotto.data.disponibilitaReale,
        immagineProdotto: this.Prodotto.data.immagineProdotto,
        prezzo: this.Prodotto.data.prezzo,
        slugProdotto: this.Prodotto.data.slugProdotto,
        ultimaCategoria: this.Prodotto.data.ultimaCategoria,
        stelle: media,
        reviews: i,
        dataInserimento: this.Prodotto.data.dataInserimento,
        inOfferta: this.Prodotto.data.inOfferta,
        prezzoPrecedente: this.Prodotto.data.prezzoPrecedente,
        scontoPercentuale: this.Prodotto.data.scontoPercentuale
      }
      this.pageService.editProdottoInserito(this.Prodotto.id, obj).then();
    }

    this.pageService.AddRecensioneByIdProdotto(value,this.rating, this.utenteConnesso, this.Prodotto.id).then(
      res => {
        this.rating = 1;
        let config = new MatSnackBarConfig();
        config.verticalPosition = this.verticalPosition;
        config.horizontalPosition = this.horizontalPosition;
        config.duration = this.setAutoHide ? this.autoHide : 0;
        this.snackBar.open(this.messageOk, this.action ? this.actionButtonLabel : undefined, config);
        this.formDirective.resetForm()}
    )
  }

}
