import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { filter, retry, tap } from 'rxjs/operators';
import { pageService } from 'src/app/modules/account/pages/pageService';
import { CarrelloFire, DatiCarrelloFire } from 'src/app/shared/models/CarrelloFire';
import { DatiNotifica, NotificaModels, StatoNotifica } from 'src/app/shared/models/NotificaModels';
import { DatiProdotto } from 'src/app/shared/models/ProdottoModels';
import { DatiUtente } from 'src/app/shared/models/UtenteModels';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { CartService } from 'src/app/shared/services/cart.service';
import { CorrieriService } from 'src/app/shared/services/corrieri.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
  providers: [pageService]
})
export class NotificationsComponent implements OnInit {

  @Output() closeMenu: EventEmitter<void> = new EventEmitter<void>();

  public utenteConnesso: DatiUtente;
  public notifiche: NotificaModels[] = [];
  public statoNotifica = StatoNotifica;

  constructor(
    private auth: AuthService,
    private pageService: pageService,
    private cart: CartService,
    public corriereService: CorrieriService
  ) { }

  ngOnInit(): void {
    this.auth.user$.pipe(
      tap((v: DatiUtente) => this.utenteConnesso = v),
      filter((v) => !!v),
      tap((v) => this.pageService.getNotificaByIdUtenteNoAzione(v.uid).pipe(
        filter((v) => !!v),
        tap((v) => this.notifiche = v)
      ).subscribe()
      )
    ).subscribe()
  }

  rifiuta(value: NotificaModels) {
    this.cart.removeElementCartFire(value.data.idCarrello).then(res => {
      this.cart.removeNotificaFire(value.id);
    })
  }

  procedi(value: NotificaModels) {
    let obj: DatiNotifica = {
      dataNotifica: value.data.dataNotifica,
      idCarrello: value.data.idCarrello,
      idProdotto: value.data.idProdotto,
      idUtente: value.data.idUtente,
      immagineProdotto: value.data.immagineProdotto,
      nomeProdotto: value.data.nomeProdotto,
      quantita: value.data.quantita,
      scelta: this.statoNotifica.RichiestaAvvisoProdottoDisponibile,
      notificaInviataAlCliente: false,
      isNotificaSistema: value.data.isNotificaSistema,
      idOrdine: value.data.idOrdine,
      isVisualizzata: true,
      codiceSpedizione: value.data.codiceSpedizione,
      corriereAffidato: value.data.corriereAffidato
    }
    this.cart.editNotificaFire(value.id, obj).then()
  }

  rifiutaAddCarrello(value: NotificaModels) {
    this.cart.removeElementCartFire(value.data.idCarrello);
    this.cart.removeNotificaFire(value.id);
  }

  accettaAddCarrello(value: NotificaModels) {
    this.cart.recuperaCarrelloById(value.data.idCarrello).pipe(
      filter((v) => !!v),
      tap((v) => {
        let quantita: number = 0;
        if (v.disponibilitaReale >= v.quantitaDesiderata) {
          quantita = v.quantitaDesiderata
        } else {
          quantita = v.disponibilitaReale
        }
        let carrello: CarrelloFire = {
          id: value.data.idCarrello,
          data: {
            NomeProdotto: v.NomeProdotto,
            disponibilitaReale: v.disponibilitaReale,
            emailUtente: v.emailUtente,
            idNotifica: v.idNotifica,
            idProdotto: v.idProdotto,
            idUtente: v.idUtente,
            immagineProdotto: v.immagineProdotto,
            prezzo: v.prezzo,
            quantita: quantita,
            quantitaDesiderata: v.quantitaDesiderata,
            slugProdotto: v.slugProdotto,
            visibility: true,
            prodotto: {
              NomeProdotto: v.prodotto.NomeProdotto,
              categoria: v.prodotto.categoria,
              categoriaDefault: v.prodotto.categoriaDefault,
              categoriaFiglio: v.prodotto.categoriaFiglio,
              descrizione: v.prodotto.descrizione,
              disponibilita: v.prodotto.disponibilita,
              disponibilitaReale: v.prodotto.disponibilita,
              immagineProdotto: v.prodotto.immagineProdotto,
              prezzo: v.prodotto.prezzo,
              slugProdotto: v.prodotto.slugProdotto,
              ultimaCategoria: v.prodotto.ultimaCategoria,
              reviews: v.prodotto.reviews,
              stelle: v.prodotto.stelle,
              dataInserimento: v.prodotto.dataInserimento,
              inOfferta: v.prodotto.inOfferta,
              prezzoPrecedente: v.prodotto.prezzoPrecedente,
              scontoPercentuale: v.prodotto.scontoPercentuale
            } as DatiProdotto
          } as DatiCarrelloFire
        };
        this.pageService.editProdottoSuCarrelloFire(carrello.id, carrello.data).then(res => {
          this.cart.removeNotificaFire(value.id)
        })
      })
    ).subscribe();
  }

  confermaLetturaNotifica(value: NotificaModels){
    let obj: DatiNotifica = {
      dataNotifica: value.data.dataNotifica,
      idCarrello: value.data.idCarrello,
      idOrdine: value.data.idOrdine,
      idProdotto: value.data.idProdotto,
      idUtente: value.data.idUtente,
      immagineProdotto: value.data.immagineProdotto,
      isNotificaSistema: value.data.isNotificaSistema,
      isVisualizzata: true,
      nomeProdotto: value.data.nomeProdotto,
      notificaInviataAlCliente: value.data.notificaInviataAlCliente,
      quantita: value.data.quantita,
      scelta: value.data.scelta,
      codiceSpedizione: value.data.codiceSpedizione,
      corriereAffidato: value.data.corriereAffidato
    }
    this.cart.editNotificaFire(value.id, obj).then()
  }
}
