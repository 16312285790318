import { Component, Inject, Input, OnInit, ChangeDetectorRef } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { CartService } from '../../services/cart.service';
import { ActivatedRoute, ActivationEnd, Router } from '@angular/router';
import { filter, tap } from 'rxjs/operators';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ProdottoModels } from '../../models/ProdottoModels';
import { pageService } from 'src/app/modules/account/pages/pageService';
import { PageCategoryService } from 'src/app/modules/shop/services/page-category.service';

export type ProductLayout = 'standard' | 'sidebar' | 'columnar' | 'quickview';
export class NumberValidators {
  static range(min: number, max: number): ValidatorFn {
    return (c: AbstractControl): { [key: string]: boolean } | null => {
      if (c.value && (isNaN(c.value) || c.value < min || c.value > max)) {
        return { 'range': true };
      }
      return null;
    };
  }
}
@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnInit {
  @Input() layout: ProductLayout;
  @Input() product: ProdottoModels[];

  quantity: FormControl = new FormControl(1);
  addingToCart = false;
  addingToWishlist = false;
  addingToCompare = false;
  public disabilitaBtn: boolean = false;

  public aggiungiAlCarrello: FormGroup;

  customOptions: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    autoWidth: true,
    // autoHeight: true,
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 2
      },
      940: {
        items: 3
      }
    },
    nav: true
  };

  public productCarousel: ProdottoModels[];
  public showLoaderProdottiCorrelati: Boolean = false;

  constructor(
    private cart: CartService,
    private route: Router,
    private cd: ChangeDetectorRef,
    public fb: FormBuilder,
    private pageService: PageCategoryService
  ) { }

  ngOnInit(): void {
    let disponibilità: number = Number(this.product[0].data.disponibilitaReale);
    this.aggiungiAlCarrello = this.fb.group({
      quantity: [1, [Validators.required, NumberValidators.range(0, disponibilità)]]
    })

    this.cart.items$.pipe(
      tap((v) => {
        if (v.length == 0) {
          this.disabilitaBtn = false
        } else {
          let productFilter = this.cart.items.filter(x => x.cartItem.product.data.NomeProdotto == this.product[0].data.NomeProdotto);
          if (productFilter.length > 0) {
            if (productFilter[0].quantity >= this.product[0].data.disponibilitaReale) {
              this.disabilitaBtn = true;
            }
          }
        }
      })
    ).subscribe();
    this.recuperaProdottiCorrelati();
    this.route.events.subscribe((e) => {
      if (e instanceof ActivationEnd) {
        this.recuperaProdottiCorrelati();
      }
    });


  }

  addToCart(qnt): void {
    if (!this.addingToCart && this.product && this.aggiungiAlCarrello.value.quantity > 0) {
      this.addingToCart = true;
      var prodottoFinito
      var noArrayProduct = this.product.forEach(element => {
        prodottoFinito = element
      });
      this.cart.add(prodottoFinito, qnt).subscribe({
        complete: () => { this.addingToCart = false; this.cd.markForCheck() }
      });
    }
  }

  recuperaProdottiCorrelati() {
    this.showLoaderProdottiCorrelati = true;
    if (!!this.product[0].data.categoria && this.product[0].data.categoriaFiglio == null && this.product[0].data.ultimaCategoria == null) {
      if (this.product[0].data.categoria == 'tutti_i_prodotti') {

        this.pageService.getAllProdottiLimit().pipe(
          filter((v) => !!v),
          tap((v) => {
            let index = v.findIndex(_p => _p.id == this.product[0].id);
            v.splice(index, 1);
            this.productCarousel = v;
          })
        ).subscribe(res => this.showLoaderProdottiCorrelati = false);

      } else if (this.product[0].data.categoria === 'in_offerta') {

        this.pageService.getAllProdottiInOffertaLimit().pipe(
          filter((v) => !!v),
          tap((v) => {
            let index = v.findIndex(_p => _p.id == this.product[0].id);
            v.splice(index, 1);
            this.productCarousel = v;
          })).subscribe(res => this.showLoaderProdottiCorrelati = false);

      }
      else {

        this.pageService.getAllProdottiByCategoriaPrincipaleLimit(this.product[0].data.categoria).pipe(
          filter((v) => !!v),
          tap((v) => {
            let index = v.findIndex(_p => _p.id == this.product[0].id);
            v.splice(index, 1);
            this.productCarousel = v;
          })
        ).subscribe(res => this.showLoaderProdottiCorrelati = false)

      }
    }
    if (!!this.product[0].data.categoria && !!this.product[0].data.categoriaFiglio && this.product[0].data.ultimaCategoria == null) {
      this.pageService.getAllProdottiByFiglioLimit(this.product[0].data.categoriaFiglio).pipe(
        filter((v) => !!v),
        tap((v) => {
          let index = v.findIndex(_p => _p.id == this.product[0].id);
          v.splice(index, 1);
          this.productCarousel = v;
        })
      ).subscribe(res => this.showLoaderProdottiCorrelati = false)
    }
    if (!!this.product[0].data.categoria && !!this.product[0].data.categoriaFiglio && !!this.product[0].data.ultimaCategoria) {
      this.pageService.getAllProdottiBySottoCategoriaLimit(this.product[0].data.ultimaCategoria, this.product[0].data.categoriaFiglio).pipe(
        filter((v) => !!v),
        tap((v) => {
          let index = v.findIndex(_p => _p.id == this.product[0].id);
          v.splice(index, 1);
          this.productCarousel = v;
        })
      ).subscribe(res => this.showLoaderProdottiCorrelati = false)
    }
  }
}
