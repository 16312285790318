import { Component, Input, OnInit } from '@angular/core';
import { DatiNuovoOrdine } from '../../models/NuovoOrdineModels';

@Component({
  selector: 'app-template-fatturazione-partita-iva',
  templateUrl: './template-fatturazione-partita-iva.component.html',
  styleUrls: ['./template-fatturazione-partita-iva.component.scss']
})
export class TemplateFatturazionePartitaIvaComponent implements OnInit {

  @Input('ordineDettaglio') ordineDettaglio: DatiNuovoOrdine;

  constructor() { }

  ngOnInit(): void {
  }

}
