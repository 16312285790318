import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NotificaModels } from '../models/NotificaModels';
import { DatiNuovoOrdine, NuovoOrdineModels } from '../models/NuovoOrdineModels';

@Injectable({
  providedIn: 'root'
})
export class CorrieriService {

  constructor(private router: Router) { }

  recuperaRottaCorrieriDaNotifica(notifica: NotificaModels, event: Event) {
    switch (notifica.data.corriereAffidato) {
      case "SDA":
        event.stopPropagation();
        return window.open("https://www.sda.it/wps/portal/Servizi_online/dettaglio-spedizione?locale=it&tracing.letteraVettura="+ notifica.data.codiceSpedizione, '_blank');
      default:
        break;
    }
  }

  recuperaRottaCorrieriDaOrdine(ordine: DatiNuovoOrdine, event: Event) {
    switch (ordine.corriereAffidato) {
      case "SDA":
        event.stopPropagation();
        return window.open("https://www.sda.it/wps/portal/Servizi_online/dettaglio-spedizione?locale=it&tracing.letteraVettura="+ ordine.codiceSpedizione, '_blank');
      default:
        break;
    }
  }

}
