import { InjectionToken } from '@angular/core';
import { CategoriaFiglioModels } from '../models/CategoriaFiglioModels';
import { CategoriaGenitoreModels } from '../models/CategoriaGenitoreModels';
import { UltimaCategoriaModels } from '../models/UltimaCategoriaModels';

export interface CommonInterface {
  listaCategoriePrincipali: CategoriaGenitoreModels[];
  listaCategoriaFiglio:CategoriaFiglioModels[];
  listaUltimaCategoria: UltimaCategoriaModels[];
}

export const COMMON_INTERFACE_TOKEN = new InjectionToken<CommonInterface>('CommonInterface');