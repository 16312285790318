import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { filter, tap } from 'rxjs/operators';
import { PageCategoryService } from 'src/app/modules/shop/services/page-category.service';
import { COMMON_INTERFACE_TOKEN, CommonInterface } from '../../interfaces/common.interface';
import { UltimaCategoriaModels } from '../../models/UltimaCategoriaModels';

@Component({
  selector: 'app-link-last-child',
  templateUrl: './link-last-child.component.html',
  styleUrls: ['./link-last-child.component.scss'],
  providers: [PageCategoryService]
})
export class LinkLastChildComponent implements OnInit {

  public items: UltimaCategoriaModels[];
  @Input() lastChild: any;
  @Input() isMobile: boolean;
  @Input() isAdministration: boolean;
  @Output() itemClick: EventEmitter<any> = new EventEmitter();
  @Output() dato: EventEmitter<any> = new EventEmitter();
  @Output() addUltimaCategoriaEmit: EventEmitter<any> = new EventEmitter();

  constructor(
    public pageCategoryService: PageCategoryService,
    public router: Router,
    @Inject(COMMON_INTERFACE_TOKEN) public commonInterface: CommonInterface
  ) { }

  ngOnInit(): void {

    if (!this.commonInterface.listaUltimaCategoria) {
      this.pageCategoryService.getAllUltimaCat().pipe(
        filter((v) => !!v),
        tap((v) => {
          this.items = Object.assign([], v.filter(x => x.data.catFiglio == this.lastChild.data.slugCategoria && x.data.catPadre === this.lastChild.data.catGenitore));
          this.commonInterface.listaUltimaCategoria = Object.assign([], v);
        })
      ).subscribe();
    } else {
      this.items = Object.assign([], this.commonInterface.listaUltimaCategoria.filter(x => x.data.catFiglio == this.lastChild.data.slugCategoria && x.data.catPadre === this.lastChild.data.catGenitore));
    }
  }

  onItemClick(item: any): void {
    let dato: { id: number, value: any } = { id: 3, value: item };
    if (!this.isAdministration) {
      this.dato.emit(dato);
      this.router.navigate(['/shop/catalog/' + item.data.catPadre + '/' + item.data.catFiglio + '/' + item.data.slugCategoria]);
    }
    if (this.isMobile) this.itemClick.emit(item);
  }

  modifica(value) {
    let dato: { id: number, value: any } = { id: 1, value: value };
    this.dato.emit(dato);
  }

  cancella(value) {
    let dato: { id: number, value: any } = { id: 2, value: value };
    this.dato.emit(dato);
  }

  addSottoCategoria() {
    let inviaDato: { id: number, value: any } = { id: 3, value: this.lastChild.data };
    this.addUltimaCategoriaEmit.emit(inviaDato)
  }


}
