import { NgModule } from '@angular/core';

// modules (angular)
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

// modules (third-party)
import { Ng5SliderModule } from 'ng5-slider';

// modules
import { SharedModule } from '../../shared/shared.module';

// widgets
import { WidgetFiltersComponent } from './widget-filters/widget-filters.component';
import { DemoMaterialModule } from 'src/app/material-module';

@NgModule({
    declarations: [
        // widgets
        WidgetFiltersComponent,
    ],
    imports: [
        // modules (angular)
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        // modules (third-party)
        Ng5SliderModule,
        // modules
        SharedModule,
        DemoMaterialModule
    ],
    exports: [
        // widgets
        WidgetFiltersComponent,
    ]
})
export class WidgetsModule { }
