<div class="site-footer__widget footer-contacts">
  <h5 class="footer-contacts__title">Contattaci</h5>
  <ul class="footer-contacts__contacts">
    <li><span class="material-icons mr-2 text-primary" style="font-size: 22px"> public </span>Via Macedonia 4, Roma 00179</li>
    <li><span class="material-icons mr-2 text-primary" style="font-size: 22px"> email </span>
      <a href="mailto:info@laboutiquedelcaffe.eu">info@laboutiquedelcaffe.eu</a>
    </li>
    <li><span class="material-icons mr-2 text-primary" style="font-size: 22px"> phone_iphone </span><a href="tel:+393519161816">3519161816</a></li>
    <li><span class="material-icons mr-2 text-primary" style="font-size: 22px"> call </span><a href="tel:+390695222236">0695222236</a></li>

    <li><span class="material-icons mr-2 text-primary" style="font-size: 22px"> schedule </span> Lun-Sab 9:30 - 19:30 </li>
    <li> P.IVA <b>15639181005</b> </li>
  </ul>
</div>
