import { Injectable, AfterViewInit } from '@angular/core';
import { EMPTY, Observable, of } from 'rxjs';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { User } from '../../interfaces/user';
import { auth } from 'firebase/app';
import Swal from 'sweetalert2'
import * as firebase from 'firebase/app';



@Injectable({
  providedIn: 'root'
})
export class AuthService implements AfterViewInit {

  user$: Observable<any>;
  public utenteConnesso;
  public datiUtente;

  constructor(
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private router: Router,
  ) {
    this.user$ = this.afAuth.authState.pipe(
      switchMap(user => {
        if (user) {
          return this.afs.doc<User>(`users/${user.uid}`).valueChanges()
        } else {
          return of(null)
        }
      })
    )

  }

  ngAfterViewInit(): void {
    this.user$.pipe().subscribe(async res => this.utenteConnesso = res)
  }

  getCurrentUser() {
    return new Promise<any>((resolve, reject) => {
      var user = firebase.auth().onAuthStateChanged(function (user) {
        if (user) {
          resolve(user);
        } else {
          return EMPTY
        }
      })
    })
  }



  async googleSignIn(isCheckOut: boolean) {
    const provide = new auth.GoogleAuthProvider();
    provide.setCustomParameters({ prompt: 'select_account' });
    const credential = await this.afAuth.auth.signInWithPopup(provide);
    return this.updateUserData(credential.user).then(res => {
      if (isCheckOut) {
        window.localStorage.clear();
        this.router.navigate(['/shop/cart/checkout'])
      }
    })
  }

  async signOut() {
    await this.afAuth.auth.signOut();
    return this.router.navigate(['/']);
  }

  private updateUserData({ uid, email, displayName, photoURL }: User) {
    const userRef: AngularFirestoreDocument<User> = this.afs.doc(`users/${uid}`);
    const data = {
      uid,
      email,
      displayName,
      photoURL
    }

    this.utenteConnesso = data;


    return userRef.set(data, { merge: true })
  }

  SignIn(email, password) {
    return this.afAuth.auth.signInWithEmailAndPassword(email, password)
      .then((result) => {
        this.utenteConnesso = result;
        // this.router.navigate(['/account/dashboard'])
      }).catch(e => {
        if (e.message == 'The email address is badly formatted.') {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            showConfirmButton: true,
            text: "L'indirizzo email inserito non è ben formattato o non è valido.",
            confirmButtonText: 'Chiudi'
          })
        }
        if (e.message == 'There is no user record corresponding to this identifier. The user may have been deleted.') {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            showConfirmButton: true,
            text: "Non esiste alcun utente registrato con la questa email fornita.",
            confirmButtonText: 'Chiudi'
          })
        }

        if (e.code == "auth/wrong-password") {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            showConfirmButton: true,
            text: "Ci dispiace, ma la password non è corretta",
            confirmButtonText: 'Chiudi'
          })
        }
      }
      )
  }

  registrati(email, password) {
    return this.afAuth.auth.createUserWithEmailAndPassword(email, password)
      .then((result) => {
        /* Call the SendVerificaitonMail() function when new user sign
        up and returns promise */
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Registrazione Avvenuta!',
          showConfirmButton: false,
          timer: 1500
        })
      }).catch((error) => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: error.message,
        })
      })
  }

  registratiConAccesso(email, password) {
    return this.afAuth.auth.createUserWithEmailAndPassword(email, password)
      .then((result) => {
        /* Call the SendVerificaitonMail() function when new user sign
        up and returns promise */
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Registrazione Avvenuta!',
          showConfirmButton: false,
          timer: 1500
        })
      }).catch((error) => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: error.message,
        })
      })
  }

  getAuth() {
    return this.afAuth;
  }

  resetPasswordInit(email: string) {
    return this.afAuth.auth.sendPasswordResetEmail(
      email,
      { url: 'http://localhost:4200/auth' });
  }
}
