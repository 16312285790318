import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ADMINISTRATION_TOKEN } from 'src/app/modules/account/pages/administration/administration.interface';
import { ORDER_ACCEPTED_TOKEN } from 'src/app/modules/account/pages/administration/orders-accepted/orders-accepted.interface';
import { ORDER_REJECTED_TOKEN } from 'src/app/modules/account/pages/administration/orders-rejected/order-rejected.interface';
import { EDIT_CATEGORY_TOKEN } from 'src/app/modules/account/pages/edit-category/edit-category.interface';
import { MESSAGE_TOKEN } from 'src/app/modules/account/pages/message/message.interface';
import { PAGE_DASHBOARD_TOKEN } from 'src/app/modules/account/pages/page-dashboard/page-dashboard.interface';
import { WAREHOUSE_TOKEN } from 'src/app/modules/account/pages/warehouse/warehouse.interface';
import { MEGA_MENU_TOKEN } from 'src/app/modules/header/components/megamenu/megamenu.interface';
import { SUB_MENU_TOKEN } from 'src/app/modules/header/components/megamenu/sub-menu/sub-menu.interface';
import { PAGE_SEARCH_TOKEN } from 'src/app/modules/site/pages/page-search/page-search.interface';
import { WIDGET_FILTER_INTERFACE } from 'src/app/modules/widgets/widget-filters/widget.interface';
import { EDIT_PRODUCT_TOKEN } from 'src/app/shared/components/edit-product/edit-product.interface';
import { LINK_SUBMENU_TOKEN } from 'src/app/shared/components/link-submenu/link-submenu.interface';
import { COMMON_INTERFACE_TOKEN } from 'src/app/shared/interfaces/common.interface';
import { RootInternalService } from './root.service';

@Component({
  selector: 'app-main',
  templateUrl: './root.component.html',
  styleUrls: ['./root.component.scss'],
  providers: [RootInternalService,
    { provide: COMMON_INTERFACE_TOKEN, useExisting: RootInternalService },
    { provide: PAGE_DASHBOARD_TOKEN, useExisting: RootInternalService },
    { provide: WAREHOUSE_TOKEN, useExisting: RootInternalService },
    { provide: LINK_SUBMENU_TOKEN, useExisting: RootInternalService },
    { provide: EDIT_CATEGORY_TOKEN, useExisting: RootInternalService },
    { provide: ADMINISTRATION_TOKEN, useExisting: RootInternalService },
    { provide: ORDER_ACCEPTED_TOKEN, useExisting: RootInternalService },
    { provide: ORDER_REJECTED_TOKEN, useExisting: RootInternalService },
    { provide: SUB_MENU_TOKEN, useExisting: RootInternalService },
    { provide: MEGA_MENU_TOKEN, useExisting: RootInternalService },
    { provide: MESSAGE_TOKEN, useExisting: RootInternalService },
    { provide: EDIT_PRODUCT_TOKEN, useExisting: RootInternalService },
    { provide: PAGE_SEARCH_TOKEN, useExisting: RootInternalService }
  ]
})
export class RootComponent {
  headerLayout: 'classic' | 'compact';

  constructor(
    public router: Router,
    public route: ActivatedRoute
  ) {
    this.route.data.subscribe(data => this.headerLayout = data.headerLayout);
  }
}
