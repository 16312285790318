import { Subject } from 'rxjs';
import { LinkSubmenuInterface } from 'src/app/shared/components/link-submenu/link-submenu.interface';
import { Injectable } from "@angular/core";


@Injectable()
export class MobileLinkService implements LinkSubmenuInterface {
    ricaricaLista$: Subject<any> = new Subject<any>();
    salvaDato$: Subject<any>= new Subject<any>();
    clearSottoCategoria$: Subject<any> = new Subject<any>();
}