import { AfterContentChecked, AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition, MatSnackBarConfig, MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { iif, Observable, of } from 'rxjs';
import { filter, finalize, map, mergeMap, tap } from 'rxjs/operators';
import { pageService } from 'src/app/modules/account/pages/pageService';
import { CarrelloFire, DatiCarrelloFire } from '../../models/CarrelloFire';
import { CategoriaFiglioModels } from '../../models/CategoriaFiglioModels';
import { CategoriaGenitoreModels } from '../../models/CategoriaGenitoreModels';
import { DatiNotifica, StatoNotifica } from '../../models/NotificaModels';
import { DatiProdotto, ProdottoModels } from '../../models/ProdottoModels';
import { UltimaCategoriaModels } from '../../models/UltimaCategoriaModels';
import { CartService } from '../../services/cart.service';
import { EDIT_PRODUCT_TOKEN, IEditProduct } from './edit-product.interface';

@Component({
  selector: 'app-edit-product',
  templateUrl: './edit-product.component.html',
  styleUrls: ['./edit-product.component.scss'],
  providers: [pageService]
})
export class EditProductComponent implements OnInit, AfterViewInit, AfterContentChecked {

  @Input() product: ProdottoModels;
  @Input('isInAmministrazione') isInAmministrazione: boolean;
  @Output() chiudiModale: EventEmitter<void> = new EventEmitter<void>();

  modificaProdottoForm: FormGroup;
  messageOk: string = "Prodotto modificato!!";
  messageDelete: string = 'Cancellazione Avvenuta.';
  actionButtonLabel: string = '';
  action: boolean = true;
  setAutoHide: boolean = true;
  autoHide: number = 2000;
  horizontalPosition: MatSnackBarHorizontalPosition = 'right';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';

  public prodottoRecuperato: ProdottoModels;

  public showMessage: boolean = false;

  public listaCategoriePrincipali: CategoriaGenitoreModels[];
  public listaCategorieFiglio: CategoriaFiglioModels[];
  public listaUltimaCategoria: UltimaCategoriaModels[];

  public listaDatiCategoriaFiglio: CategoriaFiglioModels[];
  public listaDatiUltimaCategoria: UltimaCategoriaModels[];
  public visibiltyFiglio: boolean = false;

  public hiddenFiglio: boolean = false;
  public hiddenUltimaCat: boolean = false;
  public disabilitaUltimaCat: boolean = true;

  public categoriaFiglio: FormControl = new FormControl('');
  public ultimaCategoria: FormControl = new FormControl('');
  public inOfferta: FormControl = new FormControl('');
  public visibilityPercentuale: boolean = false;

  public editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '0',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Inserisci la descrizione del prodotto',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    uploadUrl: 'v1/image',
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['bold', 'italic'],
      ['fontSize']
    ]
  };

  public showLoader: boolean;
  link;
  downloadURL: Observable<string>;


  constructor(
    public cart: CartService,
    public snackBar: MatSnackBar,
    public fb: FormBuilder,
    public pageService: pageService,
    public cds: ChangeDetectorRef,
    @Inject(EDIT_PRODUCT_TOKEN) public IEditProduct: IEditProduct,
    public router: Router,
    public activationRouter: ActivatedRoute,
    private storage: AngularFireStorage

  ) { }


  ngAfterContentChecked(): void {
    this.cds.detectChanges();
  }


  ngAfterViewInit(): void {
    this.modificaProdottoForm.get('categoria').valueChanges.pipe(
      filter((v) => this.prodottoRecuperato != undefined && Number(this.prodottoRecuperato.data.disponibilitaReale) == Number(this.prodottoRecuperato.data.disponibilita) && this.listaCategoriePrincipali != undefined),
      tap((v) => {
        this.hiddenFiglio = true;
        this.hiddenUltimaCat = true;
        this.categoriaFiglio.reset()
        this.ultimaCategoria.reset();
        this.modificaProdottoForm.removeControl('categoriaFiglio');
        this.modificaProdottoForm.removeControl('ultimaCategoria');
        let infoPadre = this.listaCategoriePrincipali.filter(x => x.data.slugCategoria == v);
        if (infoPadre[0].data.sottoCategoria == "true") {
          this.listaCategorieFiglio = this.listaDatiCategoriaFiglio.filter(x => x.data.catGenitore == v)
          this.hiddenFiglio = false;
          this.modificaProdottoForm.addControl('categoriaFiglio', this.categoriaFiglio);
        } else this.hiddenFiglio = true;
      })
    ).subscribe();

    this.modificaProdottoForm.patchValue(this.product.data)
    this.showLoader = false;

  }

  ngOnInit(): void {
    this.showLoader = true;
    this.initForm();
    this.pageService.getProdottoBySlug(this.product.data.slugProdotto).pipe(
      filter((v) => !!v),
      mergeMap(v =>
        iif(() => !!v && Number(v[0].data.disponibilitaReale) != Number(v[0].data.disponibilita),
          of(v).pipe(
            tap((v) => this.prodottoRecuperato = v[0]),
            tap((v) => {
              this.showMessage = true;
              this.visibilityPercentuale = this.prodottoRecuperato.data.inOfferta
            })
          ),
          of(v).pipe(
            tap((v) => this.prodottoRecuperato = v[0]),
            tap((v) => {
              this.showMessage = false;
              this.visibilityPercentuale = this.prodottoRecuperato.data.inOfferta

            })
          )
        ))
    ).subscribe();

    this.categoriaFiglio.valueChanges.pipe(
      filter((v) => v != null && v != '' && !!this.prodottoRecuperato),
      filter((v) => Number(this.prodottoRecuperato.data.disponibilitaReale) == Number(this.prodottoRecuperato.data.disponibilita) && this.listaDatiCategoriaFiglio != undefined),
      tap((v) => {
        this.modificaProdottoForm.removeControl('ultimaCategoria');
        this.hiddenUltimaCat = true;
        let infoFiglio: CategoriaFiglioModels[] = this.listaDatiCategoriaFiglio.filter(x => x.data.slugCategoria == v);
        if (infoFiglio[0].data.sottoCategoria == "true") {
          this.disabilitaUltimaCat = true;
          let preLista: UltimaCategoriaModels[] = this.listaUltimaCategoria.filter(x => x.data.catFiglio == infoFiglio[0].data.slugCategoria && x.data.catPadre == infoFiglio[0].data.catGenitore);
          if (preLista.length > 0) {
            this.listaDatiUltimaCategoria = preLista
          } else this.disabilitaUltimaCat = false;
          this.ultimaCategoria.reset();
          this.modificaProdottoForm.addControl('ultimaCategoria', this.ultimaCategoria)
          this.hiddenUltimaCat = false;
        } else this.hiddenUltimaCat = true;
      })
    ).subscribe();



    if (!this.IEditProduct.listaCategoriePrincipali) {
      this.pageService.getCategoriaPrincipale().pipe(
        filter((v) => !!v),
        tap((value) => {
          this.listaCategoriePrincipali = value;
          this.IEditProduct.listaCategoriePrincipali = value;
        })
      ).subscribe();
    } else {
      this.listaCategoriePrincipali = this.IEditProduct.listaCategoriePrincipali;
    }

    if (!this.IEditProduct.listaCategorieFiglio) {
      this.pageService.getAllCategoriaFiglio().pipe(
        filter((v) => !!v),
        tap((value) => {
          this.listaCategorieFiglio = value;
          this.listaDatiCategoriaFiglio = value;
          this.IEditProduct.listaCategorieFiglio = value
        })
      ).subscribe();
    } else {
      this.listaCategorieFiglio = this.IEditProduct.listaCategorieFiglio;
      this.listaDatiCategoriaFiglio = this.IEditProduct.listaCategorieFiglio;
    }

    if (!this.IEditProduct.listaUltimaCategoria) {
      this.pageService.getAllUltimaCategoria().pipe(
        filter((v) => !!v),
        tap((v) => {
          this.listaUltimaCategoria = v;
          this.listaDatiUltimaCategoria = v;
          this.IEditProduct.listaUltimaCategoria = v;
        })
      ).subscribe();
    } else {
      this.listaUltimaCategoria = this.IEditProduct.listaUltimaCategoria;
      this.listaDatiUltimaCategoria = this.IEditProduct.listaUltimaCategoria;
    }

    this.inOfferta.valueChanges.pipe(
      tap((v) => this.visibilityPercentuale = v)
    ).subscribe();



  }

  initForm() {
    this.modificaProdottoForm = this.fb.group({
      NomeProdotto: ['', Validators.required],
      categoria: ['', Validators.required],
      categoriaDefault: [''],
      descrizione: ['', Validators.required],
      disponibilita: ['', Validators.required],
      disponibilitaReale: [''],
      immagineProdotto: ['', Validators.required],
      prezzo: ['', Validators.required],
      slugProdotto: [''],
      reviews: [''],
      stelle: [''],
      dataInserimento: [''],
      prezzoPrecedente: [''],
      scontoPercentuale: [''],
    })
    this.modificaProdottoForm.addControl('categoriaFiglio', this.categoriaFiglio);
    this.modificaProdottoForm.addControl('ultimaCategoria', this.ultimaCategoria);
    this.modificaProdottoForm.addControl('inOfferta', this.inOfferta);

    if (!this.product.data.categoriaFiglio) this.hiddenFiglio = true;
    if (!this.product.data.ultimaCategoria) this.hiddenUltimaCat = true;
  }

  ricaricaListaFiglio(value) {
    this.listaCategorieFiglio = this.listaDatiCategoriaFiglio.filter(x => x.data.catGenitore == value);
  }

  caricaImmagine(event) {
    var n = Date.now();
    const file = event.target.files[0];
    const filePath = `img-prodotti/${n}`;
    const fileRef = this.storage.ref(filePath);
    const task = this.storage.upload(`img-prodotti/${n}`, file);
    task.snapshotChanges().pipe(
      finalize(() => {
        this.downloadURL = fileRef.getDownloadURL();
        this.downloadURL.subscribe(url => {
          if (url) {
            this.link = url;
            this.modificaProdottoForm.get('immagineProdotto').setValue(this.link);
          }
        });
      })
    ).subscribe()
  }

  ricaricaListaUltimaCategoria(figlio, padre) {
    let preLista = this.listaUltimaCategoria.filter(x => x.data.catFiglio == figlio && x.data.catPadre == padre);
    if (preLista.length > 0) {
      this.listaDatiUltimaCategoria = preLista
    } else this.disabilitaUltimaCat = true;
  }

  caricaCategoriaFiglio(value) {
    if (value.data.sottoCategoria == 'true') {
      this.listaCategorieFiglio = this.listaDatiCategoriaFiglio.filter(x => x.data.catGenitore == value.data.slugCategoria);
    }
  }

  salvaModifiche(value: DatiProdotto) {
    if (value.inOfferta == true) {
      value.inOfferta = true;
      if (this.prodottoRecuperato.data.inOfferta == false) {
        let newPrezzo = value.prezzo - (value.prezzo * value.scontoPercentuale / 100);
        value.prezzoPrecedente = value.prezzo;
        value.prezzo = Number(newPrezzo.toFixed(2));
      } else {
        let newPrezzo = value.prezzoPrecedente - (value.prezzoPrecedente * value.scontoPercentuale / 100);
        value.prezzo = Number(newPrezzo.toFixed(2));
      }
    } else {
      if (this.prodottoRecuperato.data.inOfferta == true) {
        value.prezzo = value.prezzoPrecedente;
        value.prezzoPrecedente = 0;
        value.scontoPercentuale = 0
      }
      value.inOfferta = false;
    }

    this.pageService.getNotificaAccettataByProdotto(this.prodottoRecuperato.id).pipe(
      filter((v) => !!v),
      tap((v) => v.map(res => {
        if (value.disponibilita >= 1) {
          if (res.data.notificaInviataAlCliente == false) {
            let obj: DatiNotifica = {
              dataNotifica: res.data.dataNotifica,
              idCarrello: res.data.idCarrello,
              idProdotto: res.data.idProdotto,
              idUtente: res.data.idUtente,
              immagineProdotto: res.data.immagineProdotto,
              nomeProdotto: res.data.nomeProdotto,
              notificaInviataAlCliente: true,
              quantita: res.data.quantita,
              scelta: 0,
              isNotificaSistema: res.data.isNotificaSistema,
              idOrdine: res.data.idOrdine,
              isVisualizzata: res.data.isVisualizzata,
              codiceSpedizione: null,
              corriereAffidato: null
            }
            if (res.data.scelta == StatoNotifica.ProdottoEsaurito) {
              this.pageService.cancellaNotificaFire(res.id).then(x => {
                this.pageService.getCarrelloById(res.data.idCarrello).pipe(
                  filter((v) => !!v),
                  tap((v) => {
                    let slugProdotto = this.creaSlug(value.NomeProdotto);
                    let quantita: number = v.quantita;
                    if (Number(value.disponibilita) >= v.quantitaDesiderata) {
                      quantita = v.quantitaDesiderata
                    } else {
                      quantita = Number(value.disponibilita)
                    }
                    let obj: DatiCarrelloFire = {
                      NomeProdotto: value.NomeProdotto,
                      disponibilitaReale: Number(value.disponibilita),
                      emailUtente: v.emailUtente,
                      idNotifica: v.idNotifica,
                      idProdotto: v.idProdotto,
                      idUtente: v.idUtente,
                      immagineProdotto: value.immagineProdotto,
                      prezzo: value.prezzo,
                      quantita: quantita,
                      quantitaDesiderata: v.quantitaDesiderata,
                      slugProdotto: slugProdotto,
                      visibility: true,
                      prodotto: {
                        NomeProdotto: value.NomeProdotto,
                        categoria: value.categoria,
                        categoriaDefault: value.categoriaDefault,
                        categoriaFiglio: value.categoriaFiglio,
                        descrizione: value.descrizione,
                        disponibilita: Number(value.disponibilita),
                        disponibilitaReale: Number(value.disponibilita),
                        immagineProdotto: value.immagineProdotto,
                        prezzo: value.prezzo,
                        slugProdotto: slugProdotto,
                        ultimaCategoria: value.ultimaCategoria,
                        reviews: value.reviews,
                        stelle: value.stelle,
                        dataInserimento: value.dataInserimento,
                        inOfferta: value.inOfferta,
                        prezzoPrecedente: value.prezzoPrecedente,
                        scontoPercentuale: value.scontoPercentuale,
                      }
                    };
                    this.pageService.editProdottoSuCarrelloFire(res.data.idCarrello, obj).then()
                  })
                ).subscribe();
              })
            } else if (res.data.scelta == StatoNotifica.RichiestaAvvisoProdottoDisponibile) {
              let objProdottoDisponibile: DatiNotifica = {
                dataNotifica: res.data.dataNotifica,
                idCarrello: res.data.idCarrello,
                idProdotto: res.data.idProdotto,
                idUtente: res.data.idUtente,
                immagineProdotto: res.data.immagineProdotto,
                nomeProdotto: res.data.nomeProdotto,
                notificaInviataAlCliente: true,
                quantita: res.data.quantita,
                scelta: StatoNotifica.ProdottoDisponibile,
                isNotificaSistema: res.data.isNotificaSistema,
                idOrdine: res.data.idOrdine,
                isVisualizzata: false,
                codiceSpedizione: null,
                corriereAffidato: null
              }
              this.pageService.editNotificaFire(res.id, objProdottoDisponibile)
            }
            else {
              this.pageService.editNotificaFire(res.id, obj)
            }
          }
        }
      }))
    ).subscribe();

    this.pageService.checkProdottoSuCarrello(this.prodottoRecuperato.id).pipe(
      filter((v) => !!v),
      map((v) => {
        v.map(r => {
          let slugProdotto = this.creaSlug(value.NomeProdotto);
          if (Number(value.disponibilita) <= r.data.quantita) {
            r.data.quantita = Number(value.disponibilita);
            r.data.disponibilitaReale = Number(value.disponibilita);
            r.data.prodotto.disponibilita = Number(value.disponibilita);
            r.data.prodotto.disponibilitaReale = Number(value.disponibilita);
          }
          let carrello: CarrelloFire = {
            id: r.id,
            data: {
              NomeProdotto: value.NomeProdotto,
              disponibilitaReale: Number(value.disponibilita),
              emailUtente: r.data.emailUtente,
              idNotifica: r.data.idNotifica,
              idProdotto: r.data.idProdotto,
              idUtente: r.data.idUtente,
              immagineProdotto: value.immagineProdotto,
              prezzo: value.prezzo,
              quantita: r.data.quantita,
              quantitaDesiderata: r.data.quantitaDesiderata,
              slugProdotto: slugProdotto,
              visibility: r.data.visibility,
              prodotto: {
                NomeProdotto: value.NomeProdotto,
                categoria: value.categoria,
                categoriaDefault: value.categoriaDefault,
                categoriaFiglio: value.categoriaFiglio,
                descrizione: value.descrizione,
                disponibilita: value.disponibilita,
                disponibilitaReale: value.disponibilita,
                immagineProdotto: value.immagineProdotto,
                prezzo: value.prezzo,
                slugProdotto: slugProdotto,
                ultimaCategoria: value.ultimaCategoria,
                reviews: value.reviews,
                stelle: value.stelle,
                dataInserimento: value.dataInserimento,
                inOfferta: value.inOfferta,
                prezzoPrecedente: value.prezzoPrecedente,
                scontoPercentuale: value.scontoPercentuale
              } as DatiProdotto
            } as DatiCarrelloFire
          };
          this.pageService.editProdottoSuCarrelloFire(carrello.id, carrello.data).then();
          if (Number(value.disponibilita) == 0) {
            let obj: DatiNotifica = {
              dataNotifica: new Date(),
              idCarrello: r.id,
              idProdotto: r.data.idProdotto,
              idUtente: r.data.idUtente,
              nomeProdotto: r.data.NomeProdotto,
              quantita: r.data.quantitaDesiderata,
              scelta: StatoNotifica.ProdottoEsaurito,
              immagineProdotto: r.data.immagineProdotto,
              notificaInviataAlCliente: false,
              isNotificaSistema: true,
              idOrdine: null,
              isVisualizzata: false,
              codiceSpedizione: null,
              corriereAffidato: null
            }
            this.pageService.inviaNotifica(obj)
          }
        },
        )
      }),
    ).subscribe();



    of(value as DatiProdotto).pipe(
      mergeMap(value =>
        iif(() => value.NomeProdotto === this.product.data.NomeProdotto,
          of(value).pipe(
            tap((v) => {
              let obj: DatiProdotto = {
                NomeProdotto: v.NomeProdotto,
                categoria: v.categoria,
                categoriaDefault: v.categoriaDefault,
                categoriaFiglio: v.categoriaFiglio,
                descrizione: v.descrizione,
                disponibilita: v.disponibilita,
                disponibilitaReale: v.disponibilita,
                immagineProdotto: v.immagineProdotto,
                prezzo: v.prezzo,
                slugProdotto: v.slugProdotto,
                ultimaCategoria: v.ultimaCategoria,
                reviews: v.reviews,
                stelle: v.stelle,
                dataInserimento: v.dataInserimento,
                inOfferta: v.inOfferta,
                prezzoPrecedente: v.prezzoPrecedente,
                scontoPercentuale: v.scontoPercentuale
              }
              this.modificaProdotto(this.product.id, obj);
            })
          ),
          of(value).pipe(
            tap((v) => {
              let slugProdotto = this.creaSlug(value.NomeProdotto);
              let obj: DatiProdotto = {
                NomeProdotto: v.NomeProdotto,
                categoria: v.categoria,
                categoriaDefault: v.categoriaDefault,
                categoriaFiglio: v.categoriaFiglio,
                descrizione: v.descrizione,
                disponibilita: v.disponibilita,
                disponibilitaReale: v.disponibilita,
                immagineProdotto: v.immagineProdotto,
                prezzo: v.prezzo,
                slugProdotto: slugProdotto,
                ultimaCategoria: v.ultimaCategoria,
                reviews: v.reviews,
                stelle: v.stelle,
                dataInserimento: v.dataInserimento,
                inOfferta: v.inOfferta,
                prezzoPrecedente: v.prezzoPrecedente,
                scontoPercentuale: v.scontoPercentuale
              }
              this.modificaProdotto(this.product.id, obj);
            })
          )
        ))
    ).subscribe();
  }

  creaSlug(value) {
    let valoreIniziale = value;
    var slugLowerValoreIniziale = valoreIniziale.toLowerCase();
    return slugLowerValoreIniziale.replace(/ /g, '_');
  }

  modificaProdotto(id, value) {
    this.cart.modificaProdottoByID(id, value).then(
      res => {
        if (this.isInAmministrazione == false) {
          let vai = this.router.routerState.snapshot.url;
          if (vai == '/shop/catalog/in_offerta') {
            this.router.navigateByUrl(vai)
          }
        }
        let config = new MatSnackBarConfig();
        config.verticalPosition = this.verticalPosition;
        config.horizontalPosition = this.horizontalPosition;
        config.duration = this.setAutoHide ? this.autoHide : 0;
        this.snackBar.open(this.messageOk, this.action ? this.actionButtonLabel : undefined, config);
        this.chiudiModale.emit();
      }
    )
  }

}
