export const environment = {
  production: true,
  firebase: {
    apiKey: "AIzaSyDDYO1-jQ664h1f50jb6DHlVwr9ZXnP93g",
    authDomain: "laboutiquedelcaffe.web.app",
    databaseURL: "https://boutiquerafactory.firebaseio.com",
    projectId: "boutiquerafactory",
    storageBucket: "boutiquerafactory.appspot.com",
    messagingSenderId: "556306495573",
    appId: "1:556306495573:web:d58ab294eda8aa27dc7cdc",
    measurementId: "G-KRJN6GH12J"
  }
};
