<div class="site-header">
    <div class="site-header__nav-panel">
        <app-header-nav
            [departments]="layout === 'classic'"
            [logo]="layout === 'compact'"
            [search]="layout === 'compact'"
            stickyMode="pullToShow"
        ></app-header-nav>
    </div>
</div>
