<h3 class="card-title">Registrazione Rapida</h3>
<form [formGroup]="addNuovoUtente" autocomplete="off">
  <div class="form-group">
    <mat-form-field class="example-full-width w-50 pr-2">
      <mat-label>Nome</mat-label>
      <input matInput formControlName="Nome" #nome autocomplete="off">
    </mat-form-field>
    <mat-form-field class="example-full-width w-50 pl-2">
      <mat-label>Cognome</mat-label>
      <input matInput formControlName="Cognome" #cognome autocomplete="off">
    </mat-form-field>
    <mat-form-field class="example-full-width w-100">
      <mat-label>Email</mat-label>
      <input matInput formControlName="Email" #email autocomplete="off">
    </mat-form-field>
    <mat-form-field appearance="fill" class="example-full-width w-100">
      <mat-label>Password</mat-label>
      <input matInput [type]="hide ? 'password' : 'text'" formControlName="Password" #Pass autocomplete="off">
      <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
        [attr.aria-pressed]="hide">
        <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
      </button>
    </mat-form-field>
  </div>
  <div class="form-group d-flex justify-content-end">
    <button class="submit-button btn btn-sm btn-primary text-white pl-4 pr-4 pt-1 pb-1 " color="primary" type="submit"
      [disabled]="!addNuovoUtente.valid" (click)="registrati(addNuovoUtente.value)">Registrati</button>
  </div>
</form>
<span>Successivamente ti verrà richiesto di inserire i dati relativi alla spedizione e fatturazione</span>