<ul class="mobile-links mobile-links--level-2" appCollapse>
  <li *ngFor="let link of items">
    <div class="mobile-links__item" appCollapseItem="mobile-links__item--open" #item="appCollapseItem">
      <div class="mobile-links__item-title d-flex align-items-center categoryElement">
        <a class="mobile-links__item-link" [ngStyle]="{'cursor': isAdministration ? 'default' : 'pointer' }"
          (click)="onItemClick(link)">{{ link.data.nomeCategoria }}</a>
        <div class="d-flex mr-3" *ngIf="isAdministration">
          <span class="material-icons d-none mr-1" style="cursor: pointer;" id="modifica" (click)="modifica(link)">
            create
          </span>
          <span class="material-icons d-none text-danger" id="cancella" style="cursor: pointer;"
            (click)="cancella(link)">
            delete
          </span>
        </div>
      </div>
    </div>
  </li>
  <li *ngIf="isAdministration">
    <div class="mobile-links__item" appCollapseItem="mobile-links__item--open" #item="appCollapseItem">
      <div class="mobile-links__item-title d-flex align-items-center categoryElement">
        <a class="mobile-links__item-link" style="cursor: pointer;"
          (click)="addSottoCategoria()">Aggiungi categoria</a>
      </div>
    </div>
  </li>
</ul>