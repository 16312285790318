import { Component, EventEmitter, Output, OnInit } from '@angular/core';
import { CartService } from '../../../../shared/services/cart.service';
import { CartItem } from '../../../../shared/interfaces/cart-item';
import { RootService } from '../../../../shared/services/root.service';
import { tap, filter } from 'rxjs/operators';
import { CarrelloModels } from 'src/app/shared/models/CarrelloModels';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { DatiUtente } from 'src/app/shared/models/UtenteModels';
import { pageService } from 'src/app/modules/account/pages/pageService';
import { CarrelloFire } from 'src/app/shared/models/CarrelloFire';

@Component({
  selector: 'app-header-dropcart',
  templateUrl: './dropcart.component.html',
  styleUrls: ['./dropcart.component.scss'],
  providers: [pageService]
})
export class DropcartComponent implements OnInit {
  removedItems: CarrelloModels[] = [];

  @Output() closeMenu: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    public cart: CartService,
    public root: RootService,
    public authService: AuthService,
    public pageService: pageService
  ) { }

  prodotti;
  costoProdotto;
  contenitoreCosto: Array<any> = []
  costoFinale;
  public utente: DatiUtente;
  public carrelloFire: CarrelloFire[];
  public costoFinaleFire;
  public costoSpedizione: number;
  public sommaProdottiOrdine: number;

  ngOnInit(): void {

    this.authService.user$.pipe(
      tap((v) => this.utente = v),
      filter((v) => !!v),
      tap((v:DatiUtente) => {
        this.pageService.getCarrelloFireByID(v.uid).pipe(
          tap((v) => {
            this.carrelloFire = v;
            if(v.length > 0){
              let contenitore = [];
              v.forEach(element => {
                let costoProdotto = Number(element.data.quantita) * Number(element.data.prezzo);
                contenitore.push(costoProdotto);
              }),
                this.costoFinaleFire = contenitore.reduce(function (a, b) { return a + b });
                this.sommaProdottiOrdine = this.costoFinaleFire;
            }
          }),
          tap(() => {
            if (this.sommaProdottiOrdine >= 50) {
              this.costoSpedizione = 0;
            } else {
              this.costoSpedizione = 8;
              this.costoFinaleFire = this.costoFinaleFire + 8;
            }
          })
        ).subscribe();
      })
    ).subscribe();


    this.cart.items$.pipe(
      filter((t) => !!t),
      tap((t) => this.calcolaTotale(t))
    ).subscribe()
      ;

    this.cart.ricarica.pipe(
      filter((t) => !!t),
      tap((t) => { this.calcolaTotale(t) })
    ).subscribe()
  }


  calcolaTotale(value) {
    if (value.length >= 1) {
      this.contenitoreCosto = [];
      value.forEach(element => {
        this.costoProdotto = Number(element.quantity) * Number(element.cartItem.product.data.prezzo);
        this.contenitoreCosto.push(this.costoProdotto);
      }),
        this.costoFinale = this.contenitoreCosto.reduce(function (a, b) { return a + b });
        this.sommaProdottiOrdine = this.costoFinale;
        if (this.sommaProdottiOrdine >= 50) {
          this.costoSpedizione = 0;
        } else {
          this.costoSpedizione = 8;
          this.costoFinale = this.costoFinale + 8;
        }
      return this.costoFinale
    }

  }

  remove(item: CarrelloModels): void {
    if (this.removedItems.includes(item)) {
      return;
    }

    this.removedItems.push(item);
    this.cart.remove(item).subscribe({ complete: () => this.removedItems = this.removedItems.filter(eachItem => eachItem !== item) });
  }

  removeFire(item: CarrelloFire){
    this.cart.removeFire(item)
  }
}
