<div class="dropcart">
  <ng-container *ngIf="notifiche.length >= 1 else notNotifica">
    <div class="dropcart__products-list pb-2">
      <div class="dropcart__product" *ngFor="let notifica of notifiche">
        <!-- ordine ricevuto -->
        <ng-container *ngIf="notifica?.data?.scelta == statoNotifica.Inviata">
          <div class="dropcart__product-info">
            <div class="dropcart__product-name" style="cursor: pointer; outline: none;"
              (click)="confermaLetturaNotifica(notifica)" [routerLink]="['/account/orders', notifica?.data?.idOrdine]">
              <b>Ordine inoltrato!</b><br>
              <span>Riceverai una notifica quando l'ordine sarà confermato</span>
              <footer class="blockquote-footer text-right">{{notifica?.data?.dataNotifica.toDate() | date:"dd/MM/yy"
                }}</footer>
            </div>
          </div>
        </ng-container>

        <!-- avviso prodotto non piu disponibile disponibile -->
        <ng-container *ngIf="notifica?.data?.scelta == statoNotifica.ProdottoEsaurito">
          <div class="dropcart__product-image product-image">
            <a class="product-image__body">
              <img class="product-image__img immagineCarrello" alt=""
                [ngStyle]="{ 'background-image': 'url(' + notifica?.data?.immagineProdotto + ')'}">
            </a>
          </div>
          <div class="dropcart__product-info">
            <div class="dropcart__product-name">
              <a>Il prodotto <b>{{notifica?.data?.nomeProdotto}}</b>, non è piu disponibile,
                desideri ricevere una notifica quando il prodotto sarà nuovamente disponibile?
              </a>
            </div>
            <div class="d-flex justify-content-center mt-3">
              <button class="btn btn-sm btn-light col-4 mr-2" (click)="procedi(notifica)">Si</button>
              <button class="btn btn-sm btn-light col-4" (click)="rifiuta(notifica)">No</button>
            </div>
          </div>
        </ng-container>
        <!-- Richiesta avviso prodotto disponibile -->
        <ng-container *ngIf="notifica?.data?.scelta == statoNotifica.RichiestaAvvisoProdottoDisponibile">
          <div class="dropcart__product-image product-image">
            <a class="product-image__body">
              <img class="product-image__img immagineCarrello" alt=""
                [ngStyle]="{ 'background-image': 'url(' + notifica?.data?.immagineProdotto + ')'}">
            </a>
          </div>
          <div class="dropcart__product-info">
            <div class="dropcart__product-name">
              <a>Il prodotto <b>{{notifica?.data?.nomeProdotto}}</b>, non è piu disponibile,
                riceverai una notifica quando il prodotto sarà nuovamente disponibile.
              </a>
            </div>
          </div>
        </ng-container>
        <!-- Prodotto nuovamente disponibile -->
        <ng-container *ngIf="notifica?.data?.scelta == statoNotifica.ProdottoDisponibile">
          <div class="dropcart__product-image product-image">
            <a class="product-image__body">
              <img class="product-image__img immagineCarrello" alt=""
                [ngStyle]="{ 'background-image': 'url(' + notifica?.data?.immagineProdotto + ')'}">
            </a>
          </div>
          <div class="dropcart__product-info">
            <div class="dropcart__product-name">
              <a>Il prodotto <b>{{notifica?.data?.nomeProdotto}}</b>, è disponibile,
                desideri aggiungere al carrello il prodotto?
              </a>
            </div>
            <div class="d-flex justify-content-center mt-3">
              <button class="btn btn-sm btn-light col-4 mr-2" (click)="accettaAddCarrello(notifica)">Si</button>
              <button class="btn btn-sm btn-light col-4" (click)="rifiutaAddCarrello(notifica)">No</button>
            </div>
          </div>
        </ng-container>
        <!-- Ordine confermato -->
        <ng-container *ngIf="notifica?.data?.scelta == statoNotifica.OrdineConfermato">
          <div class="dropcart__product-info">
            <div class="dropcart__product-name" style="cursor: pointer; outline: none;"
              (click)="confermaLetturaNotifica(notifica)" [routerLink]="['/account/orders', notifica?.data?.idOrdine]">
              <b class="text-info">Buone notizie!</b><br>
              <span>Il suo ordine è stato confermato. A breve riceverai una notifica quando l'ordine sarà spedito</span>
              <footer class="blockquote-footer text-right">{{notifica?.data?.dataNotifica.toDate() | date:"dd/MM/yy"
                }}</footer>
            </div>
          </div>
        </ng-container>
        <!-- Ordine Rifiutato -->
        <ng-container *ngIf="notifica?.data?.scelta == statoNotifica.OrdineRifiutato">
          <div class="dropcart__product-info">
            <div class="dropcart__product-name" style="cursor: pointer; outline: none;"
              (click)="confermaLetturaNotifica(notifica)" [routerLink]="['/site/contact-us']">
              <b class="text-danger">Ci dispiace!</b><br>
              <span>Il suo ordine è stato rifiutato. Per maggiori informazioni contatta l'assistenza. </span>
              <footer class="blockquote-footer text-right">{{notifica?.data?.dataNotifica.toDate() | date:"dd/MM/yy"
                }}</footer>
            </div>
          </div>
        </ng-container>
        <!-- Ordine Spedito -->
        <ng-container *ngIf="notifica?.data?.scelta == statoNotifica.OrdineSpedito">
          <div class="dropcart__product-info">
            <div class="dropcart__product-name" style="cursor: pointer; outline: none;"
              (click)="confermaLetturaNotifica(notifica)" [routerLink]="['/account/orders', notifica?.data?.idOrdine]">
              <b class="text-success">Stiamo arrivando!</b><br>
              <span>Il suo ordine è stato spedito ed è in consegna ed è stato affidato a
                <b>{{notifica.data.corriereAffidato}}</b>, entro 48H il prodotto sarà consegnato. </span><br>
              <span>Potrà controllare la tracciabilità del suo pacco tramite <a
                  (click)="corriereService.recuperaRottaCorrieriDaNotifica(notifica, $event); confermaLetturaNotifica(notifica)"><b
                    class="specialLink">questo link</b></a>.</span><br>
              <footer class="blockquote-footer text-right">{{notifica?.data?.dataNotifica.toDate() | date:"dd/MM/yy"
                }}</footer>
            </div>
          </div>
        </ng-container>

        <!-- Notifica di Benvenuto -->
        <ng-container *ngIf="notifica?.data?.scelta == statoNotifica.NotificaBenvenuto">
          <div class="dropcart__product-info">
            <div class="dropcart__product-name" style="cursor: pointer; outline: none;"
              (click)="confermaLetturaNotifica(notifica)">
              <b class="text-success">Benvenuto nella Boutique del Caffè!</b><br>
              <span>Ti ringraziamo per averci scelto, ci teniamo a informarla che momentaneamente l'ecommerce non è
                ancora operativa.</span><br>
              <span>Ci dispiace per il disagio a breve le manderemo una notifica quando sarà possibile effettuare un
                ordine.</span><br>
              <span>Cordiali saluti il team della Boutique del Caffè</span>
              <footer class="blockquote-footer text-right">{{notifica?.data?.dataNotifica.toDate() | date:"dd/MM/yy"
                }}</footer>
            </div>
          </div>
        </ng-container>

        <!-- Notifica di accettazione ritiro in negozio -->
        <ng-container *ngIf="notifica?.data?.scelta == statoNotifica.RitiroInNegozioAccettato">
          <div class="dropcart__product-info">
            <div class="dropcart__product-name" style="cursor: pointer; outline: none;"
              (click)="confermaLetturaNotifica(notifica)">
              <b class="text-success">Buone notizie!</b><br>
              <span>Il suo ordine è stato accettato, a breve riceverai una notifica di conferma quando l'ordine sarà
                pronto per il ritiro.</span><br>
              <span>A presto!</span>
              <footer class="blockquote-footer text-right">{{notifica?.data?.dataNotifica.toDate() | date:"dd/MM/yy"
                }}</footer>
            </div>
          </div>
        </ng-container>

        <!-- notifica ordine concluso -->
        <ng-container *ngIf="notifica?.data?.scelta == statoNotifica.OrdineConcluso">
          <div class="dropcart__product-info">
            <div class="dropcart__product-name" style="cursor: pointer; outline: none;"
              (click)="confermaLetturaNotifica(notifica)" [routerLink]="['/account/orders', notifica?.data?.idOrdine]">
              <b class="text-success">Ordine consegnato!</b><br>
              <span>Grazie per averci scelto, il suo ordine è stato consegnato.</span><br>
              <footer class="blockquote-footer text-right">{{notifica?.data?.dataNotifica.toDate() | date:"dd/MM/yy"
                }}</footer>
            </div>
          </div>
        </ng-container>
      </div>
    </div>

  </ng-container>
</div>

<ng-template #notNotifica>
  <div class="dropcart__products-list">
    <div class="dropcart__product">
      <div class="dropcart__product-info">
        <a>Non è presente nessuna notifica da visualizzare.
        </a>
      </div>
    </div>
  </div>
</ng-template>
