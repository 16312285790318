<ul class="mobile-links mobile-links--level--0" appCollapse>
  <li>
    <div class="mobile-links__item" appCollapseItem="mobile-links__item--open" #item="appCollapseItem">
      <div class="mobile-links__item-title">
        <a class="mobile-links__item-link" routerLink="/" (click)="chiudi()">Home</a>
      </div>
    </div>
  </li>
  <li *ngFor="let link of items">
    <div class="mobile-links__item" appCollapseItem="mobile-links__item--open" #item="appCollapseItem">
      <div class="mobile-links__item-title">
        <a class="mobile-links__item-link" (click)="item.toggle(); chiudiMenu(link)">{{ link.data.nomeCategoria }}</a>
        <button *ngIf="link.data.sottoCategoria == 'true'" class="mobile-links__item-toggle" type="button"
          (click)="item.toggle(); ">
          <app-icon class="mobile-links__item-arrow" name="arrow-rounded-down-12x7" size="12x7"></app-icon>
        </button>
      </div>
      <div *ngIf="link.data.sottoCategoria == 'true'" class="mobile-links__item-sub-links" appCollapseContent>
        <app-link-submenu [subCategory]="link" [isMobile]="true" (itemClick)="itemClick.emit($event)">
        </app-link-submenu>
      </div>
    </div>
    <div *ngIf="link.type === 'divider'" class="mobile-links__divider"></div>
  </li>
  <li>
    <div class="mobile-links__item" appCollapseItem="mobile-links__item--open" #item="appCollapseItem">
      <div class="mobile-links__item-title">
        <a class="mobile-links__item-link" routerLink="/shop/catalog/in_offerta" (click)="chiudi()">In Offerta</a>
      </div>
    </div>
  </li>
  <li>
    <div class="mobile-links__item" appCollapseItem="mobile-links__item--open" #item="appCollapseItem">
      <div class="mobile-links__item-title">
        <a class="mobile-links__item-link" routerLink="/site/contact-us" (click)="chiudi()">Contattaci</a>
      </div>
    </div>
  </li>
</ul>
