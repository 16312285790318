<div class="nav-panel" [ngClass]="{'nav-panel--sticky': stickyMode}" #element>
  <div class="nav-panel__container container-xxl">
    <div class="nav-panel__row">

      <img src="https://firebasestorage.googleapis.com/v0/b/boutiquerafactory.appspot.com/o/img-prodotti%2Flogomini.png?alt=media&token=9f6bbe95-030e-4a33-95f1-f809c4f00ff6">

      <ng-container *ngIf="showLoader">
        <div class="d-flex h-100 w-100 align-items-end">
          <mat-progress-bar mode="indeterminate" value="40"></mat-progress-bar>
        </div>
      </ng-container>
      <div class="nav-links__list" *ngIf="!showLoader">
        <span class="nav-links__item-link ml-2 mr-3" style="cursor: pointer;" routerLink="/"> Home</span>

        <ng-container *ngFor="let menuItem of modulesList" class="nav-links__item ">
          <app-indicator [label]="menuItem.data.nomeCategoria" class="mr-3" icon="arrow-rounded-down-9x6"
            trigger="hover" #indicator="appIndicator">
            <app-header-megamenu [menu]="menuItem" (itemClick)="indicator.close(true)"></app-header-megamenu>
          </app-indicator>
        </ng-container>
        <span class="nav-links__item-link mr-2" style="cursor: pointer;" routerLink="/shop/catalog/in_offerta"> In Offerta</span>
        <span class="nav-links__item-link ml-3" style="cursor: pointer;" routerLink="/site/contact-us"> Contatti</span>
      </div>
      <!-- .nav-links / end -->

      <div class="nav-panel__indicators">
<!--
        <app-indicator id="search" icon="search-20" trigger="click" #indicator="appIndicator">
          <app-search-product (closeMenu)="indicator.close(true)"></app-search-product>
        </app-indicator> -->


        <app-indicator id="notificaCustom" icon="bell-20" trigger="click" *ngIf="utente" #indicator="appIndicator"
          [counter]="countNotificaFire">
          <app-notifications (closeMenu)="indicator.close(true)"></app-notifications>
        </app-indicator>

        <ng-container *ngIf="utente else notUser">
          <app-indicator id="carrelloMenuCustom" link="./shop/cart" icon="cart-20" trigger="click"
            [counter]="countCartFire" #indicator="appIndicator">
            <app-header-dropcart (closeMenu)="indicator.close(true)"></app-header-dropcart>
          </app-indicator>
        </ng-container>

        <ng-template #notUser>
          <app-indicator id="carrelloMenuCustom" link="./shop/cart" icon="cart-20" trigger="click"
            [counter]="cart.quantity$|async" #indicator="appIndicator">
            <app-header-dropcart (closeMenu)="indicator.close(true)"></app-header-dropcart>
          </app-indicator>
        </ng-template>


        <app-indicator id="accountMenuCustom" link="./account" icon="person-20" trigger="click"
          #indicatorAccount="appIndicator">
          <app-account-menu (closeMenuAccount)="indicatorAccount.close(true)"></app-account-menu>
        </app-indicator>
      </div>
    </div>
  </div>
</div>
