import { Injectable } from '@angular/core';
import { filter, map } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/firestore';
import { ProdottoModels } from 'src/app/shared/models/ProdottoModels';
import { CategoriaGenitoreModels } from 'src/app/shared/models/CategoriaGenitoreModels';
import { CategoriaFiglioModels } from 'src/app/shared/models/CategoriaFiglioModels';
import { UltimaCategoriaModels } from 'src/app/shared/models/UltimaCategoriaModels';

@Injectable()
export class PageCategoryService {

  public prodotti: any;

  constructor(public db: AngularFirestore) { }

  getInfoProdotto(nome) {
    return this.db.collection('prodotti-inseriti', res => res.where('categoriaPadre', '==', nome).limit(1)).snapshotChanges().pipe(
      map(changes => {
        return changes.map(doc => {
          return {
            id: doc.payload.doc.id,
            data: doc.payload.doc.data()
          }
        })
      })
    )
  }

  getAllCategoriagenitore() {
    return this.db.collection('categoria-genitore', res => res.orderBy("nomeCategoria", "asc")).snapshotChanges().pipe(
      map(changes => {
        return changes.map(doc => {
          return {
            id: doc.payload.doc.id,
            data: doc.payload.doc.data()
          } as CategoriaGenitoreModels
        })
      })
    )
  }

  getAllCategoryFiglio(catGenitore) {
    return this.db.collection('categoria-figlio', res => res.where('catGenitore', '==', catGenitore).orderBy("nomeCategoria", "asc")).snapshotChanges().pipe(
      map(changes => {
        return changes.map(doc => {
          return {
            id: doc.payload.doc.id,
            data: doc.payload.doc.data()
          }
        })
      })
    )
  }

  getAllCatFiglio() {
    return this.db.collection('categoria-figlio', res => res.orderBy("nomeCategoria", "asc")).snapshotChanges().pipe(
      map(changes => {
        return changes.map(doc => {
          return {
            id: doc.payload.doc.id,
            data: doc.payload.doc.data()
          } as CategoriaFiglioModels
        })
      })
    )
  }

  getAllUltimaCategoria(catFiglio) {
    return this.db.collection('ultima-categoria', res => res.where('catFiglio', '==', catFiglio).orderBy("nomeCategoria", "asc")).snapshotChanges().pipe(
      map(changes => {
        return changes.map(doc => {
          return {
            id: doc.payload.doc.id,
            data: doc.payload.doc.data()
          }
        })
      })
    )
  }

  getAllUltimaCat() {
    return this.db.collection('ultima-categoria', res => res.orderBy("nomeCategoria", "asc")).snapshotChanges().pipe(
      map(changes => {
        return changes.map(doc => {
          return {
            id: doc.payload.doc.id,
            data: doc.payload.doc.data()
          } as UltimaCategoriaModels
        })
      })
    )
  }

  getAllProdotti() {
    return this.db.collection('prodotti-inseriti').snapshotChanges().pipe(
      map(changes => {
        return changes.map(doc => {
          return {
            id: doc.payload.doc.id,
            data: doc.payload.doc.data()
          } as ProdottoModels
        })
      })
    )
  }

  getAllProdottiLimit() {
    return this.db.collection('prodotti-inseriti', res => res.limit(10)).snapshotChanges().pipe(
      map(changes => {
        return changes.map(doc => {
          return {
            id: doc.payload.doc.id,
            data: doc.payload.doc.data()
          } as ProdottoModels
        })
      })
    )
  }

  getAllProdottiByCategoriaPrincipale(name) {
    return this.db.collection('prodotti-inseriti', res => res.where('categoria', '==', name)).snapshotChanges().pipe(
      map(changes => {
        return changes.map(doc => {
          return {
            id: doc.payload.doc.id,
            data: doc.payload.doc.data()
          } as ProdottoModels
        })
      })
    )
  }

  getAllProdottiByCategoriaPrincipaleLimit(name) {
    return this.db.collection('prodotti-inseriti', res => res.where('categoria', '==', name).limit(10)).snapshotChanges().pipe(
      map(changes => {
        return changes.map(doc => {
          return {
            id: doc.payload.doc.id,
            data: doc.payload.doc.data()
          } as ProdottoModels
        })
      })
    )
  }

  getAllProdottiInOfferta() {
    return this.db.collection('prodotti-inseriti', res => res.where('inOfferta', '==', true)).get().pipe(
      map(changes => {
        return changes.docs.map(doc => {
          return {
            id: doc.id,
            data: doc.data()
          } as ProdottoModels
        })
      })
    )
  }

  getAllProdottiInOffertaLimit() {
    return this.db.collection('prodotti-inseriti', res => res.where('inOfferta', '==', true).limit(10)).get().pipe(
      map(changes => {
        return changes.docs.map(doc => {
          return {
            id: doc.id,
            data: doc.data()
          } as ProdottoModels
        })
      })
    )
  }

  getAllProdottiByFiglio(name) {
    return this.db.collection('prodotti-inseriti', res => res.where('categoriaFiglio', '==', name)).snapshotChanges().pipe(
      map(changes => {
        return changes.map(doc => {
          return {
            id: doc.payload.doc.id,
            data: doc.payload.doc.data()
          } as ProdottoModels
        })
      })
    )
  }

  getAllProdottiByFiglioLimit(name) {
    return this.db.collection('prodotti-inseriti', res => res.where('categoriaFiglio', '==', name).limit(10)).snapshotChanges().pipe(
      map(changes => {
        return changes.map(doc => {
          return {
            id: doc.payload.doc.id,
            data: doc.payload.doc.data()
          } as ProdottoModels
        })
      })
    )
  }

  getAllProdottiBySottoCategoria(ultimaCat, catFiglio) {
    return this.db.collection('prodotti-inseriti', res => res.where('ultimaCategoria', '==', ultimaCat).where('categoriaFiglio', '==', catFiglio)).snapshotChanges().pipe(
      map(changes => {
        return changes.map(doc => {
          return {
            id: doc.payload.doc.id,
            data: doc.payload.doc.data()
          } as ProdottoModels
        })
      })
    )
  }

  getAllProdottiBySottoCategoriaLimit(ultimaCat, catFiglio) {
    return this.db.collection('prodotti-inseriti', res => res.where('ultimaCategoria', '==', ultimaCat).where('categoriaFiglio', '==', catFiglio).limit(10)).snapshotChanges().pipe(
      map(changes => {
        return changes.map(doc => {
          return {
            id: doc.payload.doc.id,
            data: doc.payload.doc.data()
          } as ProdottoModels
        })
      })
    )
  }

  getAllProdottiByCategoriaSistema(categoria, nomeSistema) {
    return this.db.collection('prodotti-inseriti', res => res.where('categoria', '==', categoria).where('categoriaSistemaCapsule', '==', nomeSistema)).snapshotChanges().pipe(
      map(changes => {
        return changes.map(doc => {
          return {
            id: doc.payload.doc.id,
            data: doc.payload.doc.data()
          }
        })
      })
    )
  }

  getAllProdottiByCategoriaSistemaCialde(nomeSistema) {
    return this.db.collection('prodotti-inseriti', res => res.where('categoria', '==', 'Caffè in Cialde').where('slugBrandCialde', '==', nomeSistema)).snapshotChanges().pipe(
      map(changes => {
        return changes.map(doc => {
          return {
            id: doc.payload.doc.id,
            data: doc.payload.doc.data()
          }
        })
      })
    )
  }

  getAllProdottoFood(categoriaFood) {
    return this.db.collection('prodotti-inseriti', res => res.where('categoriaFood', '==', categoriaFood)).snapshotChanges().pipe(
      map(changes => {
        return changes.map(doc => {
          return {
            id: doc.payload.doc.id,
            data: doc.payload.doc.data()
          }
        })
      })
    )
  }

  getProdottoFoodByCategoria(categoriaFood) {
    return this.db.collection('prodotti-inseriti', res => res.where('slugBrandVillaReale', '==', categoriaFood)).snapshotChanges().pipe(
      map(changes => {
        return changes.map(doc => {
          return {
            id: doc.payload.doc.id,
            data: doc.payload.doc.data()
          }
        })
      })
    )
  }

  getProdottoFoodDonPuglisi(categoriaFood) {
    return this.db.collection('prodotti-inseriti', res => res.where('slugBrandDonPuglisi', '==', categoriaFood)).snapshotChanges().pipe(
      map(changes => {
        return changes.map(doc => {
          return {
            id: doc.payload.doc.id,
            data: doc.payload.doc.data()
          }
        })
      })
    )
  }

  getAllProdottiBySlug(slug) {
    return this.db.collection('prodotti-inseriti', res => res.where('slugBrandCapsule', '==', slug)).snapshotChanges().pipe(
      map(changes => {
        return changes.map(doc => {
          return {
            id: doc.payload.doc.id,
            data: doc.payload.doc.data()
          }
        })
      })
    )
  }

  getProdottoBySlugName(slug) {
    return this.db.collection('prodotti-inseriti', res => res.where('slugProdotto', '==', slug)).snapshotChanges().pipe(
      filter((v) => !!v),
      map(changes => {
        return changes.map(doc => {
          return {
            id: doc.payload.doc.id,
            data: doc.payload.doc.data()
          } as ProdottoModels
        })
      })
    )
  }

  getProdottiHomePage(name) {
    return this.db.collection('prodotti-inseriti', res => res.where('categoria', '==', name).limit(15)).snapshotChanges().pipe(
      map(changes => {
        return changes.map(doc => {
          return {
            id: doc.payload.doc.id,
            data: doc.payload.doc.data()
          } as ProdottoModels
        })
      })
    )
  }
}
