import { AfterViewChecked, Component, OnDestroy, OnInit, Input, Output } from '@angular/core';
import { EventEmitter } from 'protractor';
import { Subject } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { pageService } from 'src/app/modules/account/pages/pageService';
import { CategoriaGenitoreModels } from 'src/app/shared/models/CategoriaGenitoreModels';

@Component({
  selector: 'app-header-links',
  templateUrl: './links.component.html',
  styleUrls: ['./links.component.scss'],
  providers: [pageService]
})
export class LinksComponent implements OnInit {
  modulesList: CategoriaGenitoreModels[];

  @Input() public chiudiMenu: any;

  constructor(
    public pageService: pageService,
  ) {
  }
  ngOnInit(): void {
    this.pageService.getCategoriaPrincipaleHeader().pipe(
      filter((v) => !!v),
      tap((v) => {
        this.modulesList = v;
      })
    ).subscribe();

  }


  menuClosed() {
  }

}
