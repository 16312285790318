import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { filter, tap } from 'rxjs/operators';
import { pageService } from 'src/app/modules/account/pages/pageService';
import { PageCategoryInternalService } from 'src/app/modules/shop/pages/page-category/page-categoty-internal.service';
import { WidgetFilterInterface, WIDGET_FILTER_INTERFACE } from 'src/app/modules/widgets/widget-filters/widget.interface';
import { CategoriaFiglioModels } from 'src/app/shared/models/CategoriaFiglioModels';
import { CategoriaGenitoreModels } from 'src/app/shared/models/CategoriaGenitoreModels';
import { IMegaMenu, MEGA_MENU_TOKEN } from './megamenu.interface';

@Component({
  selector: 'app-header-megamenu',
  templateUrl: './megamenu.component.html',
  styleUrls: ['./megamenu.component.scss']
})
export class MegamenuComponent implements OnInit {
  @Input() menu;

  public vociMenu: CategoriaFiglioModels[];
  public colonnaSinistra: CategoriaFiglioModels[];
  public colonnaDestra: CategoriaFiglioModels[];

  @Output() itemClick: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    public pageService: pageService,
    private router: Router,
    @Inject(MEGA_MENU_TOKEN) public IMegaMenu: IMegaMenu
  ) { }

  ngOnInit(): void {
    this.pageService.getCategoriaFiglio(this.menu.data.slugCategoria).pipe(
      filter((v) => !!v),
      tap((v) => {
        this.vociMenu = v;
        this.IMegaMenu.vociMenu = v;
      })
    ).subscribe(
      res => {
        if (this.menu.data.numeroColonne == 2) {
          this.colonnaSinistra = this.vociMenu.filter(x => x.data.posizione == true);
          this.colonnaDestra = this.vociMenu.filter(x => x.data.posizione == false);
          this.IMegaMenu.colonnaSinistra = this.colonnaSinistra;
          this.IMegaMenu.colonnaDestra = this.colonnaDestra;
        }
      }
    );
  }

  naviga(value, prodotto) {
    this.itemClick.emit(true);
    this.router.navigate(['/shop/catalog/' + value]);
    // this.megamenuInterface.clearFiglio$.next(true)
  }

  navigaFiglio(padre, figlio, value) {
    let dato: { id: number, value: any } = { id: 2, value: value };
    this.itemClick.emit(true);

    this.router.navigate(['/shop/catalog/' + padre + '/' + figlio]);
  }

  chiudi() {
    this.itemClick.emit(true)
  }
}
