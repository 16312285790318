import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { RootService } from '../../../shared/services/root.service';
import { iif, of, Subject } from 'rxjs';
import { PageCategoryService } from '../../shop/services/page-category.service';
import { Router } from '@angular/router';
import { filter, mergeMap, switchMap, tap } from 'rxjs/operators';
import { WidgetFilterInterface, WIDGET_FILTER_INTERFACE } from './widget.interface';
import { CommonInterface, COMMON_INTERFACE_TOKEN } from 'src/app/shared/interfaces/common.interface';
import { CategoriaGenitoreModels } from 'src/app/shared/models/CategoriaGenitoreModels';

@Component({
  selector: 'app-widget-filters',
  templateUrl: './widget-filters.component.html',
  styleUrls: ['./widget-filters.component.scss'],
  providers: [PageCategoryService]
})
export class WidgetFiltersComponent implements OnInit, OnDestroy {

  @Input() offcanvas: 'always' | 'mobile' = 'mobile';
  @Output() cancellaSlugEvent: EventEmitter<any> = new EventEmitter();

  destroy$: Subject<void> = new Subject<void>();
  public items: CategoriaGenitoreModels[];
  public showLoader: boolean;

  constructor(
    public root: RootService,
    public pageCategory: PageCategoryService,
    public router: Router,
    @Inject(WIDGET_FILTER_INTERFACE) public wigetFilterInterface: WidgetFilterInterface,
    @Inject(COMMON_INTERFACE_TOKEN) public commonInterface: CommonInterface
  ) {
  }


  ngOnInit(): void {
    this.showLoader = true;
    if(!this.commonInterface.listaCategoriePrincipali){
      this.pageCategory.getAllCategoriagenitore().pipe(
        filter((v) => !!v),
        tap((v) => {
          this.items = v;
          this.commonInterface.listaCategoriePrincipali = v
        })
      ).subscribe(res => this.showLoader = false);
    }else{
      this.items = this.commonInterface.listaCategoriePrincipali
      this.showLoader = false;
    }
  }

  goToTutti() {
    let dato: { id: number, value: any } =
    {
      id: 0,
      value: 'tutti_i_prodotti'
    };
    this.wigetFilterInterface.salvaDato$.next(dato);
    this.wigetFilterInterface.clearPadre$.next(true);
    this.router.navigate(['/shop/catalog/' + 'tutti_i_prodotti'])
  }

  inviaDato(v) {
    this.wigetFilterInterface.salvaDato$.next(v);
  }

  onItemClick(value) {
    let dato: { id: number, value: any } =
    {
      id: 1,
      value: value
    };
    this.wigetFilterInterface.clearFiglio$.next(true);
    this.wigetFilterInterface.salvaDato$.next(dato);
    this.router.navigate(['/shop/catalog/' + value.data.slugCategoria])
  }

  cancellaSLug(value) {
    this.cancellaSlugEvent.emit(true)
    this.wigetFilterInterface.salvaDato$.next(value);
  }

  ngOnDestroy(): void {
  }
}
