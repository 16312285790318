import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition, MatSnackBarConfig, MatSnackBar } from '@angular/material/snack-bar';
import { DialogData } from 'src/app/modules/account/pages/page-addresses-list/page-addresses-list.component';
import { pageService } from 'src/app/modules/account/pages/pageService';
import { DatiUtente } from '../../models/UtenteModels';
import { AuthService } from '../../services/auth/auth.service';
import { INuovoIndirizzoPersonaFisica, NUOVO_INDIRIZZO_PERSONA_FISICA_TOKEN } from './nuovo-indirizzo-persona-fisica-template.interface';

@Component({
  selector: 'app-nuovo-indirizzo-persona-fisica-template',
  templateUrl: './nuovo-indirizzo-persona-fisica-template.component.html',
  styleUrls: ['./nuovo-indirizzo-persona-fisica-template.component.scss'],
  providers: [pageService]

})
export class NuovoIndirizzoPersonaFisicaTemplateComponent implements OnInit {


  nuovoIndirizzo: FormGroup;
  messageOk: string = 'Registrazione indirizzo avvenuta correttamente.';
  messageDelete: string = 'Cancellazione Avvenuta.';
  actionButtonLabel: string = '';
  action: boolean = true;
  setAutoHide: boolean = true;
  autoHide: number = 2000;
  horizontalPosition: MatSnackBarHorizontalPosition = 'right';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';

  @Input('utente') utente: DatiUtente;

  constructor(
    private fb: FormBuilder,
    public pageService: pageService,
    public snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<DialogData>,
    @Inject(NUOVO_INDIRIZZO_PERSONA_FISICA_TOKEN) public INuovoIndirizzoPF: INuovoIndirizzoPersonaFisica
  ) {
    this.createFormIndirizzo()
   }

  ngOnInit(): void {
  }

  createFormIndirizzo() {
    this.nuovoIndirizzo = this.fb.group({
      Nome: ['', [Validators.required]],
      Cognome: ['', [Validators.required]],
      Paese: ['', [Validators.required]],
      indirizzoSpedizione: ['', [Validators.required]],
      Citta: ['', [Validators.required]],
      indirizzoEmail: ['', [Validators.required, Validators.email]],
      telefono: ['', [Validators.required, Validators.minLength(10)]],
      codicePostale: ['', [Validators.required, Validators.minLength(5)]],
      provincia: ['', [Validators.required]],
      spedizioneGratuita: [false],
      interno: [''],
      palazzina: ['']
    })
  }

  salvaNuovoIndirizzo(value) {
    this.pageService.AddNuovoIndirizzo(this.utente.uid, value, this.utente).then(
      res => {
        let config = new MatSnackBarConfig();
        config.verticalPosition = this.verticalPosition;
        config.horizontalPosition = this.horizontalPosition;
        config.duration = this.setAutoHide ? this.autoHide : 0;
        this.snackBar.open(this.messageOk, this.action ? this.actionButtonLabel : undefined, config);
        this.INuovoIndirizzoPF.chiudiModale$.next(true);
      }
    )

  }

}
