import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { WidgetFilterInterface } from 'src/app/modules/widgets/widget-filters/widget.interface';
import { LinkSubmenuInterface } from 'src/app/shared/components/link-submenu/link-submenu.interface';
import { PageHeaderInterface } from 'src/app/shared/components/page-header/page-header.interface';


@Injectable({ providedIn: 'root' })
export class PageCategoryInternalService implements WidgetFilterInterface, LinkSubmenuInterface, PageHeaderInterface {
    ricaricaLista$: Subject<any> = new Subject<any>();
    public listaCategoriePrincipali: any = []

    public catPadre;
    public catFiglio;
    public sottoCategoria;

    clearPadre$: Subject<any> = new Subject<any>();
    clearFiglio$: Subject<any> = new Subject<any>();
    clearSottoCategoria$: Subject<any> = new Subject<any>();
    salvaDato$: Subject<any> = new Subject<any>();

    test(value){
      return this.clearFiglio$.next(value);
    }

}