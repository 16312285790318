<div class="product-card" [ngClass]="{
    'product-card--layout--grid product-card--size--sm': layout === 'grid-sm',
    'product-card--layout--grid product-card--size--nl': layout === 'grid-nl',
    'product-card--layout--grid product-card--size--lg': layout === 'grid-lg',
    'product-card--layout--list':                        layout === 'list',
    'product-card--layout--horizontal':                  layout === 'horizontal'
}">
  <div class="product-card__badges-list w-100" *ngIf="product.data.inOfferta == true">
    <div class="row justify-content-end w-100">
      <div class="address-card__badge address-card__badge--muted d-flex align-items-center col-auto">In Offerta <b class="ml-2"
          style="font-size: 16px;">-{{product.data.scontoPercentuale}}%</b></div>
    </div>
  </div>
  <div class="product-card__image product-image">
    <ng-container *ngIf="!isInOrdiniEffettuati">
      <div class="d-flex position-absolute fixed-top mt-4 ml-4" *ngIf="this.utente?.email === isAdmin">
        <span class="material-icons d-none mr-1" style="z-index: 999999; cursor: pointer;" id="modifica"
          (click)="modificaProdotto(product)">
          create
        </span>
        <span class="material-icons d-none text-danger" id="cancella" style="z-index: 999999; cursor: pointer;"
          (click)="cancellaProdotto(product)">
          delete
        </span>
      </div>
    </ng-container>
    <a appClick [routerLink]="root.prodotto(product)" class="product-image__body"
      [ngClass]="isInOrdiniEffettuati ? '': 'customImage'">
      <div class="product-image__img"
        style="background-position: center; background-size: contain; background-repeat: no-repeat; width: 100%; height: 100%;"
        [ngStyle]="{ 'background-image': 'url(' + product.data.immagineProdotto + ')'}">
      </div>
    </a>
  </div>
  <div class="product-card__info">
    <div class="product-card__name" [ngClass]="isInOrdiniEffettuati ? 'text-center mb-1' : '' ">
      <a appClick [routerLink]="root.prodotto(product)" style="text-transform: capitalize;" class="fw-bold">{{
        product.data.NomeProdotto }}</a><br>
        <span class="text-muted" *ngIf="isInOrdiniEffettuati">Quantita: {{elementoCarrello.data.quantita}}</span>
    </div>
    <div class="customSubTitle text-truncate">
      <span
        matTooltip="{{product.data.categoria ? recuperaCategoria(product.data.categoria) : ''}} {{product.data.categoriaFiglio ? '- ' + recuperaCategoria(product.data.categoriaFiglio) : ''}}">
        {{product.data.categoria ? recuperaCategoria(product.data.categoria) : ''}}
        {{product.data.categoriaFiglio ? '- ' + recuperaCategoria(product.data.categoriaFiglio) : ''}}
      </span>
    </div>
    <ng-container *ngIf="!isInOrdiniEffettuati">
      <div class="product-card__rating mt-2">
        <app-rating [value]="product.data.stelle"></app-rating>
        <div class="product-card__rating-legend">{{ product.data.reviews }}
          {{product.data.reviews == 1 ? 'Recensione' : 'Recensioni'}}</div>
      </div>
      <div class="product__meta mt-0 pt-1 border-0">
        <li class="product__meta-availability">Disponibilità:
          <span
            [ngClass]="product.data.disponibilitaReale == 0 ? 'text-danger' : 'text-success'">{{product.data.disponibilitaReale}}</span>
        </li>
      </div>
    </ng-container>
  </div>

  <div class="product-card__actions" [ngClass]="isInOrdiniEffettuati ? 'text-center mb-3' : '' " >
    <div class="product-card__prices">
      <ng-container *ngIf="product.data.inOfferta == true; else noOfferta">
        <span class="mr-2"><del>{{ product.data.prezzoPrecedente |currencyFormat }}</del></span>
        <span class="product-card__new-price">{{ product.data.prezzo |currencyFormat }}</span>
      </ng-container>
      <ng-template #noOfferta>
        <span class="product-card__new-price" [ngClass]="isInOrdiniEffettuati ? 'text-primary' : '' " >{{ product.data.prezzo |currencyFormat }}</span>
      </ng-template>
    </div>
    <div class="product-card__buttons" *ngIf="!isInOrdiniEffettuati">
      <div class="col-12 pl-0 pr-0 mb-2">
        <button class="btn btn-primary product-card__addtocart w-100" style="color: #fff;" type="button" appClick
          (click)="addToCart()" [disabled]="product.data.disponibilitaReale == 0"
          [ngClass]="{'btn-loading': addingToCart}">Aggiungi al
          Carrello</button>
      </div>
      <div class="col-12 d-flex pl-0 pr-0 ml-0">
      </div>
    </div>
  </div>
</div>
