<div class="address-card__body">
  <div class="address-card__badge address-card__badge--muted">Indirizzo di
    Fatturazione
  </div>
  <span class="address-card__row-title mb-2 mt-3">Ragione Sociale </span>
  <div class="address-card__name">{{ ordineDettaglio?.fatturazione?.RagioneSociale }}
  </div>
  <div class="address-card__row">
    <span class="address-card__row-title mb-2 mt-3">Sede Legale </span>
    <div class="address-card__row-content">{{ ordineDettaglio?.fatturazione?.SedeLegale }}</div>
  </div>

  <div class="address-card__row">
    <span class="address-card__row-title mb-2 mt-3">Indirizzo </span>
    <div class="address-card__row-content">
      {{ ordineDettaglio?.fatturazione?.indirizzo }} {{ordineDettaglio?.fatturazione?.numeroCivico}}<br>
      {{ ordineDettaglio?.fatturazione?.Citta }}, {{ ordineDettaglio?.fatturazione?.CodicePostale }}

    </div>
  </div>
  <div class="address-card__row">
    <div class="address-card__row-title">Partita IVA</div>
    <div class="address-card__row-content">{{ ordineDettaglio?.fatturazione?.PartitaIva }}</div>
  </div>
  <div class="address-card__row">
    <div class="address-card__row-title">Codice Fiscale</div>
    <div class="address-card__row-content text-uppercase">{{ ordineDettaglio?.fatturazione?.CodiceFiscale }}</div>
  </div>
  <div class="address-card__row">
    <div class="address-card__row-title">Numero Telefonico</div>
    <div class="address-card__row-content">{{ ordineDettaglio?.fatturazione?.Telefono }}</div>
  </div>
  <div class="address-card__row">
    <div class="address-card__row-title">Codice SDI</div>
    <div class="address-card__row-content">{{ ordineDettaglio?.fatturazione?.CodiceSDI }}</div>
  </div>
  <div class="address-card__row">
    <div class="address-card__row-title">Indirizzo PEC</div>
    <div class="address-card__row-content">{{ ordineDettaglio?.fatturazione?.PEC }}</div>
  </div>
</div>
