import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { filter, tap } from 'rxjs/operators';
import { pageService } from 'src/app/modules/account/pages/pageService';
import { PageCategoryInternalService } from 'src/app/modules/shop/pages/page-category/page-categoty-internal.service';
import { UltimaCategoriaModels } from 'src/app/shared/models/UltimaCategoriaModels';
import { ISubMenu, SUB_MENU_TOKEN } from './sub-menu.interface';

@Component({
  selector: 'app-sub-menu',
  templateUrl: './sub-menu.component.html',
  styleUrls: ['./sub-menu.component.scss'],
  providers: [pageService]
})
export class SubMenuComponent implements OnInit {

  @Output() itemClick: EventEmitter<any> = new EventEmitter<any>();

  @Input() subMenu;

  public listaSubMenu: UltimaCategoriaModels[];

  constructor(
    public pageservice: pageService,
    private router: Router,
    public pageCategoryService: PageCategoryInternalService,
    @Inject(SUB_MENU_TOKEN) public ISubMenu: ISubMenu
  ) { }

  ngOnInit(): void {
    if (this.subMenu.sottoCategoria == 'true') {
        this.pageservice.getUltimaCategoria(this.subMenu.slugCategoria, this.subMenu.catGenitore).pipe(
          filter((v) => !!v),
          tap((value) => {
            this.listaSubMenu = value;
            this.ISubMenu.listaSubMenu = value;
          })
        ).subscribe();
      }
  }

  naviga(value) {
    this.itemClick.emit(value);
    this.router.navigate(['/shop/catalog/' + value.data.catPadre + '/' + value.data.catFiglio + '/' + value.data.slugCategoria])
  }

}
