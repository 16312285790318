<app-alert type="danger" size="lg" *ngIf="showMessage">
  <b>Attenzione!</b><br>
  <span>Questo prodotto è stato inserito in un ordine ed è in attesa di un esito.</span><br>
</app-alert>

<ng-container *ngIf="showLoader">
  <div class="dashboard">
    <app-loader style="width: 100%; height: 300px;"></app-loader>
  </div>
</ng-container>
<ng-container *ngIf="!showLoader">
  <form [formGroup]="modificaProdottoForm">
    <div class="row">
      <input hidden (change)="caricaImmagine($event)" #fileInput type="file" id="file">
      <div class="col-lg-6 col-12" style="height: 500px; cursor: pointer;" (click)="fileInput.click()">
        <a class="addresses-list__item addresses-list__item--new"
          style="width: 100%; max-width:100%!important; height:100%; background-position:center; background-repeat: no-repeat"
          [ngStyle]="{ 'background-image': 'url(' + immagineProdotto.value + ')'}">
          <div class="addresses-list__plus" *ngIf="!this.product.data.immagineProdotto"></div>
        </a>
      </div>
      <div class="col-lg-6 col-12">
        <div class="form-row">
          <mat-form-field class="example-full-width w-100">
            <mat-label>Nome Prodotto</mat-label>
            <textarea matInput formControlName="NomeProdotto" #Nome autocomplete="off"></textarea>
            <button mat-button *ngIf="Nome.value" matSuffix mat-icon-button aria-label="Clear" (click)="Nome.value=''">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
        </div>
        <div class="form-row">
          <mat-form-field class="example-full-width w-100">
            <mat-label>Prezzo</mat-label>
            <input matInput formControlName="prezzo" #prezzo autocomplete="off" type="number" [readonly]="inOfferta.value">
            <button mat-button *ngIf="prezzo.value && !inOfferta.value" matSuffix mat-icon-button aria-label="Clear"
              (click)="prezzo.value=''">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
        </div>
        <div class="form-row">
          <mat-form-field class="example-full-width w-100">
            <mat-label>Disponibilità</mat-label>
            <input matInput formControlName="disponibilita" #disponibilita autocomplete="off" type="number">
            <button mat-button *ngIf="disponibilita.value" matSuffix mat-icon-button aria-label="Clear"
              (click)="disponibilita.value=''">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
        </div>
        <div class="form-row">
          <mat-form-field class="example-full-width w-100">
            <mat-label>Immagine Prodotto</mat-label>
            <textarea matInput formControlName="immagineProdotto" #immagineProdotto autocomplete="off" rows="6" (click)="fileInput.click()"></textarea>
            <button mat-button *ngIf="immagineProdotto.value" matSuffix mat-icon-button aria-label="Clear"
            (click)="modificaProdottoForm.get('immagineProdotto').setValue('')">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
        </div>
        <div class="form-row">
          <!-- <mat-form-field class="example-full-width w-100">
            <mat-label>Descrizione</mat-label>
            <textarea matInput formControlName="descrizione" #descrizione autocomplete="off"></textarea>
            <button mat-button *ngIf="descrizione.value" matSuffix mat-icon-button aria-label="Clear"
              (click)="descrizione.value=''">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field> -->
          <div class="form-row" style="height: auto">
            <angular-editor class="w-100" formControlName="descrizione" [config]="editorConfig"></angular-editor>
          </div>
        </div>
        <div class="form-row mt-3" style="height: 65px;">
          <section class="example-section w-100 d-flex align-items-center">
            <mat-checkbox class="w-50" formControlName="inOfferta">In Offerta</mat-checkbox>
            <mat-form-field class="example-full-width w-50" *ngIf="visibilityPercentuale">
              <mat-label>Percentuale sconto</mat-label>
              <input matInput formControlName="scontoPercentuale" #scontoPercentuale autocomplete="off" type="number">
              <button mat-button *ngIf="scontoPercentuale.value" matSuffix mat-icon-button aria-label="Clear"
                (click)="scontoPercentuale.value=''">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </section>
        </div>
        <div class="form-row">
          <mat-form-field class="w-100">
            <mat-label>Seleziona la categoria principale</mat-label>
            <mat-select formControlName="categoria">
              <mat-option *ngFor="let categoria of listaCategoriePrincipali" [value]="categoria.data.slugCategoria">
                {{categoria.data.nomeCategoria}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <ng-container *ngIf="!hiddenFiglio">
            <mat-form-field class="w-100">
              <mat-label>Seleziona la categoria</mat-label>
              <mat-select formControlName="categoriaFiglio"
                (click)="ricaricaListaFiglio(modificaProdottoForm.get('categoria').value)">
                <ng-container>
                  <mat-option *ngFor="let item of listaCategorieFiglio" [value]="item.data.slugCategoria">
                    {{item.data.nomeCategoria}}
                  </mat-option>
                </ng-container>
              </mat-select>
            </mat-form-field>
          </ng-container>

          <ng-container>
            <mat-form-field class="w-100" *ngIf="!hiddenUltimaCat">
              <mat-label>Seleziona la categoria</mat-label>
              <mat-select formControlName="ultimaCategoria" [disabled]="!disabilitaUltimaCat"
                (click)="ricaricaListaUltimaCategoria(modificaProdottoForm.get('categoriaFiglio').value, modificaProdottoForm.get('categoria').value )">
                <ng-container>
                  <mat-option *ngFor="let item of listaDatiUltimaCategoria" [value]="item.data.slugCategoria">
                    {{item.data.nomeCategoria}}
                  </mat-option>
                </ng-container>
              </mat-select>
            </mat-form-field>
          </ng-container>
        </div>
        <div class="form-row">
          <button [disabled]="showMessage || modificaProdottoForm.invalid"
            class="btn btn-sm btn-primary text-white col mr-2" color="primary" type="submit"
            (click)="salvaModifiche(modificaProdottoForm.value)" [mat-dialog-close]="modificaProdottoForm.value">Salva
            Modifiche</button>
        </div>
      </div>
    </div>
  </form>
</ng-container>
