import { NgModule } from '@angular/core';

// modules (angular)
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

// modules
import { SharedModule } from '../../shared/shared.module';

// components
import { AccountMenuComponent } from './components/account-menu/account-menu.component';
import { DropcartComponent } from './components/dropcart/dropcart.component';
import { HeaderComponent } from './header.component';
import { IndicatorComponent } from './components/indicator/indicator.component';
import { LinksComponent } from './components/links/links.component';
import { MegamenuComponent } from './components/megamenu/megamenu.component';
import { MenuComponent } from './components/menu/menu.component';
import { NavComponent } from './components/nav/nav.component';
import { DemoMaterialModule } from 'src/app/material-module';
import { ReactiveFormsModule } from '@angular/forms';
import { SubMenuComponent } from './components/megamenu/sub-menu/sub-menu.component';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { SearchProductComponent } from './components/search-product/search-product.component';

@NgModule({
    declarations: [
        // components
        AccountMenuComponent,
        DropcartComponent,
        HeaderComponent,
        IndicatorComponent,
        LinksComponent,
        MegamenuComponent,
        MenuComponent,
        NavComponent,
        SubMenuComponent,
        NotificationsComponent,
        SearchProductComponent,
    ],
    imports: [
        // modules (angular)
        CommonModule,
        RouterModule,
        // modules
        SharedModule,
        DemoMaterialModule,
        ReactiveFormsModule
    ],
    exports: [
        // components
        HeaderComponent
    ]
})
export class HeaderModule { }
