<form [formGroup]="accedi" (ngSubmit)="SignIn(Email.value, Password.value)">
  <div class="form-group">
    <mat-form-field class="example-full-width w-100">
      <mat-label>Email</mat-label>
      <input matInput formControlName="Email" #Email autocomplete="off">
    </mat-form-field>
    <mat-form-field appearance="fill" class="example-full-width w-100">
      <mat-label>Password</mat-label>
      <input matInput [type]="hide1 ? 'password' : 'text'" formControlName="Password" #Password autocomplete="off">
      <button mat-icon-button matSuffix (click)="hide1 = !hide1" [attr.aria-label]="'Hide password'"
        [attr.aria-pressed]="hide1" type="button">
        <mat-icon>{{hide1 ? 'visibility_off' : 'visibility'}}</mat-icon>
      </button>
    </mat-form-field>
  </div>
  <div class="form-group">
    <div class="form-check pl-0">
      <span class="fw-bold" style="cursor: pointer;" routerLink="/account/password-dimenticata">Password
        dimenticata?</span>
    </div>
  </div>
  <div class="d-flex">
    <button class="submit-button btn btn-sm btn-primary text-white pl-4 pr-4 pt-1 pb-1 col-6" color="primary" type="submit"
      [disabled]="!accedi.valid">Accedi</button>
    <button class="submit-button btn btn-sm btn-primary text-white ml-2 pl-4 pr-4 pt-1 pb-1 col-6" color="primary"
      type="submit" (click)="goToRegistrazione()">Registrati</button>
  </div>
</form>
<hr>
<span class="">Oppure accedi con: </span>
<div class="form-row mt-3">
  <button class="btn pl-1 col-6 pr-1 d-flex align-items-center mr-3" (click)="accediConGoogle()">
    <span class="icon pl-2 ml-1"></span>
    <span class="buttonText w-100 text-center">Google</span>
  </button>
</div>
