<!-- .megamenu -->
<div class="megamenu">
  <div class="megamenu__body">
    <div class="row" style="min-width: 500px;">
      <ng-container *ngIf="menu.data.numeroColonne==2">
        <!-- <div class="megamenu__item megamenu__item--with-submenu w-100 mb-3 fw-bold">
          <a style="cursor: pointer;" (click)="naviga(menu.data.slugCategoria, menu)">
            {{menu.data.nomeCategoria}}
          </a>
        </div> -->
        <div class="col-6">
          <ul class="megamenu__links megamenu__links--level--0">
            <li class="megamenu__item megamenu__item--with-submenu" *ngFor="let item of colonnaSinistra">
              <a style="cursor: pointer;" class="fw-bold" (click)="navigaFiglio(menu.data.slugCategoria, item.data.slugCategoria, item)">
                {{item.data.nomeCategoria}}
              </a>
              <div class="megamenu__links megamenu__links--level--1 pt-1 pl-2" style="border-top: 1px solid #e5e5e5;">
                <app-sub-menu [subMenu]="item.data" (itemClick)="chiudi()"></app-sub-menu>
              </div>
            </li>
          </ul>
        </div>
        <div class="col-6">
          <ul class="megamenu__links megamenu__links--level--0">
            <li class="megamenu__item megamenu__item--with-submenu" *ngFor="let item of colonnaDestra">
              <a style="cursor: pointer;" class="fw-bold" (click)="navigaFiglio(menu.data.slugCategoria, item.data.slugCategoria, item)">
                {{item.data.nomeCategoria}}
              </a>
              <div class="megamenu__links megamenu__links--level--1 pt-1 pl-2" style="border-top: 1px solid #e5e5e5;">
                <app-sub-menu [subMenu]="item.data" (itemClick)="chiudi()"></app-sub-menu>
              </div>
            </li>
          </ul>
        </div>
      </ng-container>
      <ng-container *ngIf="menu.data.numeroColonne==1">
        <div class="megamenu__item megamenu__item--with-submenu w-75 mb-2">
          <div style="border-bottom: 1px solid #e5e5e5;">
            <a style="cursor: pointer;" class="fw-bold" (click)="naviga(menu.data.slugCategoria, menu)">
              {{menu.data.nomeCategoria}}
            </a>
          </div>
        </div>
        <div class="col-8">
          <ul class="megamenu__links megamenu__links--level--1">
            <li class="megamenu__item megamenu__item--with-submenu" *ngFor="let item of vociMenu">
              <ng-container *ngIf="item.data.sottoCategoria == 'false'">
                <a class="megamenu__item" style="cursor: pointer;"
                  (click)="navigaFiglio(menu.data.slugCategoria, item.data.slugCategoria, item)">
                  {{item.data.nomeCategoria}}
                </a>
              </ng-container>
              <ng-container *ngIf="item.data.sottoCategoria == 'true'">
                <a style="cursor: pointer;" (click)="navigaFiglio(menu.data.slugCategoria, item.data.slugCategoria, item)">
                  {{item.data.nomeCategoria}}
                </a>
                <div class="megamenu__links megamenu__links--level--1 pt-1 pl-2" style="border-top: 1px solid #e5e5e5;">
                  <app-sub-menu [subMenu]="item.data" (itemClick)="chiudi()"></app-sub-menu>
                </div>
              </ng-container>
            </li>
          </ul>
        </div>
      </ng-container>
    </div>
  </div>
</div>
<!-- .megamenu / end -->
