<ng-container *ngIf="showLoader">
  <div class="dashboard">
    <app-loader style="width: 100%; height: 300px;"></app-loader>
  </div>
</ng-container>

<div class="row" *ngIf="!showLoader">
  <ng-container *ngFor="let lista of listaReviews">
    <div class="col-lg-2 col-md-3 col-4 d-flex justify-content-center align-items-center">
      <div class="profile-card__avatar">
        <span *ngIf="!lista.data.photoURL"
          class="material-icons d-flex align-items-center justify-content-center mt-3"
          style="height: 90px;font-size: 7rem;color: rgba(0,0,0,.325);"> account_circle </span>
        <div *ngIf="!!lista.data.photoURL" class="customIconReview"
          [ngStyle]="{ 'background-image': 'url(' + lista.data.photoURL + ')'}"></div>
      </div>
    </div>
    <div class="col-lg-10 col-md-9 col-8">
      <div class="fw-bold">{{lista?.data?.displayName}}</div>
      <div class="mt-1 mb-3">
        <app-rating [value]="lista?.data?.stelle"></app-rating>
      </div>
      <div class="review__text">{{lista?.data?.descrizione}}</div>
      <div class="mt-2" style="color: #999; font-size: 14px;">{{lista.data.dataCommento.toDate() | date:"dd/MM/yy"}}</div>
    </div>
    <hr class="w-100">
  </ng-container>

  <div class="col-12 text-center justify-content-center mt-3" *ngIf="!!utenteConnesso?.email else noUserLog">
    <span class="h3">Scrivi una recensione</span>
    <div class="col-12 d-flex justify-content-center mt-3">
      <form [formGroup]="reviewsForm" class="row w-lg-50 w-100 justify-content-center mt-3"
        (ngSubmit)="salvaRecensione(reviewsForm.value)" #formDirective="ngForm">
        <div class="col-lg-12 col-md-8 col-12 d-flex justify-content-center">
          <button mat-icon-button [color]="color" *ngFor="let ratingId of ratingArr;index as i" [id]="'star_'+i"
            (click)="onClick(i+1)" [matTooltip]="ratingId+1" matTooltipPosition="above" [value]="rating">
            <mat-icon>
              {{showIcon(i)}}
            </mat-icon>
          </button>
        </div>
        <div class="col-lg-7 col-md-8 col-12 mt-2 pl-0 pr-0">
          <mat-form-field class="example-full-width col-12">
            <mat-label>Il tuo commento</mat-label>
            <textarea matInput #Commento autocomplete="off" formControlName="descrizione"></textarea>
            <button mat-button *ngIf="Commento.value" matSuffix mat-icon-button aria-label="Clear"
              (click)="Commento.value=''">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
        </div>
        <div class="col-lg-7 col-md-8 col-12 mt-2">
          <button class="btn btn-primary btn-sm text-white" [disabled]="reviewsForm.invalid" type="submit">Pubblica la
            tua recensione</button>
        </div>
      </form>
    </div>
  </div>
  <ng-template #noUserLog>
    <app-alert type="primary" size="lg" class="w-100">
      Per inserire una recensione devi effettuare l'accesso.
    </app-alert>
  </ng-template>
</div>
