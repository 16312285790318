import { Injectable } from "@angular/core";
import { Subject } from 'rxjs';
import { AdministrationInterface } from "src/app/modules/account/pages/administration/administration.interface";
import { IOrderAccepted } from "src/app/modules/account/pages/administration/orders-accepted/orders-accepted.interface";
import { IOrderRejected } from "src/app/modules/account/pages/administration/orders-rejected/order-rejected.interface";
import { EditCategoryInterface } from "src/app/modules/account/pages/edit-category/edit-category.interface";
import { PageDashboardInterface } from "src/app/modules/account/pages/page-dashboard/page-dashboard.interface";
import { WarehouseInterface } from "src/app/modules/account/pages/warehouse/warehouse.interface";
import { WidgetFilterInterface } from 'src/app/modules/widgets/widget-filters/widget.interface';
import { LinkSubmenuInterface } from "src/app/shared/components/link-submenu/link-submenu.interface";
import { CommonInterface } from 'src/app/shared/interfaces/common.interface';
import { ProdottoModels } from "src/app/shared/models/ProdottoModels";
import { CategoriaFiglioModels } from "src/app/shared/models/CategoriaFiglioModels";
import { UtenteModels } from "src/app/shared/models/UtenteModels";
import { NuovoOrdineModels } from "src/app/shared/models/NuovoOrdineModels";
import { CategoriaGenitoreModels } from "src/app/shared/models/CategoriaGenitoreModels";
import { UltimaCategoriaModels } from "src/app/shared/models/UltimaCategoriaModels";
import { ISubMenu } from "src/app/modules/header/components/megamenu/sub-menu/sub-menu.interface";
import { IMegaMenu } from "src/app/modules/header/components/megamenu/megamenu.interface";
import { IMessage } from "src/app/modules/account/pages/message/message.interface";
import { MessaggiModels } from "src/app/shared/models/MessaggiModels";
import { IEditProduct } from "src/app/shared/components/edit-product/edit-product.interface";
import { IPageSearch } from "src/app/modules/site/pages/page-search/page-search.interface";

@Injectable()
export class RootInternalService implements CommonInterface, PageDashboardInterface, WarehouseInterface, LinkSubmenuInterface,
EditCategoryInterface, AdministrationInterface, IOrderRejected, IOrderAccepted, ISubMenu, IMegaMenu, IMessage, IEditProduct, IPageSearch{
  listaMessaggi: MessaggiModels[];
  listaCategoriaFiglio: CategoriaFiglioModels[];
  listaCategorieFiglio: CategoriaFiglioModels[];
  vociMenu: CategoriaFiglioModels[];
  colonnaSinistra: CategoriaFiglioModels[];
  colonnaDestra: CategoriaFiglioModels[];
  listaSubMenu: UltimaCategoriaModels[];
  userIsAdmin$: Subject<boolean>;
  listaProdotti: ProdottoModels[];
  clearSottoCategoria$: Subject<any>;
  salvaDato$: Subject<any>;
  ricaricaLista$: Subject<any>;
  listaOrdiniRicevutiCompleta: NuovoOrdineModels[];
  listaOrdiniInAccettazione: NuovoOrdineModels[];
  listaUtentiRegistrati: UtenteModels[];
  listaOrdiniRifiutati: NuovoOrdineModels[];
  listaOrdiniConfermati: NuovoOrdineModels[];
  listaCategoriePrincipali: CategoriaGenitoreModels[];
  listaUltimaCategoria: UltimaCategoriaModels[];
  listaProdottiCompleta: ProdottoModels[];
}
