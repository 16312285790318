import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, Inject, AfterViewInit } from '@angular/core';
import { CartService } from '../../services/cart.service';
import { RootService } from '../../services/root.service';
import { CurrencyService } from '../../services/currency.service';
import { takeUntil, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { isAdmin } from 'src/data/isAdmin';
import { ProdottoModels } from '../../models/ProdottoModels';
import { ActivatedRoute, Router } from '@angular/router';
import { CarrelloFire } from '../../models/CarrelloFire';

@Component({
  selector: 'app-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductCardComponent implements OnInit, OnDestroy, OnChanges {
  private destroy$: Subject<void> = new Subject();

  @Input() product: ProdottoModels;
  @Input() layout: 'grid-sm' | 'grid-nl' | 'grid-lg' | 'list' | 'horizontal' | null = null;
  @Input() utente;
  @Input('isInOrdiniEffettuati') isInOrdiniEffettuati: boolean;
  @Input('elementoCarrello') elementoCarrello: CarrelloFire;

  public isAdmin = isAdmin;

  addingToCart = false;
  addingToWishlist = false;
  addingToCompare = false;
  showingQuickview = false;

  messageDelete: string = 'Cancellazione Avvenuta.';
  actionButtonLabel: string = '';
  action: boolean = true;
  setAutoHide: boolean = true;
  autoHide: number = 2000;
  horizontalPosition: MatSnackBarHorizontalPosition = 'right';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';

  public disabilitaBtn: boolean = false;

  constructor(
    private cd: ChangeDetectorRef,
    public root: RootService,
    public cart: CartService,
    public currency: CurrencyService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public fb: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.currency.changes$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.cd.markForCheck();
    });

    this.cart.items$.pipe(
      tap((v) => {
        if (v.length == 0) {
          this.disabilitaBtn = false
        }
        else {
          let productFilter = this.cart.items.filter(x => x.cartItem.product.id == this.product.id);
          productFilter.forEach(res => {
            if (res.quantity >= Number(this.product.data.disponibilitaReale)) {
              this.disabilitaBtn = true;
            }
          })
        }
      })
    ).subscribe();

  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
  }


  addToCart(): void {
    if (this.addingToCart) {
      return;
    }
    this.addingToCart = true;
    this.cart.add(this.product, 1).subscribe({
      complete: () => {
        this.addingToCart = false;
        this.cd.markForCheck();
      }
    });
  }

  recuperaCategoria(value) {
    let primaOperazione = value.replace(/_/g, ' ') || null;
    let label = primaOperazione.charAt(0).toUpperCase() + primaOperazione.substring(1).toLowerCase() || null;
    return label
  }

  cancellaProdotto(prodotto) {
    this.cart.cancellaProdottoByID(prodotto.id).then(
      res => {
        let config = new MatSnackBarConfig();
        config.verticalPosition = this.verticalPosition;
        config.horizontalPosition = this.horizontalPosition;
        config.duration = this.setAutoHide ? this.autoHide : 0;
        this.snackBar.open(this.messageDelete, this.action ? this.actionButtonLabel : undefined, config);
      }
    )
  }

  modificaProdotto(prodotto) {
    const dialogRef = this.dialog.open(DialogContentExampleDialog, {
      data: prodotto
    });

  }

  addToWishlist(): void {
    if (this.addingToWishlist) {
      return;
    }

    this.addingToWishlist = true;
  }
}

@Component({
  selector: 'dialog-content-example-dialog',
  templateUrl: 'modifica-prodotto.html',
})
export class DialogContentExampleDialog implements OnInit {

  catGenitore;
  categoriaCapsula;

  utente;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DialogContentExampleDialog>,
  ) {
  }

  ngOnInit(): void {

  }

  chiudiModale() {
    this.dialogRef.close();
  }

}
