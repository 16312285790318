import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Prodotto } from '../interfaces/product';
import { CartItem } from '../interfaces/cart-item';
import { BehaviorSubject, iif, Observable, of, Subject, timer, zip } from 'rxjs';
import { concatMap, filter, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';
import { AngularFirestore } from '@angular/fire/firestore';
import { AuthService } from './auth/auth.service';
import { CarrelloFire, DatiCarrelloFire } from '../models/CarrelloFire';
import { DatiProdotto, ProdottoModels } from '../models/ProdottoModels';
import { CarrelloItems, CarrelloModels } from '../models/CarrelloModels';
import { DatiUtente } from '../models/UtenteModels';
import { DatiNotifica, NotificaModels } from '../models/NotificaModels';
import { DatiNuovoOrdine } from '../models/NuovoOrdineModels';

interface CartTotal {
  title: string;
  price: number;
}

interface CartData {
  items: CarrelloModels[];
  quantity: number;
  subtotal: number;
  totals: CartTotal[];
  total: number;
}

@Injectable({
  providedIn: 'root'
})
export class CartService {

  private data: CartData = {
    items: [],
    quantity: 0,
    subtotal: 0,
    totals: [],
    total: 0
  };

  public ricarica = new Subject()

  private itemsSubject$: BehaviorSubject<CarrelloModels[]> = new BehaviorSubject(this.data.items);
  private quantitySubject$: BehaviorSubject<number> = new BehaviorSubject(this.data.quantity);
  private subtotalSubject$: BehaviorSubject<number> = new BehaviorSubject(this.data.subtotal);
  private totalSubject$: BehaviorSubject<number> = new BehaviorSubject(this.data.total);
  private onAddingSubject$: Subject<string> = new Subject();
  private onAddingSubjectErrors$: Subject<ProdottoModels | DatiCarrelloFire> = new Subject();
  public utente: DatiUtente;

  get items(): CarrelloModels[] {
    return this.data.items;
  }

  get quantity(): number {
    return this.data.quantity;
  }

  readonly items$: Observable<any> = this.itemsSubject$.asObservable();
  readonly quantity$: Observable<number> = this.quantitySubject$.asObservable();
  readonly subtotal$: Observable<number> = this.subtotalSubject$.asObservable();
  readonly total$: Observable<number> = this.totalSubject$.asObservable();

  readonly onAdding$: Observable<any> = this.onAddingSubject$.asObservable();
  readonly onAddingErrors$: Observable<any> = this.onAddingSubjectErrors$.asObservable();

  public carrelloFire$: Observable<CarrelloFire[]> = new Subject();
  public carrelloFire: CarrelloFire[];

  public prepareCarrello: Subject<CarrelloFire[]> = new Subject();
  public entra: boolean = false;

  constructor(
    @Inject(PLATFORM_ID)
    private platformId: any,
    public db: AngularFirestore,
    public authService: AuthService,

  ) {
    if (isPlatformBrowser(this.platformId)) {
      this.load();
      this.calc();
    }

    this.authService.user$.pipe(
      tap((v) => this.utente = v),
      filter((v) => !!this.utente),
      tap((v: DatiUtente) => {
        this.recuperaCarrelloFire(v).pipe(
          filter((v) => !!v),
          tap((v) => {
            this.carrelloFire = v;
            this.prepareCarrello.next(v);
          }),
        ).subscribe(res => {
        });
      }),
    ).subscribe();

    this.prepareCarrello.pipe(
      tap((v) => {
        if (this.items.length > 0) {
          this.items.forEach(res => {
            if (this.isSelezionatoLocal(res) == true) {
              let cerco = v.find(f => f.data.idProdotto == res.cartItem.product.id);
              let obj: DatiCarrelloFire = {
                NomeProdotto: res.cartItem.product.data.NomeProdotto,
                disponibilitaReale: res.cartItem.product.data.disponibilitaReale,
                emailUtente: this.utente.email,
                idProdotto: res.cartItem.product.id,
                idUtente: this.utente.uid,
                immagineProdotto: res.cartItem.product.data.immagineProdotto,
                prezzo: res.cartItem.product.data.prezzo,
                quantita: res.quantity + cerco.data.quantita,
                slugProdotto: res.cartItem.product.data.slugProdotto,
                prodotto: res.cartItem.product.data,
                visibility: cerco.data.visibility,
                quantitaDesiderata: cerco.data.quantitaDesiderata,
                idNotifica: cerco.data.idNotifica
              }
              this.editProdottoSuCarrelloFire(cerco.id, obj).then();
            } else {
              let obj: DatiCarrelloFire = {
                NomeProdotto: res.cartItem.product.data.NomeProdotto,
                disponibilitaReale: res.cartItem.product.data.disponibilitaReale,
                emailUtente: this.utente.email,
                idProdotto: res.cartItem.product.id,
                idUtente: this.utente.uid,
                immagineProdotto: res.cartItem.product.data.immagineProdotto,
                prezzo: res.cartItem.product.data.prezzo,
                quantita: res.quantity,
                slugProdotto: res.cartItem.product.data.slugProdotto,
                prodotto: res.cartItem.product.data,
                visibility: true,
                quantitaDesiderata: res.quantity,
                idNotifica: null
              }
              this.salvaSuCarrelloFire(obj).then();
            }
          });
          this.data.items = []
          localStorage.clear()
        }
      })
    ).subscribe();

    this.carrelloFire$.pipe(
      filter((v) => !!v),
      tap((v) => this.carrelloFire = v)
    ).subscribe();
  }

  caricaCarrelloFire(id, value) {
    this.editProdottoSuCarrelloFire(id, value).then();
  }

  isSelezionatoLocal = ((selezionato: CarrelloModels): boolean => { return this.carrelloFire.some(i => i.data.idProdotto == selezionato.cartItem.product.id && i.data.slugProdotto == selezionato.cartItem.product.data.slugProdotto) });
  isSelezionatoFirebase = ((selezionato: ProdottoModels): boolean => { return this.carrelloFire.some(i => i.data.idProdotto == selezionato.id) });


  recuperaCarrelloFire(v: DatiUtente) {
    return this.db.collection('carrello', res => res.where('idUtente', '==', v.uid).where('visibility', '==', true)).snapshotChanges().pipe(
      map(changes => {
        return changes.map(doc => {
          return {
            id: doc.payload.doc.id,
            data: doc.payload.doc.data()
          } as CarrelloFire
        })
      })
    )
  }

  recuperaCarrelloFireCheck(v: DatiUtente, idProdotto) {
    return this.db.collection('carrello', res => res.where('emailUtente', '==', v.email).where('idProdotto', '==', idProdotto)).get().pipe(
      map(changes => {
        return changes.docs.map(doc => {
          return {
            id: doc.id,
            data: doc.data()
          } as CarrelloFire
        })
      })
    )
  }


  recuperaCarrelloFireCustom(v: DatiUtente) {
    let contenitore;
    return this.db.collection('carrello', res => res.where('emailUtente', '==', v.email)).snapshotChanges().pipe(
      concatMap(v => {
        return v.map((r) => {
          let id: any = r.payload.doc.data();
          this.db.collection('prodotti-inseriti').doc(id.idProdotto).get().pipe(
            map(changes => {
              return changes.data() as DatiProdotto
            })
          ).subscribe()
        })
      }),
    )
  }

  checkProdottoSuCarrello(v) {
    return this.db.collection('carrello', res => res.where('idProdotto', '==', v)).get().pipe(
      map(changes => {
        return changes.docs.map(r => {
          return {
            data: r.data(),
            id: r.id
          } as CarrelloFire
        })
      })
    )
  }


  cancellaProdottoByID(id) {
    return this.db.collection('prodotti-inseriti').doc(id).delete();
  }

  modificaProdottoByID(id, value) {
    return this.db.collection('prodotti-inseriti').doc(id).set(value);
  }

  salvaOrdine(value: DatiNuovoOrdine) {
    let dataOrdine = new Date();
    return this.db.collection('nuovo-ordine').add({
      elementi: value.elementi,
      importo: value.importo,
      spedizione: value.spedizione,
      modPagamento: value.modPagamento,
      idUtente: value.idUtente,
      emailUtente: value.emailUtente,
      dataOrdine: dataOrdine,
      statoOrdine: value.statoOrdine,
      flagPersonaFisica: value.flagPersonaFisica,
      flagPartitaIva: value.flagPartitaIva,
      spedito: value.spedito,
      dettagliExtra: value.dettagliExtra,
      fatturazione: value.fatturazione,
      costoSpedizione: value.costoSpedizione,
      chiudiOrdine: value.chiudiOrdine,
      codiceSpedizione: value.codiceSpedizione,
      corriereAffidato: value.corriereAffidato,
    } as DatiNuovoOrdine
    );
  }

  salvaSuCarrelloFire(value: DatiCarrelloFire) {
    return this.db.collection('carrello').add({
      NomeProdotto: value.NomeProdotto,
      disponibilitaReale: value.disponibilitaReale,
      emailUtente: value.emailUtente,
      idProdotto: value.idProdotto,
      idUtente: value.idUtente,
      immagineProdotto: value.immagineProdotto,
      prezzo: value.prezzo,
      quantita: value.quantita,
      slugProdotto: value.slugProdotto,
      prodotto: value.prodotto,
      visibility: value.visibility,
      quantitaDesiderata: value.quantitaDesiderata,
      idNotifica: null
    } as DatiCarrelloFire);
  }

  editProdottoSuCarrelloFire(id, value) {
    return this.db.collection('carrello').doc(id).set(Object.assign({}, value));
  }

  inviaNotifica(value: DatiNotifica) {
    return this.db.collection('notifiche').add({
      dataNotifica: value.dataNotifica,
      idCarrello: value.idCarrello,
      idProdotto: value.idProdotto,
      idUtente: value.idUtente,
      nomeProdotto: value.nomeProdotto,
      quantita: value.quantita,
      scelta: value.scelta,
      immagineProdotto: value.immagineProdotto,
      notificaInviataAlCliente: false,
      isNotificaSistema: true,
      idOrdine: value.idOrdine,
      isVisualizzata: value.isVisualizzata
    } as DatiNotifica)
  }

  inviaNotificaOrdineSuccess(value: DatiNotifica) {
    return this.db.collection('notifiche').add({
      dataNotifica: value.dataNotifica,
      idCarrello: value.idCarrello,
      idProdotto: value.idProdotto,
      idUtente: value.idUtente,
      nomeProdotto: value.nomeProdotto,
      quantita: value.quantita,
      scelta: value.scelta,
      immagineProdotto: value.immagineProdotto,
      notificaInviataAlCliente: false,
      isNotificaSistema: true,
      idOrdine: value.idOrdine,
      isVisualizzata: value.isVisualizzata
    } as DatiNotifica)
  }

  getNotificaByIdUtente(idUtente) {
    return this.db.collection('notifiche', res => res.where('idUtente', '==', idUtente)).snapshotChanges().pipe(
      map(changes => {
        return changes.map(doc => {
          return {
            id: doc.payload.doc.id,
            data: doc.payload.doc.data()
          } as NotificaModels
        })
      })
    )
  }

  getNotificaById(idNotifica) {
    return this.db.collection('notifiche').doc(idNotifica).get().pipe(
      map(changes => {
        return changes.data() as DatiNotifica
      })
    )
  }

  getNotificaByIdUtenteNoAzione(idUtente) {
    return this.db.collection('notifiche', res => res.where('idUtente', '==', idUtente).where('isVisualizzata', '==', false)).snapshotChanges().pipe(
      map(changes => {
        return changes.map(doc => {
          return {
            id: doc.payload.doc.id,
            data: doc.payload.doc.data()
          } as NotificaModels
        })
      })
    )
  }

  add(product: ProdottoModels, quantity: number): Observable<CarrelloModels | DatiCarrelloFire[] | DatiCarrelloFire> {

    return of(null).pipe(
      mergeMap(v =>
        iif(() => this.utente != undefined,
          of(this.items).pipe(
            map((v) => {
              return {
                NomeProdotto: product.data.NomeProdotto,
                disponibilitaReale: product.data.disponibilitaReale,
                emailUtente: this.utente.email,
                idProdotto: product.id,
                idUtente: this.utente.uid,
                immagineProdotto: product.data.immagineProdotto,
                prezzo: product.data.prezzo,
                quantita: quantity,
                slugProdotto: product.data.slugProdotto,
                prodotto: product.data,
                visibility: true,
                quantitaDesiderata: quantity,
                idNotifica: null
              } as DatiCarrelloFire
            }),
            tap((v) => {
              //se il carrello fire è vuoto
              if (this.carrelloFire.length == 0) {

                this.recuperaCarrelloFireCheck(this.utente, product.id).pipe(
                  filter((c) => !!c),
                  map((c) => c.map(m => {
                    if (m.data.idNotifica !== null) {
                      this.getNotificaById(m.data.idNotifica).pipe(
                        filter((n) => !!n),
                        filter((n) => n.scelta == 3),
                        tap((n) => {
                          this.removeCarrelloFire(m.id).then()
                          this.removeNotificaFire(m.data.idNotifica).then()
                        })
                      ).subscribe();
                    }
                  }))
                ).subscribe();

                this.salvaSuCarrelloFire(v).then(res => {
                  this.onAddingSubject$.next(v.NomeProdotto);
                })


              }
              // carrello con almeno un elemento
              else {
                //controllo
                if (this.isSelezionatoFirebase(product) == true) {
                  let helper = this.carrelloFire.find(r => r.data.idProdotto == product.id);
                  if (Number(product.data.disponibilitaReale) >= helper.data.quantita) {
                    let preSomma = helper.data.quantita + quantity;
                    if (Number(product.data.disponibilitaReale) >= preSomma) {
                      let obj: DatiCarrelloFire = {
                        NomeProdotto: helper.data.NomeProdotto,
                        disponibilitaReale: helper.data.disponibilitaReale,
                        emailUtente: helper.data.emailUtente,
                        idProdotto: helper.data.idProdotto,
                        idUtente: helper.data.idUtente,
                        immagineProdotto: helper.data.immagineProdotto,
                        prezzo: helper.data.prezzo,
                        quantita: preSomma,
                        slugProdotto: helper.data.slugProdotto,
                        prodotto: product.data,
                        visibility: helper.data.visibility,
                        quantitaDesiderata: preSomma,
                        idNotifica: helper.data.idNotifica
                      }
                      //modifico il prodotto su carrello ed elimino la notifica se presente
                      this.editProdottoSuCarrelloFire(helper.id, obj).then(res => this.onAddingSubject$.next(obj.NomeProdotto));
                    } else {
                      this.onAddingSubjectErrors$.next();
                    }
                  } else {
                    this.onAddingSubjectErrors$.next();
                  }
                  // if (helper.data.idNotifica !== null) {
                  //   this.getNotificaById(helper.data.idNotifica).pipe(
                  //     filter((v) => !!v),
                  //     filter((v) => v.scelta == 3),
                  //     tap((v) => {
                  //       this.removeCarrelloFire(helper.id).then()
                  //       this.removeNotificaFire(helper.data.idNotifica).then()
                  //     })
                  //   ).subscribe();
                  // }
                } else {
                  this.salvaSuCarrelloFire(v).then(res => {
                    this.onAddingSubject$.next(v.NomeProdotto);
                  })
                }
              }
            }),
          ),
          of({ product: product, quantity: quantity }).pipe(
            map((v) => {
              let item = this.items.find(eachItem => {
                if (eachItem.cartItem.product.id !== product.id) {
                  return false;
                }
                return true;
              });
              if (item) {
                if (Number(product.data.disponibilitaReale) >= item.quantity) {
                  let preSomma = item.quantity + quantity;
                  if (Number(product.data.disponibilitaReale) >= preSomma) {
                    item.quantity = preSomma;
                    this.onAddingSubject$.next(product.data.NomeProdotto);
                  } else {
                    this.onAddingSubjectErrors$.next();
                  }
                }
              }
              else {
                let cartItems: CarrelloItems = { product: v.product, quantity: v.quantity }
                item = { cartItem: cartItems, quantity: v.quantity };
                this.data.items.push(item);
                this.onAddingSubject$.next(product.data.NomeProdotto);
              }
              this.save();
              this.calc();
              return item;
            })
          )
        )
      ),
    );
  }

  update(updates: { item: CarrelloModels, quantity: number }[]): Observable<void> {
    return of(null).pipe(map(() => {
      updates.forEach(update => {
        const item = this.items.find(eachItem => eachItem === update.item);
        if (item) {
          item.quantity = update.quantity;
        }
      });
      this.save();
      this.calc();
    }));
  }

  remove(item: CarrelloModels): Observable<void> {
    return of(null).pipe(
      map(() => {
        this.data.items = this.data.items.filter(eachItem => eachItem !== item);
        this.save();
        this.calc();
      }),
    );
  }

  removeFire(item: CarrelloFire) {
    return this.db.collection('carrello').doc(item.id).delete();
  }

  removeCarrelloFire(id: string) {
    return this.db.collection('carrello').doc(id).delete();
  }

  removeElementCartFire(idCarrello) {
    return this.db.collection('carrello').doc(idCarrello).delete();
  }

  removeNotificaFire(idNotifica) {
    return this.db.collection('notifiche').doc(idNotifica).delete();
  }

  editNotificaFire(idNotifica, value) {
    return this.db.collection('notifiche').doc(idNotifica).set(Object.assign({}, value));
  }

  removeAll(data) {
    let dato = [];
    this.data.items = [];
    this.data.quantity = 0;
    this.itemsSubject$.next(dato);
    this.quantitySubject$.next(0);
    localStorage.removeItem('cartItems');
  }

  private calc(): void {
    let quantity = 0;
    let subtotal = 0;

    this.data.items.forEach(item => {
      quantity += item.quantity;
      subtotal += item.cartItem.product.data.prezzo * item.quantity;
    });

    const totals: CartTotal[] = [];
    const total = subtotal + totals.reduce((acc, eachTotal) => acc + eachTotal.price, 0);

    this.data.quantity = quantity;
    this.data.subtotal = subtotal;
    this.data.total = total;

    this.itemsSubject$.next(this.data.items);
    this.quantitySubject$.next(this.data.quantity);
    this.subtotalSubject$.next(this.data.subtotal);
    this.totalSubject$.next(this.data.total);
  }

  private save(): void {
    localStorage.setItem('cartItems', JSON.stringify(this.data.items));
  }

  private load(): void {
    const items = localStorage.getItem('cartItems');

    if (items) {
      this.data.items = JSON.parse(items);
    }
  }

  getProdottoByID(id) {
    return this.db.collection('prodotti-inseriti').doc(id).get().pipe(
      map(changes => {
        return changes.data() as DatiProdotto
      })
    )
  }

  recuperaCarrelloById(idCarrello) {
    return this.db.collection('carrello').doc(idCarrello).get().pipe(
      map(changes => {
        return changes.data() as DatiCarrelloFire
      })
    )
  }
}
