import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { filter, tap } from 'rxjs/operators';
import { PageCategoryService } from 'src/app/modules/shop/services/page-category.service';
import { LINK_SUBMENU_TOKEN } from 'src/app/shared/components/link-submenu/link-submenu.interface';
import { CategoriaGenitoreModels } from 'src/app/shared/models/CategoriaGenitoreModels';
import { MobileLinkService } from './mobile-links.service';

@Component({
  selector: 'app-mobile-links',
  templateUrl: './mobile-links.component.html',
  styleUrls: ['./mobile-links.component.scss'],
  providers: [PageCategoryService, MobileLinkService,
    { provide: LINK_SUBMENU_TOKEN, useExisting: MobileLinkService }
  ]
})
export class MobileLinksComponent implements OnInit {
  @Input() level = 0;

  public items: CategoriaGenitoreModels[];

  @Output() itemClick: EventEmitter<any> = new EventEmitter();

  constructor(
    public PageCategoryService: PageCategoryService,
    public router: Router,
    public MobileLinkService: MobileLinkService
  ) { }

  ngOnInit(): void {
    this.PageCategoryService.getAllCategoriagenitore().pipe(
      filter((v) => !!v),
      tap((v) => {
        this.items = v
      })
    ).subscribe()

    this.MobileLinkService.clearSottoCategoria$.pipe(
    ).subscribe()
  }

  onItemClick(item: any): void {
    this.itemClick.emit(item);
    this.router.navigate(['/shop/catalog/' + item.data.slugCategoria])
  }

  chiudi() {
    this.itemClick.emit(true);
  }

  chiudiMenu(link: CategoriaGenitoreModels) {
    if (link.data.sottoCategoria == 'false') {
      this.router.navigate(['/shop/catalog/' + link.data.slugCategoria]);
      this.itemClick.emit(link)
    }
  }
}
