import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { filter, tap } from 'rxjs/operators';
import { PageCategoryService } from 'src/app/modules/shop/services/page-category.service';
import { CommonInterface, COMMON_INTERFACE_TOKEN } from '../../interfaces/common.interface';
import { CategoriaFiglioModels } from '../../models/CategoriaFiglioModels';
import { LinkSubmenuInterface, LINK_SUBMENU_TOKEN } from './link-submenu.interface';

@Component({
  selector: 'app-link-submenu',
  templateUrl: './link-submenu.component.html',
  styleUrls: ['./link-submenu.component.scss'],
  providers: [PageCategoryService]
})
export class LinkSubmenuComponent implements OnInit {

  @Input() subCategory: any;
  @Input() isMobile: boolean;
  @Input() isAdministration: boolean;
  @Output() itemClick: EventEmitter<any> = new EventEmitter();
  @Output() cancellaSlug: EventEmitter<any> = new EventEmitter();

  @Output() dato: EventEmitter<any> = new EventEmitter();
  @Output() recuperaSubCategory: EventEmitter<any> = new EventEmitter();

  public items: CategoriaFiglioModels[];

  constructor(
    public PageCategoryService: PageCategoryService,
    public router: Router,
    @Inject(LINK_SUBMENU_TOKEN) public linkSubmenuInterface: LinkSubmenuInterface,
    @Inject(COMMON_INTERFACE_TOKEN) public commonInterface: CommonInterface
  ) { }

  ngOnInit(): void {
    if (!this.commonInterface.listaCategoriaFiglio) {
      this.caricaCategoriaFiglio();
    } else {
      this.items = Object.assign([], this.commonInterface.listaCategoriaFiglio.filter(x => x.data.catGenitore == this.subCategory.data.slugCategoria));
    }

    this.linkSubmenuInterface.ricaricaLista$.pipe(
      tap(() => this.caricaCategoriaFiglio())
    ).subscribe();

  }

  caricaCategoriaFiglio() {
    this.PageCategoryService.getAllCatFiglio().pipe(
      filter((v) => !!v),
      tap((v) => {
        this.items = Object.assign([], v.filter(x => x.data.catGenitore == this.subCategory.data.slugCategoria));
        this.commonInterface.listaCategoriaFiglio = Object.assign([], v);
      })
    ).subscribe();
  }

  onItemClick(item: any): void {
    let dato: { id: number, value: any } =
    {
      id: 2,
      value: item
    };
    this.cancellaSlug.emit(true);
    if (!this.isAdministration) {
      this.router.navigate(['/shop/catalog/' + item.data.catGenitore + '/' + item.data.slugCategoria]);
      this.dato.emit(dato);
    }
    if (this.isMobile) this.itemClick.emit(item);
  }

  inviaDato(value) {
    this.dato.emit(value);
  }

  modifica(value) {
    let dato: { id: number, value: any } = { id: 1, value: value }
    this.dato.emit(dato);
  }

  cancella(value) {
    let dato: { id: number, value: any } = { id: 2, value: value }
    this.dato.emit(dato);
  }

  apriStepper() {
    let inviaDato: { id: number, value: any } = { id: 2, value: this.subCategory.data }
    this.recuperaSubCategory.emit(inviaDato)
  }

  inviaDatoSubCategory(value) {
    this.recuperaSubCategory.emit(value);
  }

}
