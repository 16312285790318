<div class="block">
    <div class="container-xxl">
        <div class="not-found">
            <div class="not-found__404">
                Oops! Errore 404
            </div>

            <div class="not-found__content">
                <h1 class="not-found__title">Pagina non trovata
                </h1>


                <p class="not-found__text">
                    Non riusciamo a trovare la pagina che stai cercando.
                </p>

                <a class="btn btn-secondary btn-sm" routerLink="">Vai alla Home</a>
            </div>
        </div>
    </div>
</div>
