<app-mobile-menu></app-mobile-menu>

<div class="site" style="overflow-x: hidden;">
    <header class="site__header d-lg-none">
        <app-mobile-header stickyMode="pullToShow"></app-mobile-header>
    </header>

    <header *ngIf="headerLayout" class="site__header d-lg-block d-none">
        <app-header [layout]="headerLayout"></app-header>
    </header>

    <div class="site__body">
        <router-outlet></router-outlet>
    </div>

    <footer class="site__footer">
        <app-footer></app-footer>
    </footer>
</div>
