<form [formGroup]="formSpedizione" class="mt-4 pl-2 pr-2">
  <div class="form-row">
    <mat-form-field class="example-full-width w-100">
      <mat-label>Ragione Sociale</mat-label>
      <input matInput formControlName="RagioneSociale" autocomplete="off">
      <button mat-button *ngIf="RagioneSociale.value" matSuffix mat-icon-button aria-label="Clear"
        (click)="RagioneSociale.reset()">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
    <mat-form-field class="example-full-width col-lg-9 pl-0 pr-0 pr-lg-2">
      <mat-label>Sede Legale</mat-label>
      <input matInput formControlName="SedeLegale" autocomplete="off">
      <button mat-button *ngIf="SedeLegale.value" matSuffix mat-icon-button aria-label="Clear"
        (click)="SedeLegale.reset()">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>

    <mat-form-field class="example-full-width col-lg-3 col-12 pl-1 pl-lg-0">
      <mat-label>Telefono</mat-label>
      <input matInput formControlName="Telefono" autocomplete="off" #telefono appOnlynumber maxlength="10" type="number">
      <mat-hint align="end">{{telefono.value.length}} / 10</mat-hint>
      <button mat-button *ngIf="telefono.value" matSuffix mat-icon-button aria-label="Clear" (click)="Telefono.reset()">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>

    <mat-form-field class="example-full-width col-lg-11 pl-0 pr-0 pr-lg-2">
      <mat-label>Via / Piazza</mat-label>
      <input matInput formControlName="indirizzo" autocomplete="off">
      <button mat-button *ngIf="indirizzo.value" matSuffix mat-icon-button aria-label="Clear"
        (click)="indirizzo.reset()">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>

    <mat-form-field class="example-full-width col-lg-1 col-12 pl-1 pl-lg-0 pr-0">
      <mat-label>N°</mat-label>
      <input matInput formControlName="numeroCivico" autocomplete="off" appOnlynumber type="number">
      <button mat-button *ngIf="numeroCivico.value" matSuffix mat-icon-button aria-label="Clear" (click)="numeroCivico.reset()">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>

    <mat-form-field class="example-full-width col-lg-4 col-8 pl-1 pl-lg-0 pr-0">
      <mat-label>Citta</mat-label>
      <input matInput formControlName="Citta" autocomplete="off">
      <button mat-button *ngIf="Citta.value" matSuffix mat-icon-button aria-label="Clear" (click)="Citta.reset()">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>

    <mat-form-field class="example-full-width col-4 col-lg-4 pr-1 ">
      <mat-label>Codice Postale / Cap</mat-label>
      <input matInput formControlName="CodicePostale" autocomplete="off" maxlength="5" appOnlynumber #CodicePostale>
      <mat-hint align="end">{{CodicePostale.value.length}} / 5</mat-hint>
      <button mat-button *ngIf="CodicePostale.value" matSuffix mat-icon-button aria-label="Clear"
        (click)="CodicePostale.reset()">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>

    <mat-form-field class="example-full-width col-lg-4 col-12 pl-6 pl-0 pr-0 pl-2">
      <mat-label>Partita Iva</mat-label>
      <input matInput formControlName="PartitaIva" #PartitaIva appOnlynumber maxlength="11" autocomplete="off">
      <mat-hint align="end">{{PartitaIva.value.length}} / 11</mat-hint>
      <button mat-button *ngIf="PartitaIva.value" matSuffix mat-icon-button aria-label="Clear"
        (click)="PartitaIva.reset()">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
    <mat-form-field class="example-full-width col-lg-8 col-12 pl-lg-0 pl-1 pr-0 pr-lg-2">
      <mat-label>Codice Fiscale</mat-label>
      <input matInput formControlName="CodiceFiscale" autocomplete="off" class="text-uppercase">
      <button mat-button *ngIf="CodiceFiscale.value" matSuffix mat-icon-button aria-label="Clear"
        (click)="CodiceFiscale.reset()">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>



    <mat-form-field class="example-full-width col-lg-4 col-12 pl-lg-0 pl-1 pr-0 pr-lg-0">
      <mat-label>Codice SDI</mat-label>
      <input matInput formControlName="CodiceSDI" autocomplete="off" class="text-uppercase">
      <button mat-button *ngIf="CodiceSDI.value" matSuffix mat-icon-button aria-label="Clear"
        (click)="CodiceSDI.reset()">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>

    <mat-form-field class="example-full-width col-lg-12 col-12 pl-1 pl-lg-0 pr-0 pr-lg-0">
      <mat-label>PEC</mat-label>
      <input matInput formControlName="PEC" autocomplete="off">
      <button mat-button *ngIf="PEC.value" matSuffix mat-icon-button aria-label="Clear" (click)="PEC.reset()">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
  </div>
  <div class="d-flex justify-content-center w-100">
    <button class="btn btn-primary text-white submit-button mt-3 col-md-8 col-12" color="primary" type="submit"
      [disabled]="!formSpedizione.valid" (click)="salvaNuovoIndirizzoPartitaIva(formSpedizione.value)">
      <span class="material-icons">
        add
      </span>
      {{!!form ? 'Modifica': 'Inserisci'}} </button>
  </div>

</form>
