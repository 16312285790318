import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { DirectionService } from '../../../shared/services/direction.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

@Component({
    selector: 'app-block-slideshow',
    templateUrl: './block-slideshow.component.html',
    styleUrls: ['./block-slideshow.component.scss']
})
export class BlockSlideshowComponent implements OnInit {
    @Input() withDepartments = false;

    options = {
        nav: false,
        dots: false,
        loop: false,
        responsive: {
            0: { items: 1 }
        },
        rtl: this.direction.isRTL()
    };

    slides = [
        {
            title: 'La Boutique Del Caffè',
            text: 'Ti da il Benvenuto!',
            image_classic: 'https://firebasestorage.googleapis.com/v0/b/boutiquerafactory.appspot.com/o/img-prodotti%2Flaura-chouette-79X2RNfgqDk-unsplash-1-1.jpg?alt=media&token=08b5462f-d486-478c-9e12-96a414181525',
            image_full: 'https://firebasestorage.googleapis.com/v0/b/boutiquerafactory.appspot.com/o/img-prodotti%2Flaura-chouette-79X2RNfgqDk-unsplash-1-1.jpg?alt=media&token=08b5462f-d486-478c-9e12-96a414181525',
            image_mobile: 'https://firebasestorage.googleapis.com/v0/b/boutiquerafactory.appspot.com/o/img-prodotti%2Flaura-chouette-mobile.jpg?alt=media&token=ba9a2d81-9538-41df-97db-541b8ce8a498'
        }
    ];

    constructor(
        public sanitizer: DomSanitizer,
        private direction: DirectionService
    ) {
    }

    ngOnInit(): void {
    }

    scroll(id) {
        let el = document.getElementById(id);
        el.scrollIntoView({behavior: 'smooth'});
      }

}
