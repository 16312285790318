<!-- .block-products-carousel -->
<div class="block block-products-carousel" [ngClass]="{
        'block-products-carousel--loading': true,
        'block-products-carousel--has-items': !!columns?.length
    }" [attr.data-layout]="layout">
  <div [ngClass]="{'container-xxl': !withSidebar}" *ngIf="showCarousel">
    <app-block-header [header]="header" [groups]="groups" [arrows]="true" (next)="carousel.next()"
      (prev)="carousel.prev()" (groupChange)="groupChange.emit($event)"></app-block-header>

    <div class="block-products-carousel__slider" #container>
      <div class="block-products-carousel__preloader"></div>

      <owl-carousel-o [options]="customOptions" appOwlPreventClick #carousel>
        <ng-container *ngFor="let column of columns">
          <ng-template carouselSlide>
            <div class="block-products-carousel__column">
              <div *ngFor="let product of column" class="block-products-carousel__cell">
                <div class="products-view__list products-list w-100" [attr.data-layout]="'grid-3-sidebar'"
                  [attr.data-with-features]="false">
                  <div class="products-list__body" id="prodottoCorrelato">
                    <div class="products-list__item w-100" [ngClass]="column?.length >= 2 ? 'w-100' : ''">
                      <app-product-card [product]="product" [isInOrdiniEffettuati]="false" [elementoCarrello]="product">
                      </app-product-card>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
        </ng-container>
      </owl-carousel-o>
    </div>
  </div>
</div>
<!-- .block-products-carousel / end -->
