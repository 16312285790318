<div class="mobile-header" [ngClass]="{'mobile-header--sticky': stickyMode}" #element>
    <div class="mobile-header__panel" #panelElement>
        <div class="container-xxl">
            <div class="mobile-header__body">
                <button class="mobile-header__menu-button" (click)="menu.open()">
                    <app-icon name="menu-18x14" size="18x14"></app-icon>
                </button>
                <a class="mobile-header__logo mr-0 ml-1 ml-md-2 mr-md-2" routerLink="/">
                    <span class="text-white">La Boutique del Caffè</span>
                </a>
                <div class="mobile-header__indicators">
                    <!-- <div class="indicator indicator--mobile">
                        <a routerLink="/site/search/" class="indicator__button">
                            <span class="indicator__area">
                                <app-icon name="search-20" size="20"></app-icon>
                            </span>
                        </a>
                    </div> -->
                    <div class="indicator indicator--mobile" *ngIf="utenteConnesso == null">
                        <a routerLink="/account/login" class="indicator__button">
                            <span class="indicator__area">
                                <app-icon name="person-20" size="20"></app-icon>
                            </span>
                        </a>
                    </div>

                    <div class="indicator indicator--mobile" *ngIf="utenteConnesso != null">
                        <a routerLink="/account/dashboard" class="indicator__button">
                            <span class="indicator__area">
                                <app-icon name="person-20" size="20"></app-icon>
                            </span>
                        </a>
                    </div>


                    <div class="indicator indicator--mobile" *ngIf="utenteConnesso == null">
                        <a routerLink="/shop/cart" class="indicator__button">
                            <span class="indicator__area">
                                <app-icon name="cart-20" size="20"></app-icon>
                                <span class="indicator__value">{{ cart.quantity$|async }}</span>
                            </span>
                        </a>
                    </div>
                    <div class="indicator indicator--mobile" *ngIf="utenteConnesso != null">
                        <a routerLink="/shop/cart" class="indicator__button">
                            <span class="indicator__area">
                                <app-icon name="cart-20" size="20"></app-icon>
                                <span class="indicator__value">{{ countCartFire }}</span>
                            </span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
