<div class="site-footer">
  <div class="container-xxl">
    <div class="block-features mt-3">
      <div class="container-xxl customContainer">
        <div class="block-features__list d-block d-md-flex">
          <div class="block-features__item justify-content-center">
            <div class="block-features__icon">
              <app-icon name="fi-free-delivery-48" size="48"></app-icon>
            </div>
            <div class="block-features__content">
              <div class="block-features__title">Consegna Gratuita</div>
              <div class="block-features__subtitle">Per ordini superiori a 99€</div>
            </div>
          </div>
          <div class="block-features__divider"></div>
          <div class="block-features__item justify-content-center">
            <div class="block-features__icon">
              <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48">
                <path d="m630-310 20-20-156-156.024V-680h-28v206l164 164ZM480.174-132Q408-132 344.442-159.391q-63.559-27.392-110.575-74.348-47.015-46.957-74.441-110.435Q132-407.652 132-479.826q0-72.174 27.391-135.732 27.392-63.559 74.348-110.574 46.957-47.016 110.435-74.442Q407.652-828 479.826-828q72.174 0 135.732 27.391 63.559 27.392 110.574 74.348 47.016 46.957 74.442 110.435Q828-552.348 828-480.174q0 72.174-27.391 135.732-27.392 63.559-74.348 110.575-46.957 47.015-110.435 74.441Q552.348-132 480.174-132ZM480-480Zm0 320q133 0 226.5-93.5T800-480q0-133-93.5-226.5T480-800q-133 0-226.5 93.5T160-480q0 133 93.5 226.5T480-160Z"/></svg>
            </div>
            <div class="block-features__content">
              <div class="block-features__title">Tempi di spedizione</div>
              <div class="block-features__subtitle">Dai <b>2</b> ai <b>5</b> giorni lavorativi</div>
            </div>
          </div>
          <div class="block-features__divider"></div>
          <div class="block-features__item justify-content-center">
            <div class="block-features__icon">
              <app-icon name="fi-payment-security-48" size="48"></app-icon>
            </div>
            <div class="block-features__content">
              <div class="block-features__title">100% Sicuro</div>
              <div class="block-features__subtitle">Solo pagamenti sicuri</div>
            </div>
          </div>
          <div class="block-features__divider"></div>
          <div class="block-features__item justify-content-center custom-block-features" style="cursor: pointer;"
            (click)="go()">
            <div class="block-features__icon">
              <app-icon name="fi-tag-48" size="48"></app-icon>
            </div>
            <div class="block-features__content">
              <div class="block-features__title">Super Offerte</div>
              <div class="block-features__subtitle">Scopri tutte le offerte</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="site-footer__widgets">
      <div class="row">
        <div class="col-12 col-md-6 col-lg-4">
          <app-footer-contacts></app-footer-contacts>
        </div>
        <div class="col-6 col-md-3 col-lg-4">
          <app-footer-links header="Informazioni" [links]="[
                        {label: 'Privacy Policy',       url: '/site/privacy-policy'},
                        {label: 'Contattaci',           url: '/site/contact-us'}
            ]">
          </app-footer-links>
        </div>
        <div class="col-6 col-md-3 col-lg-4" *ngIf="auth.user$ | async as user; else notLogged">
          <app-footer-links header="Il mio account" [links]="[
                        {label: 'Dashboard', url: '/account/dashboard'},
                        {label: 'Cronologia Ordini',  url: '/account/orders'},
                        {label: 'Indirizzo Predefiniito', url: '/account/addresses'},
                        {label: 'Notifiche', url: '/account/notifiche'},
                    ]"></app-footer-links>
        </div>
        <ng-template #notLogged>
          <div class="col-md-3 col-6">
            <app-footer-links header="Link Utili" [links]="[
            {label: 'Tutti i prodotti',      url: '/shop/catalog/tutti_i_prodotti'},
            {label: 'Accedi o Registrati', url: '/account/login'},
            {label: 'Password Dimenticata?',  url: '/account/password-dimenticata'}
        ]"></app-footer-links>
          </div>
        </ng-template>
      </div>
    </div>

    <div class="site-footer__bottom">
      <div class="site-footer__copyright">
        Copyright © 2021 La Boutique del Caffè. <br class="d-block d-md-none"> All Right Reserved.
      </div>
      <div class="site-footer__payments">
        <img
          src="https://firebasestorage.googleapis.com/v0/b/boutiquerafactory.appspot.com/o/E7236D34-A9C1-4620-B269-EA5BC85F995D.png?alt=media&token=e67e0dfd-7d00-4025-b104-128c37148fb1"
          alt="">
      </div>
    </div>
  </div>
  <app-totop></app-totop>
</div>
