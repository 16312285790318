import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { CategoriaFiglioModels } from 'src/app/shared/models/CategoriaFiglioModels';
import { CategoriaGenitoreModels } from 'src/app/shared/models/CategoriaGenitoreModels';
import { DatiIndirizzoDiSpedizione, IndirizzoDiSpedizioneModels } from 'src/app/shared/models/IndirizzoDiSpedizioneModes';
import { DatiIndirizzoSpedizionePartitaIva, IndirizzoDiSpedizionePartitaIvaModels } from 'src/app/shared/models/IndirizzoDiSpedizionePartivaIvaModels';
import { DatiMessaggi, MessaggiModels } from 'src/app/shared/models/MessaggiModels';
import { DatiNuovoOrdine, NuovoOrdineModels } from 'src/app/shared/models/NuovoOrdineModels';
import { DatiProdotto, ProdottoModels } from 'src/app/shared/models/ProdottoModels';
import { RecensioneModels } from 'src/app/shared/models/RecensioneModels';
import { UltimaCategoriaModels } from 'src/app/shared/models/UltimaCategoriaModels';
import { DatiUtente, UtenteModels } from 'src/app/shared/models/UtenteModels';
import { PageDashboardInterface } from './page-dashboard/page-dashboard.interface';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { CarrelloFire, DatiCarrelloFire } from 'src/app/shared/models/CarrelloFire';
import { DatiNotifica, NotificaModels } from 'src/app/shared/models/NotificaModels';
import { AngularFireStorage } from '@angular/fire/storage';
import { INuovoIndirizzoPersonaFisica } from 'src/app/shared/components/nuovo-indirizzo-persona-fisica-template/nuovo-indirizzo-persona-fisica-template.interface';

@Injectable()
export class pageService implements INuovoIndirizzoPersonaFisica {

  headProdotto = [['Prodotto', 'Quantità', 'Prezzo']]


  constructor(public db: AngularFirestore, public store: AngularFireStorage) { }
  chiudiModale$: Subject<boolean> = new Subject<boolean>();

  editProfileAccount(id, value) {
    return this.db.collection('users').doc(id).set(value);
  }

  getCategoriaPrincipaleHeader() {
    return this.db.collection('categoria-genitore', res => res.where('visibileInHeader', '==', 'true').orderBy("nomeCategoria", "asc")).snapshotChanges().pipe(
      map(changes => {
        return changes.map(doc => {
          return {
            id: doc.payload.doc.id,
            data: doc.payload.doc.data()
          } as CategoriaGenitoreModels
        })
      })
    )
  }

  getCategoriaPrincipale() {
    return this.db.collection('categoria-genitore', res => res.orderBy("nomeCategoria", "asc")).snapshotChanges().pipe(
      map(changes => {
        return changes.map(doc => {
          return {
            id: doc.payload.doc.id,
            data: doc.payload.doc.data()
          } as CategoriaGenitoreModels
        })
      })
    )
  }

  getCategoriaFiglio(catGenitore) {
    return this.db.collection('categoria-figlio', res => res.where('catGenitore', '==', catGenitore).orderBy("nomeCategoria", "asc")).snapshotChanges().pipe(
      map(changes => {
        return changes.map(doc => {
          return {
            id: doc.payload.doc.id,
            data: doc.payload.doc.data()
          } as CategoriaFiglioModels
        })
      })
    )
  }

  getUltimaCategoria(catFiglio, catPadre) {
    return this.db.collection('ultima-categoria', res => res.where('catFiglio', '==', catFiglio).where('catPadre', "==", catPadre).orderBy("nomeCategoria", "asc")).snapshotChanges().pipe(
      map(changes => {
        return changes.map(doc => {
          return {
            id: doc.payload.doc.id,
            data: doc.payload.doc.data()
          } as UltimaCategoriaModels
        })
      })
    )
  }


  getUltimaCategoriaBySlug(slug) {
    return this.db.collection('ultima-categoria', res => res.where('slugCategoria', '==', slug)).snapshotChanges().pipe(
      map(changes => {
        return changes.map(doc => {
          return {
            id: doc.payload.doc.id,
            data: doc.payload.doc.data()
          } as UltimaCategoriaModels
        })
      })
    )
  }

  getColonnaSinistraFiglio(posizione) {
    return this.db.collection('categoria-figlio', res => res.where('posizione', '==', posizione)).snapshotChanges().pipe(
      map(changes => {
        return changes.map(doc => {
          return {
            id: doc.payload.doc.id,
            data: doc.payload.doc.data()
          } as CategoriaFiglioModels
        })
      })
    )
  }

  getUltimaColonnaDestra(catFiglio) {
    return this.db.collection('ultima-categoria', res => res.where('catGenitore', '==', catFiglio)).snapshotChanges().pipe(
      map(changes => {
        return changes.map(doc => {
          return {
            id: doc.payload.doc.id,
            data: doc.payload.doc.data()
          } as CategoriaFiglioModels
        })
      })
    )
  }

  AddNuovoIndirizzo(id, value, utente: DatiUtente) {
    return this.db.collection('indirizzo-di-spedizione').add({
      Nome: value.Nome,
      Cognome: value.Cognome,
      Paese: value.Paese,
      indirizzoSpedizione: value.indirizzoSpedizione,
      Citta: value.Citta,
      indirizzoEmail: value.indirizzoEmail,
      telefono: value.telefono,
      codicePostale: value.codicePostale,
      provincia: value.provincia,
      id: id,
      emailUtente: utente.email,
      idUtente: utente.uid,
      spedizioneGratuita: value.spedizioneGratuita,
      interno: value.interno,
      palazzina: value.palazzina
    } as DatiIndirizzoDiSpedizione
    );
  }

  salvaMessaggio(value, stato) {
    let data = new Date()
    return this.db.collection('messaggi').add({
      Email: value.Email,
      Messaggio: value.Messaggio,
      Nome: value.Nome,
      Oggetto: value.Oggetto,
      Data: data,
      Stato: stato
    });
  }

  getAllMessaggi() {
    let dataOdierna = new Date();
    return this.db.collection('messaggi', ref => ref.orderBy('Data', 'desc')).snapshotChanges().pipe(
      map(changes => {
        return changes.map(doc => {
          return {
            id: doc.payload.doc.id,
            data: doc.payload.doc.data()
          } as MessaggiModels
        })
      })
    )
  }
  getMessaggioPerId(id) {
    return this.db.collection('messaggi').doc(id).get().pipe(
      map(changes => {
        return changes.data() as DatiMessaggi
      })
    )
  }

  getAllMessaggiNonVisualizzati() {
    return this.db.collection('messaggi', ref => ref.where('Stato', '==', false)).snapshotChanges().pipe(
      map(changes => {
        return changes.map(doc => {
          return {
            id: doc.payload.doc.id,
            data: doc.payload.doc.data()
          } as MessaggiModels
        })
      })
    )
  }

  editMessaggioVisualizzato(id, value) {
    return this.db.collection('messaggi').doc(id).set(value);
  }

  AddIndirizzoPredefinito(id, value) {
    return this.db.collection('indirizzo-di-spedizione').add({
      Nome: value.Nome,
      Cognome: value.Cognome,
      Paese: value.Paese,
      indirizzoSpedizione: value.indirizzoSpedizione,
      Citta: value.Citta,
      indirizzoEmail: value.indirizzoEmail,
      telefono: value.telefono,
      codicePostale: value.codicePostale,
      provincia: value.provincia,
      predefinito: true,
      id: id
    });
  }

  AddIndirizzoPredefinitoPartitaIva(id, value: DatiIndirizzoSpedizionePartitaIva) {
    return this.db.collection('partita-iva-dati-fatturazione').add({
      RagioneSociale: value.RagioneSociale,
      SedeLegale: value.SedeLegale,
      CodicePostale: value.CodicePostale,
      Citta: value.Citta,
      PartitaIva: value.PartitaIva,
      CodiceFiscale: value.CodiceFiscale,
      Telefono: value.Telefono,
      CodiceSDI: value.CodiceSDI,
      PEC: value.PEC,
      id: id,
      indirizzo: value.indirizzo,
      numeroCivico: value.numeroCivico
    } as DatiIndirizzoSpedizionePartitaIva
    );
  }

  getIndirizzoPredefinitoPartitaIva(id) {
    return this.db.collection('partita-iva-dati-fatturazione', ref => ref.where('id', '==', id)).snapshotChanges().pipe(
      map(changes => {
        return changes.map(doc => {
          return {
            id: doc.payload.doc.id,
            data: doc.payload.doc.data()
          } as IndirizzoDiSpedizionePartitaIvaModels
        })
      })
    )
  }

  GetIndirizzoByEmail(email) {
    return this.db.collection('indirizzo-di-spedizione', ref => ref.where('emailUtente', '==', email)).snapshotChanges().pipe(
      map(changes => {
        return changes.map(doc => {
          return {
            id: doc.payload.doc.id,
            data: doc.payload.doc.data()
          } as IndirizzoDiSpedizioneModels
        })
      })
    )
  }

  GetIndirizzoByID(idUtente) {
    return this.db.collection('indirizzo-di-spedizione', ref => ref.where('idUtente', '==', idUtente)).snapshotChanges().pipe(
      map(changes => {
        return changes.map(doc => {
          return {
            id: doc.payload.doc.id,
            data: doc.payload.doc.data()
          } as IndirizzoDiSpedizioneModels
        })
      })
    )
  }

  GetIndirizzoByEmailSingle(email) {
    return this.db.collection('indirizzo-di-spedizione', ref => ref.where('emailUtente', '==', email)).get().pipe(
      map(changes => {
        return changes.docs.map(doc => {
          return {
            id: doc.id,
            data: doc.data()
          } as IndirizzoDiSpedizioneModels
        })
      })
    )
  }

  GetIndirizzoByIdUtente(idUtente) {
    return this.db.collection('indirizzo-di-spedizione', ref => ref.where('idUtente', '==', idUtente)).get().pipe(
      map(changes => {
        return changes.docs.map(doc => {
          return {
            id: doc.id,
            data: doc.data()
          } as IndirizzoDiSpedizioneModels
        })
      })
    )
  }

  getAllOrdiniRicevuti() {
    return this.db.collection('nuovo-ordine').snapshotChanges().pipe(
      map(changes => {
        return changes.map(doc => {
          return {
            id: doc.payload.doc.id,
            data: doc.payload.doc.data()
          } as NuovoOrdineModels
        })
      })
    )
  }

  getAllOrdiniRicevutiInAccettazione() {
    return this.db.collection('nuovo-ordine', ref => ref.where('statoOrdine', '==', 'In accettazione').orderBy('dataOrdine', 'desc')).snapshotChanges().pipe(
      map(changes => {
        return changes.map(doc => {
          return {
            id: doc.payload.doc.id,
            data: doc.payload.doc.data()
          } as NuovoOrdineModels
        })
      })
    )
  }

  getAllOrdiniRicevutiConfermati() {
    return this.db.collection('nuovo-ordine', ref => ref.where('statoOrdine', '==', 'Confermato').orderBy('dataOrdine', 'desc')).snapshotChanges().pipe(
      map(changes => {
        return changes.map(doc => {
          return {
            id: doc.payload.doc.id,
            data: doc.payload.doc.data()
          } as NuovoOrdineModels
        })
      })
    )
  }

  getAllUtenti() {
    return this.db.collection('users').snapshotChanges().pipe(
      map(changes => {
        return changes.map(doc => {
          return {
            id: doc.payload.doc.id,
            data: doc.payload.doc.data()
          } as UtenteModels
        })
      })
    )
  }

  getAllOrdiniRicevutiRifiutati() {
    return this.db.collection('nuovo-ordine', ref => ref.where('statoOrdine', '==', 'Rifiutato').orderBy('dataOrdine', 'desc')).snapshotChanges().pipe(
      map(changes => {
        return changes.map(doc => {
          return {
            id: doc.payload.doc.id,
            data: doc.payload.doc.data()
          } as NuovoOrdineModels
        })
      })
    )
  }

  getOrdineByID(id) {
    return this.db.collection('nuovo-ordine').doc(id).get().pipe(
      map(changes => {
        return changes.data() as DatiNuovoOrdine
      })
    )
  }

  accettaOrdineRicevutoByID(id, value) {
    return this.db.collection('nuovo-ordine').doc(id).set(value);
  }

  editOrdineRicevutoByID(id, value) {
    return this.db.collection('nuovo-ordine').doc(id).set(value);
  }


  editIndirizzoModificato(id, value) {
    return this.db.collection('indirizzo-di-spedizione').doc(id).set(value);
  }

  editIndirizzoModificatoPartitaIva(id, value) {
    return this.db.collection('partita-iva-dati-fatturazione').doc(id).set(value);
  }

  getUtenteByID(id) {
    return this.db.collection('users', ref => ref.where('uid', '==', id)).snapshotChanges().pipe(
      map(changes => {
        return changes.map(doc => {
          return {
            id: doc.payload.doc.id,
            data: doc.payload.doc.data()
          } as UtenteModels
        })
      })
    )
  }

  getUtenteByEmail(email) {
    return this.db.collection('users', ref => ref.where('email', '==', email)).snapshotChanges().pipe(
      map(changes => {
        return changes.map(doc => {
          return {
            id: doc.payload.doc.id,
            data: doc.payload.doc.data()
          } as UtenteModels
        })
      })
    )
  }

  editListaIndirizzoForDefault(id, value) {
    return this.db.collection('indirizzo-di-spedizione').doc(id).set(value);
  }

  setIndirizzoPredefinito(id, value) {
    return this.db.collection('indirizzo-di-spedizione').doc(id).set(value);
  }

  cancellaIndirizzoByID(id) {
    return this.db.collection('indirizzo-di-spedizione').doc(id).delete();
  }

  cancellaIndirizzoByIDPartitaIva(id) {
    return this.db.collection('partita-iva-dati-fatturazione').doc(id).delete();
  }

  getIndirizzoPredefinitoDashboard(id) {
    return this.db.collection('indirizzo-di-spedizione', ref => ref.where('id', '==', id).where('predefinito', '==', true)).snapshotChanges().pipe(
      map(changes => {
        return changes.map(doc => {
          return {
            id: doc.payload.doc.id,
            data: doc.payload.doc.data()
          } as IndirizzoDiSpedizioneModels
        })
      })
    )
  }



  getListaIndirizziUtente(id) {
    return this.db.collection('indirizzo-di-spedizione', ref => ref.where('id', '==', id)).get().pipe(
      map(changes => {
        return changes.docs.map(r => {
          return {
            data: r.data(),
            id: r.id
          } as IndirizzoDiSpedizioneModels
        })
      })
    )
  }

  addNuovoProdotto(value, slugProdotto) {
    return this.db.collection('prodotti-inseriti').add({
      NomeProdotto: value.NomeProdotto,
      prezzo: value.prezzo,
      disponibilita: value.disponibilita,
      disponibilitaReale: value.disponibilita,
      immagineProdotto: value.immagineProdotto,
      descrizione: value.descrizione,
      categoriaDefault: value.categoriaDefault,
      categoria: value.categoria,
      categoriaFiglio: value.categoriaFiglio,
      ultimaCategoria: value.ultimaCategoria,
      slugProdotto: slugProdotto,
      reviews: value.reviews,
      stelle: value.stelle,
      dataInserimento: value.dataInserimento,
      inOfferta: value.inOfferta,
      prezzoPrecedente: value.prezzoPrecedente,
      scontoPercentuale: value.scontoPercentuale
    } as DatiProdotto);
  }


  getAllProdotti() {
    return this.db.collection('prodotti-inseriti').snapshotChanges().pipe(
      map(changes => {
        return changes.map(doc => {
          return {
            id: doc.payload.doc.id,
            data: doc.payload.doc.data()
          } as ProdottoModels
        })
      })
    )
  }


  getProdottoBySlug(slug) {
    return this.db.collection('prodotti-inseriti', res => res.where('slugProdotto', '==', slug)).snapshotChanges().pipe(
      map(changes => {
        return changes.map(doc => {
          return {
            id: doc.payload.doc.id,
            data: doc.payload.doc.data()
          } as ProdottoModels
        })
      })
    )
  }

  recuperaOrdiniEffettuatiByEmail(id) {
    return this.db.collection('nuovo-ordine', res => res.where('idUtente', '==', id).orderBy('dataOrdine', 'desc').limit(3)).snapshotChanges().pipe(
      map(changes => {
        return changes.map(doc => {
          return {
            id: doc.payload.doc.id,
            data: doc.payload.doc.data()
          } as NuovoOrdineModels
        })
      })
    )
  }

  recuperaOrdiniEffettuatiByEmailDecrescente(id) {
    return this.db.collection('nuovo-ordine', res => res.where('idUtente', '==', id).orderBy('dataOrdine', 'desc')).snapshotChanges().pipe(
      map(changes => {
        return changes.map(doc => {
          return {
            id: doc.payload.doc.id,
            data: doc.payload.doc.data()
          } as NuovoOrdineModels
        })
      })
    )
  }

  recuperaOrdineSingoloByID(id) {
    return this.db.collection('nuovo-ordine').doc(id).get().pipe(
      map(changes => {
        return changes.data() as DatiNuovoOrdine
      })
    )
  }

  AddCategoriaGenitore(slug, nome, id, value) {
    return this.db.collection('categoria-genitore').add({
      nomeCategoria: nome,
      slugCategoria: slug,
      idGerarchia: id,
      sottoCategoria: value.datiMinimi.sottoCategoria,
      visibileInHeader: value.datiMinimi.visibileInHeader,
      numeroColonne: value.opzioniLayout.numeroColonne
    });
  }

  AddCategoriaFiglio(slug, nome, catGenitore, value, id) {
    return this.db.collection('categoria-figlio').add({
      nomeCategoria: nome,
      slugCategoria: slug,
      idGerarchia: id,
      catGenitore: catGenitore,
      sottoCategoria: value.datiMinimiFiglio.sottoCategoria,
      posizione: value.opzioniLayoutFiglio.posizione
    });
  }

  AddUltimaCategoria(slug, nome, catFiglio, catPadre, id) {
    return this.db.collection('ultima-categoria').add({
      nomeCategoria: nome,
      slugCategoria: slug,
      idGerarchia: id,
      catFiglio: catFiglio,
      catPadre: catPadre
    });
  }

  getAllCategoriagenitore() {
    return this.db.collection('categoria-genitore', res => res.orderBy("nomeCategoria", "asc")).snapshotChanges().pipe(
      map(changes => {
        return changes.map(doc => {
          return {
            id: doc.payload.doc.id,
            data: doc.payload.doc.data()
          } as CategoriaGenitoreModels
        })
      })
    )
  }

  getAllCategoriaFiglio() {
    return this.db.collection('categoria-figlio').snapshotChanges().pipe(
      map(changes => {
        return changes.map(doc => {
          return {
            id: doc.payload.doc.id,
            data: doc.payload.doc.data()
          } as CategoriaFiglioModels
        })
      })
    )
  }

  getAllUltimaCategoria() {
    return this.db.collection('ultima-categoria').snapshotChanges().pipe(
      map(changes => {
        return changes.map(doc => {
          return {
            id: doc.payload.doc.id,
            data: doc.payload.doc.data()
          } as UltimaCategoriaModels
        })
      })
    )
  }

  editCategoriaPrincipale(id, value) {
    return this.db.collection('categoria-genitore').doc(id).set(value);
  }

  editCategoriaFiglio(id, value) {
    return this.db.collection('categoria-figlio').doc(id).set(value);
  }

  editUltimaCategoria(id, value) {
    return this.db.collection('ultima-categoria').doc(id).set(value);
  }

  editProdottoInserito(id, value) {
    return this.db.collection('prodotti-inseriti').doc(id).set(value);
  }

  cancellaProdottoByID(id) {
    return this.db.collection('prodotti-inseriti').doc(id).delete();
  }

  cancellaCategoriaCategoriaPrincipaleByID(id) {
    return this.db.collection('categoria-genitore').doc(id).delete();
  }

  cancellaCategoriaFiglioByID(id) {
    return this.db.collection('categoria-figlio').doc(id).delete();
  }

  cancellaUltimaCategoriaByID(id) {
    return this.db.collection('ultima-categoria').doc(id).delete();
  }

  editUtente(id, value) {
    return this.db.collection('users').doc(id).set(value);
  }

  modificaProdottoByID(id, value) {
    return this.db.collection('prodotti-inseriti').doc(id).set(Object.assign({}, value));
  }

  AddRecensioneByIdProdotto(value, stelle, utente, idProdotto) {
    let data = new Date();
    return this.db.collection('reviews').add({
      stelle: stelle,
      descrizione: value.descrizione,
      displayName: utente.displayName,
      email: utente.email,
      photoURL: utente.photoURL,
      idProdotto: idProdotto,
      dataCommento: data
    });
  }

  getAllCommentoByIdProdotto(value) {
    return this.db.collection('reviews', res => res.where('idProdotto', '==', value).orderBy('dataCommento', 'desc')).snapshotChanges().pipe(
      map(changes => {
        return changes.map(doc => {
          return {
            id: doc.payload.doc.id,
            data: doc.payload.doc.data()
          } as RecensioneModels
        })
      })
    )
  }

  getProdottoById(id) {
    return this.db.collection('prodotti-inseriti').doc(id).get().pipe(
      map(changes => {
        return changes.data() as DatiProdotto
      })
    )
  }

  getCarrelloFireByID(idUtente) {
    return this.db.collection('carrello', res => res.where('idUtente', '==', idUtente).where('visibility', '==', true)).snapshotChanges().pipe(
      map(changes => {
        return changes.map(doc => {
          return {
            id: doc.payload.doc.id,
            data: doc.payload.doc.data()
          } as CarrelloFire
        })
      })
    )
  }

  checkProdottoSuCarrello(v) {
    return this.db.collection('carrello', res => res.where('idProdotto', '==', v)).get().pipe(
      map(changes => {
        return changes.docs.map(r => {
          return {
            data: r.data(),
            id: r.id
          } as CarrelloFire
        })
      })
    )
  }

  editProdottoSuCarrelloFire(id, value: DatiCarrelloFire) {
    return this.db.collection('carrello').doc(id).set(Object.assign({}, value));
  }

  getNotificaByIdUtente(idUtente) {
    return this.db.collection('notifiche', res => res.where('idUtente', '==', idUtente).orderBy("dataNotifica", "desc")).snapshotChanges().pipe(
      map(changes => {
        return changes.map(doc => {
          return {
            id: doc.payload.doc.id,
            data: doc.payload.doc.data()
          } as NotificaModels
        })
      })
    )
  }

  getNotificaByIdUtenteLimitata(idUtente) {
    return this.db.collection('notifiche', res => res.where('idUtente', '==', idUtente).orderBy("dataNotifica", "desc").limit(6)).snapshotChanges().pipe(
      map(changes => {
        return changes.map(doc => {
          return {
            id: doc.payload.doc.id,
            data: doc.payload.doc.data()
          } as NotificaModels
        })
      })
    )
  }

  getNotificaByIdUtenteNoAzione(idUtente) {
    return this.db.collection('notifiche', res => res.where('idUtente', '==', idUtente).where('isVisualizzata', '==', false).orderBy("dataNotifica", "desc")).snapshotChanges().pipe(
      map(changes => {
        return changes.map(doc => {
          return {
            id: doc.payload.doc.id,
            data: doc.payload.doc.data()
          } as NotificaModels
        })
      })
    )
  }

  getNotificaByIdUtenteNoAzioneSingle(idUtente) {
    return this.db.collection('notifiche', res => res.where('idUtente', '==', idUtente).where('scelta', '==', 0)).get().pipe(
      map(changes => {
        return changes.docs.map(doc => {
          return {
            id: doc.id,
            data: doc.data()
          } as NotificaModels
        })
      })
    )
  }

  getNotificaByIdUtenteIdProdotto(idNotifica) {
    return this.db.collection('notifiche').doc(idNotifica).get().pipe(
      map(changes => {
        return changes.data() as DatiNotifica
      })
    )
  }

  getNotificaByIdUtenteIdOrdine(idOrdine) {
    return this.db.collection('notifiche', res => res.where('idOrdine', '==', idOrdine)).get().pipe(
      map(changes => {
        return changes.docs.map(doc => {
          return {
            id: doc.id,
            data: doc.data()
          } as NotificaModels
        })
      })
    )
  }

  cancellaNotificaFire(id) {
    return this.db.collection('notifiche').doc(id).delete();
  }

  editNotificaFire(id, value) {
    return this.db.collection('notifiche').doc(id).set(Object.assign({}, value));
  }

  getNotificaAccettataByProdotto(idProdotto) {
    return this.db.collection('notifiche', res => res.where('idProdotto', '==', idProdotto).where('notificaInviataAlCliente', '==', false)).get().pipe(
      map(changes => {
        return changes.docs.map(res => {
          return {
            id: res.id,
            data: res.data()
          } as NotificaModels
        })
      })
    )
  }

  getCarrelloById(idCarrello) {
    return this.db.collection('carrello').doc(idCarrello).get().pipe(
      map(changes => {
        return changes.data() as DatiCarrelloFire
      })
    )
  }

  inviaNotifica(value: DatiNotifica) {
    return this.db.collection('notifiche').add({
      dataNotifica: value.dataNotifica,
      idCarrello: value.idCarrello,
      idProdotto: value.idProdotto,
      idUtente: value.idUtente,
      nomeProdotto: value.nomeProdotto,
      quantita: value.quantita,
      scelta: value.scelta,
      immagineProdotto: value.immagineProdotto,
      notificaInviataAlCliente: false,
      isNotificaSistema: true,
      idOrdine: value.idOrdine,
      isVisualizzata: value.isVisualizzata
    } as DatiNotifica)
  }

  caricaFileImmagine(file){
    return this.store.storage.ref().child('immage' + file.name).put(file);
  }

  pdf(value:DatiNuovoOrdine) {

    let y = 27 + 30;
    let prepare = [];
    value.elementi.forEach(e => {
      let temp = [];
      let nomeProdotto = e.data.NomeProdotto;
      let quantitaProdotto = e.data.quantita;
      let prezzo = e.data.prodotto.prezzo + '€';
      temp.push(nomeProdotto);
      temp.push(quantitaProdotto);
      temp.push(prezzo);
      prepare.push(temp)
      y = y + 8;
    })

    var doc = new jsPDF();
    doc.setFontSize(16);
    let image = new Image();
    image.src = 'assets/images/logomini.png';
    doc.addImage(image, 'png', 10, 10, 60, 17);
    doc.setFontSize(11);
    doc.setTextColor(100);

    doc.text(' ', 10, 10);

    (doc as any).autoTable({
      head: this.headProdotto,
      startY: 40,
      body: prepare,
      theme: 'striped',
      didDrawCell: data => {
      }
    })

    let totale = 'Totale: ' + value.importo.toFixed(2) + ' €';
    doc.setFontSize(14);
    doc.text(totale, 160, y);

    if (value.modPagamento != 'Preordina in Negozio') {
      doc.setFontSize(13);
      doc.text('Indirizzo di Spedizione', 15, y + 10);
      doc.setFontSize(11);
      let nomeCliente = value.spedizione.Nome + ' ' + value.spedizione.Cognome;
      if (value.flagPersonaFisica == true) {
        doc.text(nomeCliente, 15, y + 20);
      } else {
        doc.text(nomeCliente, 15, y + 20);
      }
      doc.setFontSize(10);
      doc.text('Italia', 15, y + 27);
      let cittaIndirizzo = value.spedizione.Citta + ', ' + value.spedizione.indirizzoSpedizione;
      doc.text(cittaIndirizzo, 15, y + 32);
      doc.text(value.spedizione.codicePostale, 15, y + 37);
      doc.text('Numero Telefonico', 15, y + 45);
      doc.text(value.spedizione.telefono, 15, y + 50);
      doc.text('Indirizzo Email', 15, y + 58);
      doc.text(value.spedizione.indirizzoEmail, 15, y + 63);

      doc.setFontSize(13);
      doc.text('Indirizzo di Fatturazione', 120, y + 10);
      doc.setFontSize(11);
      if (value.flagPersonaFisica == true) {
        let nomeCliente = value.spedizione.Nome + ' ' + value.spedizione.Cognome;
        doc.text(nomeCliente, 120, y + 20);
        doc.setFontSize(10);
        doc.text('Italia', 120, y + 27);
        doc.text(cittaIndirizzo, 120, y + 32);
        doc.text(value.spedizione.codicePostale, 120, y + 37);
        doc.text('Numero Telefonico', 120, y + 45);
        doc.text(value.spedizione.telefono, 120, y + 50);
        doc.text('Indirizzo Email', 120, y + 58);
        doc.text(value.spedizione.indirizzoEmail, 120, y + 63);
      } else {
        doc.text(value.fatturazione.RagioneSociale, 120, y + 20);
        doc.setFontSize(10);
        doc.text('Sede legale', 120, y + 27);
        doc.text(value.fatturazione.SedeLegale, 120, y + 32);
        doc.text('Partita IVA', 120, y + 40);
        doc.text(value.fatturazione.PartitaIva, 120, y + 45);
        doc.text('Codice Fiscale', 120, y + 53);
        doc.text(value.fatturazione.CodiceFiscale.toUpperCase(), 120, y + 58);
        doc.text('Numero Telefonico', 120, y + 66);
        doc.text(value.fatturazione.Telefono, 120, y + 71);
        doc.text('Codice SDI', 120, y + 79);
        doc.text(value.fatturazione.CodiceSDI, 120, y + 84);
        doc.text('Indirizzo PEC', 120, y + 92);
        doc.text(value.fatturazione.PEC, 120, y + 97);
      }

    } else {

    }


    return doc.save('nuovo-ordine.pdf')
  }

}
