import { Component, EventEmitter, Inject, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { pageService } from 'src/app/modules/account/pages/pageService';
import { DatiIndirizzoSpedizionePartitaIva } from '../../models/IndirizzoDiSpedizionePartivaIvaModels';
import { DatiUtente } from '../../models/UtenteModels';
import { INuovoIndirizzoPartitaIva, NUOVO_INDIRIZZO_PARTITA_IVA_TOKEN } from './nuovo-indirizzo-partita-iva-template.interface';

@Component({
  selector: 'app-nuovo-indirizzo-partita-iva-template',
  templateUrl: './nuovo-indirizzo-partita-iva-template.component.html',
  styleUrls: ['./nuovo-indirizzo-partita-iva-template.component.scss'],
  providers: [pageService]
})
export class NuovoIndirizzoPartitaIvaTemplateComponent implements OnInit {

  @Input('utente') utente: DatiUtente;

  formSpedizione: FormGroup;
  public RagioneSociale: FormControl = new FormControl('', Validators.required);
  public SedeLegale: FormControl = new FormControl('', Validators.required);
  public CodicePostale: FormControl = new FormControl('', Validators.required);
  public Citta: FormControl = new FormControl('', Validators.required);
  public PartitaIva: FormControl = new FormControl('', [Validators.required, Validators.minLength(11)]);
  public CodiceFiscale: FormControl = new FormControl('', Validators.required);
  public Telefono: FormControl = new FormControl('', [Validators.required, Validators.minLength(10)]);
  public CodiceSDI: FormControl = new FormControl('', Validators.required);
  public PEC: FormControl = new FormControl('', [Validators.required, Validators.email]);
  public id: FormControl = new FormControl('');
  public numeroCivico: FormControl = new FormControl('', Validators.required);
  public indirizzo: FormControl = new FormControl('', Validators.required);

  messageOk: string = 'Registrazione indirizzo avvenuta correttamente.';
  messageDelete: string = 'Cancellazione Avvenuta.';
  actionButtonLabel: string = '';
  action: boolean = true;
  setAutoHide: boolean = true;
  autoHide: number = 2000;
  horizontalPosition: MatSnackBarHorizontalPosition = 'right';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';

  @Input('form') form: DatiIndirizzoSpedizionePartitaIva;
  @Input('idIndirizzo') idIndirizzo: string;
  @Output() chiudiModaleEvent: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    @Inject(NUOVO_INDIRIZZO_PARTITA_IVA_TOKEN) public INuovoIndirizzoPartitaIva: INuovoIndirizzoPartitaIva,
    public pageService: pageService,
    public snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.createFormIndirizzoPartitaIva();
    if (!!this.form) {
      this.formSpedizione.patchValue(this.form);
    }
  }

  createFormIndirizzoPartitaIva() {
    this.formSpedizione = new FormGroup({});
    this.formSpedizione.addControl('RagioneSociale', this.RagioneSociale);
    this.formSpedizione.addControl('SedeLegale', this.SedeLegale);
    this.formSpedizione.addControl('CodicePostale', this.CodicePostale);
    this.formSpedizione.addControl('Citta', this.Citta);
    this.formSpedizione.addControl('PartitaIva', this.PartitaIva);
    this.formSpedizione.addControl('CodiceFiscale', this.CodiceFiscale);
    this.formSpedizione.addControl('Telefono', this.Telefono);
    this.formSpedizione.addControl('CodiceSDI', this.CodiceSDI);
    this.formSpedizione.addControl('PEC', this.PEC);
    this.formSpedizione.addControl('id', this.id);
    this.formSpedizione.addControl('numeroCivico', this.numeroCivico);
    this.formSpedizione.addControl('indirizzo', this.indirizzo);

  }

  salvaNuovoIndirizzoPartitaIva(value) {
    if (!!this.form) {
      this.pageService.editIndirizzoModificatoPartitaIva(this.idIndirizzo, this.formSpedizione.value).then();
      this.chiudiModaleEvent.emit()
    } else {
      this.pageService.AddIndirizzoPredefinitoPartitaIva(this.utente.uid, value).then(
        res => {
          let config = new MatSnackBarConfig();
          config.verticalPosition = this.verticalPosition;
          config.horizontalPosition = this.horizontalPosition;
          config.duration = this.setAutoHide ? this.autoHide : 0;
          this.snackBar.open(this.messageOk, this.action ? this.actionButtonLabel : undefined, config);
          this.INuovoIndirizzoPartitaIva.chiudiModale$.next(true);
        }
      )
    }

  }

}
