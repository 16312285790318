import { /*LOCALE_ID, */NgModule, CUSTOM_ELEMENTS_SCHEMA, enableProdMode } from '@angular/core';
// import { registerLocaleData } from '@angular/common';
// import localeIt from '@angular/common/locales/it';
//
// registerLocaleData(localeIt, 'it');

// modules (angular)
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// modules (third-party)
import { CarouselModule } from 'ngx-owl-carousel-o';
import { ToastrModule } from 'ngx-toastr';

// modules
import { AppRoutingModule } from './app-routing.module';
import { BlocksModule } from './modules/blocks/blocks.module';
import { FooterModule } from './modules/footer/footer.module';
import { HeaderModule } from './modules/header/header.module';
import { MobileModule } from './modules/mobile/mobile.module';
import { SharedModule } from './shared/shared.module';
import { WidgetsModule } from './modules/widgets/widgets.module';

// components
import { AppComponent } from './app.component';
import { RootComponent } from './components/root/root.component';

// pages
import { PageHomeOneComponent } from './pages/page-home-one/page-home-one.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { environment } from 'src/environments/environment.prod';


import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { DemoMaterialModule } from './material-module';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { CustomMatPaginatorIntl } from './shared/services/paginator';
import { LocationStrategy, HashLocationStrategy, CurrencyPipe } from '@angular/common';
import { PageCategoryInternalService } from './modules/shop/pages/page-category/page-categoty-internal.service';
import { PAGE_HEADER_TOKEN } from './shared/components/page-header/page-header.interface';
import { MAT_DATE_LOCALE, DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { NgcCookieConsentModule, NgcCookieConsentConfig } from 'ngx-cookieconsent';

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MM YYYY',
    dateA11yLabel: 'DD/MM/YYYY',
    monthYearA11yLabel: 'MM YYYY',
  },
};

const cookieConfig: NgcCookieConsentConfig =
{
  cookie: {
    domain: "https://laboutiquedelcaffe.eu"
  },
  position: "bottom-right",
  theme: "classic",
  palette: {
    popup: {
      background: "#422952"
    },
    button: {
      background: "#fff",
      text: "#222"
    }
  },
  type: "opt-in",
  content: {
    message: '<b>Il rispetto della tua privacy è la nostra priorità!</b><br> Il sito laboutiquedelcaffe.eu utilizza i cookie, anche di terzi, per offrire servizi in linea con le tue preferenze.',
    dismiss: 'Accetta',
    link: 'Leggi tutto',
    href: 'https://laboutiquedelcaffe.eu/#/site/privacy-policy',
    deny: "Rifiuta",
  }
}

enableProdMode();

@NgModule({
  declarations: [
    // components
    AppComponent,
    RootComponent,
    // pages
    PageHomeOneComponent,
    PageNotFoundComponent
  ],
  imports: [
    // modules (angular)
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
    // modules (third-party)
    CarouselModule,
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-right',
    }),
    // modules
    AppRoutingModule,
    BlocksModule,
    FooterModule,
    HeaderModule,
    MobileModule,
    SharedModule,
    WidgetsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireStorageModule,
    DemoMaterialModule,
    PaginationModule.forRoot(),
    AngularEditorModule,
    NgcCookieConsentModule.forRoot(cookieConfig)
  ],
  providers: [
    PageCategoryInternalService, CurrencyPipe,
    { provide: PAGE_HEADER_TOKEN, useClass: PageCategoryInternalService },
    {
      provide: MatPaginatorIntl,
      useClass: CustomMatPaginatorIntl
    },
    {
      provide: LocationStrategy, useClass: HashLocationStrategy
    },
    {
      provide: MAT_DATE_LOCALE,
      useValue: 'it'
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: MY_FORMATS
    },
    // { provide: LOCALE_ID, useValue: 'it' }
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
