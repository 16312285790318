import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { of } from 'rxjs';
import { filter, tap } from 'rxjs/operators';

@Component({
  selector: 'app-search-product',
  templateUrl: './search-product.component.html',
  styleUrls: ['./search-product.component.scss']
})
export class SearchProductComponent implements OnInit {

  @Output() closeMenu: EventEmitter<void> = new EventEmitter<void>();

  public formRicerca: FormGroup;
  public nomeProdotto: FormControl = new FormControl('', Validators.required);

  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
    this.formRicerca = new FormGroup({
      nomeProdotto: this.nomeProdotto
    })
  }

  effettuaLaRicerca(form) {
    of(null).pipe(
      filter(() => this.formRicerca.valid),
      tap(() => {
        this.router.navigate(['site/search', form.nomeProdotto]);
      })
    ).subscribe();
  }

}
