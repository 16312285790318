<app-block-slideshow [withDepartments]="true"></app-block-slideshow>

<div class="block block-products-carousel" [ngClass]="{
    'block-products-carousel--loading': loader
}" [attr.data-layout]="'grid-4'">
  <div class="container-xxl">
    <app-block-header [header]="'I Nostri Prodotti Più Amati'" [groups]="featuredProducts.groups" [arrows]="true" (next)="carousel.next()"
      (prev)="carousel.prev()" (groupChange)="groupChange(featuredProducts, $event)"></app-block-header>

    <div class="block-products-carousel__slider">
      <div class="block-products-carousel__preloader"></div>

      <owl-carousel-o [options]="customOptionsOfferte" appOwlPreventClick #carousel (dragging)="onDrag($event.dragging)">
        <!-- <div class="block-products-carousel__column">
          <div class="block-products-carousel__cell"> -->
            <ng-container *ngFor="let product of listaProdotti">
              <ng-template carouselSlide>
                <div class="products-view__list products-list w-100" [attr.data-layout]="'grid-3-sidebar'"
                  [attr.data-with-features]="false">
                  <div class="products-list__body w-100" id="prodottoCorrelato">
                    <div class="products-list__item" [ngClass]="listaProdotti?.length >= 2 ? 'w-100' : ''">
                      <app-product-card-carousel class="w-100" [product]="product" [isInOrdiniEffettuati]="false" [elementoCarrello]="product" [isDragging]="isDragging">
                      </app-product-card-carousel>
                    </div>
                  </div>
                </div>
              </ng-template>
            </ng-container>
          <!-- </div>
        </div> -->
      </owl-carousel-o>
    </div>
  </div>
</div>

<!-- Riga per la scelta dei sistemi -->
<div class="block block-banner">
  <div class="container-xxl">
    <div class="block-header">
      <h3 class="block-header__title">Scegli il tuo sistema</h3>
      <div class="block-header__divider"></div>
      <div class="block-header__arrows-list">
        <button class="block-header__arrow block-header__arrow--left" type="button" (click)="arrow.prev()">
          <app-icon name="arrow-rounded-left-7x11" size="7x11"></app-icon>
        </button>
        <button class="block-header__arrow block-header__arrow--right" type="button" (click)="arrow.next()">
          <app-icon name="arrow-rounded-right-7x11" size="7x11"></app-icon>
        </button>
      </div>
    </div>
    <owl-carousel-o [options]="customOptions" appOwlPreventClick #arrow>
      <ng-container *ngFor="let slide of slidesStore">
        <ng-template carouselSlide>
          <div class="text-center">
            <a routerLink="{{slide.link}}" style="text-decoration: none;">
              <img [src]="slide.src" style="width:150px; height:158px; margin: 0 auto;">
              <span class="text-black">{{slide.Titolo}}</span>
            </a>
          </div>
        </ng-template>
      </ng-container>
    </owl-carousel-o>
  </div>
</div>

<div class="block block-banner mt-md-4 mt-5" *ngIf="listaProdottiOfferta?.length >= 1">
  <div class="container-xxl">
    <div class="block-header">
      <h3 class="block-header__title">Offerte Speciali</h3>
      <div class="block-header__divider"></div>
      <div class="block-header__arrows-list">
        <button class="block-header__arrow block-header__arrow--left" type="button" (click)="arrowOfferte.prev()">
          <app-icon name="arrow-rounded-left-7x11" size="7x11"></app-icon>
        </button>
        <button class="block-header__arrow block-header__arrow--right" type="button" (click)="arrowOfferte.next()">
          <app-icon name="arrow-rounded-right-7x11" size="7x11"></app-icon>
        </button>
      </div>
    </div>
    <owl-carousel-o [options]="customOptionsOfferte" appOwlPreventClick #arrowOfferte>
      <ng-container *ngIf="!hidden">
        <ng-container *ngFor="let slide of listaProdottiOfferta">
          <ng-template carouselSlide>
            <div class="products-view__list products-list w-100" [attr.data-layout]="'grid-3-sidebar'"
              [attr.data-with-features]="false">
              <div class="products-list__body w-100" id="prodottoCorrelato">
                <div class="products-list__item" [ngClass]="listaProdottiOfferta?.length >= 2 ? 'w-100' : ''">
                  <app-product-card [product]="slide" [isInOrdiniEffettuati]="false" [elementoCarrello]="slide">
                  </app-product-card>
                </div>
              </div>
            </div>
          </ng-template>
        </ng-container>
      </ng-container>
    </owl-carousel-o>
  </div>
</div>



<div class="container-xxl">
  <div class="block-header mt-2">
    <div class="block-header__divider"></div>
  </div>
  <div class="row">
    <div class="row ml-0 mr-0 pl-0 pr-0">
      <div class="col-lg-6 col-12 d-flex align-items-center justify-content-center">
        <ul class="pl-0" style="text-align: center; list-style:none;">
          <li>
            <div class="bannerLavazza"></div>
          </li>
          <li>
            <h6 class="text-center"><b class="text-danger">Promo</b> Lavazza compatibile
              Nespresso 100 capsule 23.00€</h6>
          </li>
          <li><button class="btn btn-primary mt-3 btn-sm" style="color: white;"
              routerLink="/shop/catalog/caffè_in_capsule/nespresso/lavazza">vai ai prodotti</button></li>
        </ul>
      </div>
      <div class="col-lg-6 col-12 d-flex align-items-center justify-content-center mt-3 mt-md-0">
        <ul class="pl-0 " style="text-align: center; list-style:none;">
          <li>
            <div class="sfondoDolceVita"></div>
          </li>
          <li>
            <h6>Vieni a trovarci in negozio e scoprirai tutti i prodotti della Dolce Vita</h6>
          </li>
          <li><button class="btn btn-primary btn-sm mt-3" style="color: white;" routerLink="/site/contact-us">Vieni in
              Negozio</button></li>
        </ul>
      </div>
    </div>
  </div>
</div>



<!-- Rica loghi -->
<div class="block block-banner">
  <div class="container-xxl">
    <div class="block-header">
      <h3 class="block-header__title">I Nostri Marchi</h3>
      <div class="block-header__divider"></div>
      <div class="block-header__arrows-list">
        <button class="block-header__arrow block-header__arrow--left" type="button" (click)="arrowLogo.prev()">
          <app-icon name="arrow-rounded-left-7x11" size="7x11"></app-icon>
        </button>
        <button class="block-header__arrow block-header__arrow--right" type="button" (click)="arrowLogo.next()">
          <app-icon name="arrow-rounded-right-7x11" size="7x11"></app-icon>
        </button>
      </div>
    </div>
    <owl-carousel-o [options]="customOptions" #arrowLogo>
      <ng-container *ngFor="let logo of slidesLoghi">
        <ng-template carouselSlide>
          <div class="text-center" style="height: 120px;" class="d-flex align-items-center">
            <img [src]="logo.src" style="width:120px; margin: 0 auto;">
          </div>
        </ng-template>
      </ng-container>
    </owl-carousel-o>
  </div>
</div>
