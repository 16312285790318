import { InjectionToken } from '@angular/core';
import { Subject } from 'rxjs';
import { CategoriaFiglioModels } from 'src/app/shared/models/CategoriaFiglioModels';
import { CategoriaGenitoreModels } from 'src/app/shared/models/CategoriaGenitoreModels';
import { UltimaCategoriaModels } from 'src/app/shared/models/UltimaCategoriaModels';

export interface EditCategoryInterface {
    listaProdotti: any;
    ricaricaLista$: Subject<any>;
    listaCategoriePrincipali: CategoriaGenitoreModels[];
    listaCategorieFiglio: CategoriaFiglioModels[];
    listaUltimaCategoria: UltimaCategoriaModels[]
}

export const EDIT_CATEGORY_TOKEN = new InjectionToken<EditCategoryInterface>('EditCategoryInterface')