import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Prodotto } from '../interfaces/product';

@Injectable({
  providedIn: 'root'
})
export class RootService {

  constructor() { }

  recuperaOrdine$: Subject<any>;

  home(): string {
    return '/';
  }

  shop(): string {
    return `/shop/catalog`;
  }

  categoria(category: string): string {
    const basePath = this.shop();
    if (category) {
      return `${basePath}/${category}`;
    }
  }

  product(product: Partial<Prodotto>): string {
    const basePath = '/shop/products';
    if ('slug' in product.data) {
      return `${basePath}/${product}`;
    }
    if ('id' in product) {
      return `${basePath}/${product.id}`;
    }

    throw Error('Provide product with "slug" or "id".');
  }

  prodotto(product: any) {
    const basePath = '/shop/products/details';

    if (!!product.data.slugProdotto) {
      return `${basePath}/${product.data.slugProdotto}`;
    }
    if (!!product.id) {
      return `${basePath}/${product.id}`;
    }

    throw Error('Provide product with "slug" or "id".');
  }

  cart(): string {
    return '/shop/cart';
  }

  checkout(): string {
    return '/shop/cart/checkout';
  }

  login(): string {
    return '/account/login';
  }

  terms(): string {
    return '/site/terms';
  }

  notFound(): string {
    return `/site/not-found`;
  }
}
