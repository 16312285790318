import { NgModule } from '@angular/core';

// modules (angular)
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

// modules (third-party)
import { CarouselModule } from 'ngx-owl-carousel-o';

// modules
import { SharedModule } from '../../shared/shared.module';

// blocks
import { BlockMapComponent } from './block-map/block-map.component';
import { BlockSlideshowComponent } from './block-slideshow/block-slideshow.component';

// components
import { BlockSceltaSistemaComponent } from './block-scelta-sistema/block-scelta-sistema.component';
import { BlockProductsCarouselComponent } from './block-products-carousel/block-products-carousel.component';
import { BlockHeaderComponent } from './block-header/block-header.component';

@NgModule({
    declarations: [
        // blocks
        BlockMapComponent,
        BlockSlideshowComponent,
        // components
        BlockSceltaSistemaComponent,
        BlockProductsCarouselComponent,
        BlockHeaderComponent
    ],
    imports: [
        // modules (angular)
        CommonModule,
        RouterModule,
        // modules (third-party)
        CarouselModule,
        // modules
        SharedModule
    ],
    exports: [
        // blocks
        BlockMapComponent,
        BlockSlideshowComponent,
        BlockProductsCarouselComponent,
        BlockHeaderComponent
    ]
})
export class BlocksModule { }
