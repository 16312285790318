import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { User } from 'firebase';
import { pageService } from 'src/app/modules/account/pages/pageService';
import Swal from 'sweetalert2';
import { DatiNotifica, StatoNotifica } from '../../models/NotificaModels';
import { DatiUtente } from '../../models/UtenteModels';
import { UserRegistrationInterface, USER_REGISTRATION_TOKEN } from './user-registration.interface';


@Component({
  selector: 'app-user-registration',
  templateUrl: './user-registration.component.html',
  styleUrls: ['./user-registration.component.scss'],
  providers: [DatePipe, pageService]
})
export class UserRegistrationComponent implements OnInit {

  addNuovoUtente: FormGroup;
  hide = true;
  public utenteConnesso;

  constructor(
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private fb: FormBuilder,
    public pageService: pageService,
    @Inject(USER_REGISTRATION_TOKEN) public userRegistrationInterface: UserRegistrationInterface,
    public datepipe: DatePipe,
    private firebaseAuth: AngularFireAuth,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.createFormNuovoUtente();
  }

  createFormNuovoUtente() {
    this.addNuovoUtente = this.fb.group({
      Nome: ['', [Validators.required]],
      Cognome: ['', [Validators.required]],
      Email: ['', [Validators.required, Validators.email]],
      Password: ['', [Validators.required]],
    });
  }

  registrati(value) {
    this.firebaseAuth.auth.createUserWithEmailAndPassword(value.Email, value.Password)
      .then((result) => {
        this.addNuovoUtente.reset();
        this.addNuovoUtente.disable();
        this.userRegistrationInterface.success$.next(true);
        let displayName = value.Nome + ' ' + value.Cognome;
        var dataRegistrazione = new Date();
        let user: DatiUtente = {
          uid: result.user.uid,
          email: result.user.email,
          displayName: displayName,
          photoURL: result.user.photoURL,
          dataRegistrazione: dataRegistrazione,
          dataUltimoAccesso: null
        };
        // this.userRegistrationInterface.inviaDato$.next({ Email: result.user.email, Password: value.Password, user: user });
        this.updateUserData(user).then(res => {
          if(result.additionalUserInfo.isNewUser){
          }
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Registrazione Avvenuta!',
            text: 'Grazie per esserci scelto',
            showConfirmButton: false,
            timer: 1500
          });
          this.router.navigate(['/account/dashboard'])
        })
      }).catch(e => {
        if (e.message == 'The email address is badly formatted.') {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            showConfirmButton: true,
            text: "L'indirizzo email inserito non è ben formattato o non è valido.",
            confirmButtonText: 'Chiudi'
          })
        }
        if (e.message == 'There is no user record corresponding to this identifier. The user may have been deleted.') {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            showConfirmButton: true,
            text: "Non esiste alcun utente registrato con la questa email fornita.",
            confirmButtonText: 'Chiudi'
          })
        }
      }
      )
  }

  private updateUserData({ uid, email, displayName, photoURL, dataRegistrazione, dataUltimoAccesso }) {
    const userRef: AngularFirestoreDocument<User> = this.afs.doc(`users/${uid}`);
    const data = {
      uid,
      email,
      displayName,
      photoURL,
      dataRegistrazione,
      dataUltimoAccesso
    }
    this.utenteConnesso = data;
    return userRef.set(this.utenteConnesso, { merge: true })
  }

}
