import { Component, Inject, NgZone, OnInit, PLATFORM_ID } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CartService } from './shared/services/cart.service';
import { GuardsCheckEnd, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { isPlatformBrowser, ViewportScroller } from '@angular/common';
import { CurrencyService } from './shared/services/currency.service';
import { filter, first, takeUntil, tap } from 'rxjs/operators';
import { ThemePalette } from '@angular/material/core';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';
import { Subject, Subscription } from 'rxjs';
// import * as svg4everybody from 'svg4everybody/dist/svg4everybody';
import { NgcCookieConsentService, NgcInitializeEvent, NgcNoCookieLawEvent, NgcStatusChangeEvent } from 'ngx-cookieconsent';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  color: ThemePalette = 'accent';
  mode: ProgressSpinnerMode = 'indeterminate';
  value = 30;

  public showLoader: boolean = true;
  public unsubscribe$ = new Subject();

  cookieMessage = '<b>Il rispetto della tua privacy è la nostra priorità!</b><br> Il sito LaBoutiqueDelCaffe.eu utilizza i cookie, anche di terzi, per offrire servizi in linea con le tue preferenze.';
  cookieDismiss = 'Accetta';
  cookieLinkText = 'Leggi tutto';

  private popupOpenSubscription: Subscription;
  private popupCloseSubscription: Subscription;
  private initializeSubscription: Subscription;
  private statusChangeSubscription: Subscription;
  private revokeChoiceSubscription: Subscription;
  private noCookieLawSubscription: Subscription;

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    private router: Router,
    private toastr: ToastrService,
    private cart: CartService,
    private zone: NgZone,
    private scroller: ViewportScroller,
    private currency: CurrencyService,
    private ccService: NgcCookieConsentService
  ) {
    // svg4everybody();
    if (isPlatformBrowser(this.platformId)) {
      this.zone.runOutsideAngular(() => {
        this.router.events.pipe(filter(event => event instanceof NavigationEnd), first()).subscribe(() => {
          this.showLoader = false;
        });
      });
    }

    this.router.events.pipe(
      filter((v) => v instanceof NavigationStart),
      filter((v: any) => v.url != "/shop/cart/checkout"),
      tap(() => {
        var lista = document.getElementsByTagName('script');
        if (lista[0].outerHTML == '<script src="https://www.paypal.com/sdk/js?client-id=AbnzpjH-qPBvtSMGXUQKfgGafawyDysfeW2VQ7rICRDyy-Zaiadc2Tcm1SkkPlXq4teBxZu8s3yMF1Ro&amp;currency=EUR&amp;locale=it_IT" data-uid-auto="uid_rsqvfvdtpxbrxqcisewywfrqccypzs"></script>') {
          lista[0].parentNode.removeChild(lista[0])
        }
      }),
    ).subscribe();

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        (<any>window).gtag('event', 'page_view', {
          'page_location': document.location.origin + event.urlAfterRedirects,
          'page_title': document.title
        });
        if (document.cookie.includes('cookieconsent_status=allow')) {

        }
      }
    });

    this.router.events.pipe(filter(event => event instanceof NavigationEnd), first()).subscribe(() => {
      const preloader = document.querySelector('.site-preloader');

      if (preloader === null) {
        return;
      }

      preloader.addEventListener('transitionend', (event: Event) => {
        if (event instanceof TransitionEvent && event.propertyName === 'opacity') {
          preloader.remove();
        }
      });
      preloader.classList.add('site-preloader__fade');

      if (getComputedStyle(preloader).opacity === '0' && preloader.parentNode) {
        preloader.parentNode.removeChild(preloader);
      }
    });
  }

  ngOnInit(): void {
    document.getElementsByClassName("cc-allow")[0].innerHTML = 'Accetta';
    this.currency.options = {
      code: 'EUR',
    };


    this.popupOpenSubscription = this.ccService.popupOpen$.subscribe(
      (s) => {
      });

    this.popupCloseSubscription = this.ccService.popupClose$.subscribe(
      (s) => {
      });

    this.initializeSubscription = this.ccService.initialize$.subscribe(
      (event: NgcInitializeEvent) => {
        if (event.status = "allow") {
          (<any>window).gtag('event', 'page_view', {
            'page_location': '/',
            'page_title': document.title
          });
        }
      });

    this.statusChangeSubscription = this.ccService.statusChange$.subscribe(
      (event: NgcStatusChangeEvent) => {
        // you can use this.ccService.getConfig() to do stuff...
      });

    this.revokeChoiceSubscription = this.ccService.revokeChoice$.subscribe(
      () => {
        // you can use this.ccService.getConfig() to do stuff...
      });

    this.noCookieLawSubscription = this.ccService.noCookieLaw$.subscribe(
      (event: NgcNoCookieLawEvent) => {
        // you can use this.ccService.getConfig() to do stuff...
      });

      this.router.events.subscribe((event) => {
        if ((event instanceof NavigationEnd)) {
          this.scroller.scrollToPosition([0, 0]);
        }
      });

    this.cart.onAdding$.pipe(
      filter((v) => !!v),
      tap((product) => {
        this.toastr.success(`Il prodotto "${product}" è stato aggiunto al carrello!`)
      })
    ).subscribe();

    this.cart.onAddingErrors$.pipe(
      tap(() => this.toastr.error(`Non puoi aggiungere al carrello una quantità superiore alla quantità massima disponibile.`, 'Ci dispiace!'))
    ).subscribe();
  }
}
