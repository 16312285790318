import { InjectionToken } from "@angular/core";
import { NuovoOrdineModels } from "src/app/shared/models/NuovoOrdineModels";
import { ProdottoModels } from "src/app/shared/models/ProdottoModels";
import { UtenteModels } from "src/app/shared/models/UtenteModels";

export interface AdministrationInterface {
  listaProdotti: ProdottoModels[];
  listaOrdiniRicevutiCompleta: NuovoOrdineModels[];
  listaOrdiniInAccettazione: NuovoOrdineModels[];
  listaUtentiRegistrati: UtenteModels[];
}

export const ADMINISTRATION_TOKEN = new InjectionToken<AdministrationInterface>('AdministrationInterface');