import { NgModule } from '@angular/core';

// modules (angular)
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';

// modules (third-party)
import { CarouselModule } from 'ngx-owl-carousel-o';
import { ModalModule } from 'ngx-bootstrap/modal';

// directives
import { ClickDirective } from './directives/click.directive';
import { CollapseContentDirective, CollapseDirective, CollapseItemDirective } from './directives/collapse.directive';
import { OutsideTouchClickDirective } from './directives/outside-touch-click.directive';
import { OwlPreventClickDirective } from './directives/owl-prevent-click.directive';
import { TouchClickDirective } from './directives/touch-click.directive';

// components
import { AlertComponent } from './components/alert/alert.component';
import { IconComponent } from './components/icon/icon.component';
import { InputNumberComponent } from './components/input-number/input-number.component';
import { PageHeaderComponent } from './components/page-header/page-header.component';
import { ProductCardComponent, DialogContentExampleDialog } from './components/product-card/product-card.component';
import { ProductComponent } from './components/product/product.component';
import { RatingComponent } from './components/rating/rating.component';

// pipes
import { CurrencyFormatPipe } from './pipes/currency-format.pipe';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DemoMaterialModule } from '../material-module';
import { LoaderComponent } from './components/loader/loader.component';
import { LinkSubmenuComponent } from './components/link-submenu/link-submenu.component';
import { LinkLastChildComponent } from './components/link-last-child/link-last-child.component';
import { UserLoginComponent } from './components/user-login/user-login.component';
import { UserRegistrationComponent } from './components/user-registration/user-registration.component';
import { OnlynumberDirective } from './directives/onlyNumber';
import { EditProductComponent } from './components/edit-product/edit-product.component';
import { ReviewsComponent } from './components/reviews/reviews.component';
import { NuovoIndirizzoPersonaFisicaTemplateComponent } from './components/nuovo-indirizzo-persona-fisica-template/nuovo-indirizzo-persona-fisica-template.component';
import { NuovoIndirizzoPartitaIvaTemplateComponent } from './components/nuovo-indirizzo-partita-iva-template/nuovo-indirizzo-partita-iva-template.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { TemplateFatturazionePartitaIvaComponent } from './components/template-fatturazione-partita-iva/template-fatturazione-partita-iva.component';
import { ProductCardCarouselComponent } from './components/product-card-carousel/product-card-carousel.component';


@NgModule({
    declarations: [
        // directives
        ClickDirective,
        CollapseContentDirective,
        CollapseDirective,
        CollapseItemDirective,
        OutsideTouchClickDirective,
        OwlPreventClickDirective,
        TouchClickDirective,
        // components
        AlertComponent,
        IconComponent,
        InputNumberComponent,
        PageHeaderComponent,
        ProductCardComponent,
        ProductComponent,
        RatingComponent,
        // pipes
        CurrencyFormatPipe,
        DialogContentExampleDialog,
        LoaderComponent,
        LinkSubmenuComponent,
        LinkLastChildComponent,
        UserLoginComponent,
        UserRegistrationComponent,
        OnlynumberDirective,
        EditProductComponent,
        ReviewsComponent,
        NuovoIndirizzoPersonaFisicaTemplateComponent,
        NuovoIndirizzoPartitaIvaTemplateComponent,
        TemplateFatturazionePartitaIvaComponent,
        ProductCardCarouselComponent
    ],
    imports: [
        // modules (angular)
        CommonModule,
        DemoMaterialModule,
        ReactiveFormsModule,
        FormsModule,
        HttpClientModule,
        RouterModule,
        // modules (third-party)
        CarouselModule,
        ModalModule.forRoot(),
        AngularEditorModule
    ],
    exports: [
        // directives
        ClickDirective,
        CollapseContentDirective,
        CollapseDirective,
        CollapseItemDirective,
        OutsideTouchClickDirective,
        OwlPreventClickDirective,
        TouchClickDirective,
        // components
        AlertComponent,
        IconComponent,
        InputNumberComponent,
        PageHeaderComponent,
        ProductCardComponent,
        ProductComponent,
        RatingComponent,
        // pipes
        CurrencyFormatPipe,
        LoaderComponent,
        LinkSubmenuComponent,
        LinkLastChildComponent,
        UserLoginComponent,
        UserRegistrationComponent,
        OnlynumberDirective,
        EditProductComponent,
        ReviewsComponent,
        NuovoIndirizzoPersonaFisicaTemplateComponent,
        NuovoIndirizzoPartitaIvaTemplateComponent,
        TemplateFatturazionePartitaIvaComponent,
        ProductCardCarouselComponent
    ],
})
export class SharedModule { }
