
export class NotificaModels {
  id: string;
  data: DatiNotifica
}

export class DatiNotifica{
  nomeProdotto: string;
  quantita: number;
  dataNotifica: Date;
  idProdotto: string;
  scelta: StatoNotifica;
  idUtente: string;
  idCarrello: string;
  immagineProdotto: string;
  notificaInviataAlCliente: boolean;
  isNotificaSistema: boolean;
  idOrdine: string;
  isVisualizzata: boolean;
  corriereAffidato: string;
  codiceSpedizione: string;
}

export enum StatoNotifica {
  Inviata,
  ProdottoEsaurito,
  RichiestaAvvisoProdottoDisponibile,
  ProdottoDisponibile,
  OrdineConfermato,
  OrdineRifiutato,
  OrdineSpedito,
  NotificaBenvenuto,
  RitiroInNegozioAccettato,
  RitiroInNegozioPronto,
  OrdineConcluso
}
