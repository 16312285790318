<div class="product product--layout--{{ layout }}" *ngIf="product">
  <div class="product__content" style="display: -ms-grid;">
    <div class="product__gallery d-flex justify-content-center">
      <div class="immagineProdotto"
        [ngStyle]="{ 'background-image': 'url(' + product[0]?.data?.immagineProdotto + ')'}"></div>
    </div>
    <div>
      <div class="product__info">
        <h1 class="product__name">{{ product[0]?.data?.NomeProdotto }}</h1>
        <div class="product__rating">
          <div class="product__rating-stars d-flex">
            <app-rating [value]="product[0]?.data?.stelle"></app-rating>
            <div class="product-card__rating-legend"><b>{{ product[0]?.data?.reviews }}</b> Recensioni</div>
          </div>
        </div>
        <div class="product__description mt-4" [innerHTML]="product[0]?.data?.descrizione">
        </div>
      </div>
      <div class="product__sidebar">
        <div class="product__meta">
          <li class="product__meta-availability">Disponibilità: <span
              class="text-success">{{product[0]?.data.disponibilitaReale}} prodotti</span>
          </li>
        </div>

        <div class="product__prices mb-3">

          <label class="product__option-label">Prezzo</label><br>
          <span class="product__new-price" style="color: #422951!important;">{{ product[0]?.data?.prezzo|currencyFormat
            }}</span>
        </div>
        <form class="product__options">
          <div class="form-group product__option">
            <label class="product__option-label">Quantità</label>
            <div class="product__actions" [formGroup]="aggiungiAlCarrello">
              <div class="product__actions-item">
                <div input-number aria-label="Quantity" class="product__quantity" size="lg" formControlName="quantity"
                  [min]="1" [max]="product[0]?.data?.disponibilitaReale" #qnt></div>
              </div>
              <div class="product__actions-item product__actions-item--addtocart">
                <button type="button" class="btn btn-primary btn-lg text-white"
                  [disabled]="disabilitaBtn || aggiungiAlCarrello?.invalid" appClick
                  (click)="addToCart(qnt.value)">Aggiungi al Carrello</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>

  </div>
  <div class="block block-banner mt-md-4 mt-5" *ngIf="productCarousel?.length >= 1">
    <div class="container-xxl">
      <div class="block-header">
        <h3 class="block-header__title">Prodotti correlati</h3>
        <div class="block-header__divider"></div>
        <div class="block-header__arrows-list">
          <button class="block-header__arrow block-header__arrow--left" type="button" (click)="arrow.prev()">
            <app-icon name="arrow-rounded-left-7x11" size="7x11"></app-icon>
          </button>
          <button class="block-header__arrow block-header__arrow--right" type="button" (click)="arrow.next()">
            <app-icon name="arrow-rounded-right-7x11" size="7x11"></app-icon>
          </button>
        </div>
      </div>
      <owl-carousel-o [options]="customOptions" appOwlPreventClick #arrow>
        <ng-container *ngIf="!showLoaderProdottiCorrelati else showLoaderCorrelati">
          <ng-container *ngFor="let slide of productCarousel">
            <ng-template carouselSlide>
              <div class="products-view__list products-list w-100" [attr.data-layout]="'grid-3-sidebar'"
                [attr.data-with-features]="false">
                <div class="products-list__body w-100" id="prodottoCorrelato">
                  <div class="products-list__item" [ngClass]="productCarousel?.length >= 2 ? 'w-100' : ''">
                    <app-product-card [product]="slide" [isInOrdiniEffettuati]="false" [elementoCarrello]="slide">
                    </app-product-card>
                  </div>
                </div>
              </div>
            </ng-template>
          </ng-container>
        </ng-container>
      </owl-carousel-o>
    </div>
  </div>

  <h4 class="text-center mt-4 pt-2 mt-md-3 pt-md-3 pt-lg-5 mt-lg-5">Recensioni</h4>
  <div class="row">
    <div class="product-tabs__content w-100 pl-4 pr-4 pl-md-5 pr-md-5">
      <app-reviews [Prodotto]="product[0]"></app-reviews>
    </div>
  </div>
</div>

<ng-template #showLoaderCorrelati>
  <div class="row mt-4 mb-4 justify-content-center">
    <app-loader></app-loader>
  </div>
</ng-template>
