<div class="widget-filters widget pl-0 pr-0" [ngClass]="{
    'widget-filters--offcanvas--always': offcanvas === 'always',
    'widget-filters--offcanvas--mobile': offcanvas === 'mobile'
}" appCollapse>
  <ng-container *ngIf="showLoader">
    <div class="d-flex justify-content-center align-items-center">
      <div class="spinner-grow text-primary" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="!showLoader">
    <h4 class="widget-filters__title widget__title pl-3">Trova il il tuo caffè</h4>
    <span></span>
    <ul class="mobile-links mobile-links--level--0" style="padding-left: 0!important;" appCollapse>
      <li>
        <div class="mobile-links__item" style="border-top: 1px solid #ebebeb;">
          <div class="mobile-links__item-title">
            <a style="cursor: pointer;" (click)="goToTutti()" class="mobile-links__item-link">Tutti i Prodotti</a><br>
          </div>
        </div>
      </li>
      <li *ngFor="let link of items">
        <div class="mobile-links__item" appCollapseItem="mobile-links__item--open" #item="appCollapseItem">
          <div class="mobile-links__item-title">
            <a class="mobile-links__item-link" (click)="onItemClick(link)">{{ link.data.nomeCategoria }}</a>
            <button *ngIf="link.data.sottoCategoria == 'true'" class="mobile-links__item-toggle" type="button"
              (click)="item.toggle()">
              <app-icon class="mobile-links__item-arrow" name="arrow-rounded-down-12x7" size="12x7"></app-icon>
            </button>
          </div>
          <div *ngIf="link.data.sottoCategoria == 'true'" class="mobile-links__item-sub-links" appCollapseContent>
            <app-link-submenu [subCategory]="link" [isAdministration]="false" [isMobile]="false" (dato)="inviaDato($event)"></app-link-submenu>
          </div>
        </div>
        <div *ngIf="link.type === 'divider'" class="mobile-links__divider"></div>
      </li>
      <li>
        <div class="mobile-links__item">
          <div class="mobile-links__item-title">
            <a class="mobile-links__item-link" routerLink="/shop/catalog/in_offerta" >In Offerta</a>
          </div>
        </div>
      </li>
    </ul>
  </ng-container>



</div>
