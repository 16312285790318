<!-- .block-slideshow -->
<div class="block-slideshow block"
  [ngClass]="{'block-slideshow--layout--full': !withDepartments, 'block-slideshow--layout--with-departments': withDepartments}">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 p-0">
        <div class="block-slideshow__body" style="margin-left:-15px!important">
          <owl-carousel-o *ngIf="slides?.length" [options]="options" appOwlPreventClick>
            <ng-template *ngFor="let slide of slides" carouselSlide>
              <div class="block-slideshow__slide">
                <div class="block-slideshow__slide-image block-slideshow__slide-image--desktop"
                  [ngStyle]="{'background-image': 'url('+(withDepartments ? slide.image_classic : slide.image_full)+')'}">
                </div>
                <div class="block-slideshow__slide-image block-slideshow__slide-image--mobile"
                  [ngStyle]="{'background-image': 'url('+slide.image_mobile+')'}"></div>
                <div class="block-slideshow__slide-content">
                  <div class="block-slideshow__slide-title customTitle">{{slide.title}}</div>
                  <div class="block-slideshow__slide-text mt-3 pt-2"> {{slide.text}}
                  </div>
                </div>
              </div>
            </ng-template>
          </owl-carousel-o>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- .block-slideshow / end -->
