import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { CarrelloFire } from '../../models/CarrelloFire';
import { ProdottoModels } from '../../models/ProdottoModels';
import { CartService } from '../../services/cart.service';
import { RootService } from '../../services/root.service';

@Component({
  selector: 'app-product-card-carousel',
  templateUrl: './product-card-carousel.component.html',
  styleUrls: ['./product-card-carousel.component.scss']
})
export class ProductCardCarouselComponent implements OnInit {

  @Input() product: ProdottoModels;
  @Input() layout: 'grid-sm' | 'grid-nl' | 'grid-lg' | 'list' | 'horizontal' | null = null;
  @Input() utente;
  @Input('isInOrdiniEffettuati') isInOrdiniEffettuati: boolean;
  @Input('elementoCarrello') elementoCarrello: CarrelloFire;
  @Input('isDragging') isDragging: boolean;

  addingToCart = false;


  constructor(
    private cd: ChangeDetectorRef,
    public root: RootService,
    public cart: CartService
  ) { }

  ngOnInit(): void {
  }

  recuperaCategoria(value) {
    let primaOperazione = value.replace(/_/g, ' ') || null;
    let label = primaOperazione.charAt(0).toUpperCase() + primaOperazione.substring(1).toLowerCase() || null;
    return label
  }


  addToCart(): void {
    if (this.addingToCart) {
      return;
    }
    this.addingToCart = true;
    this.cart.add(this.product, 1).subscribe({
      complete: () => {
        this.addingToCart = false;
        this.cd.markForCheck();
      }
    });
  }

  naviga(product){
    if(!this.isDragging){
      this.root.prodotto(product)
    } else {event.stopPropagation(); event.preventDefault()}
  }

}
