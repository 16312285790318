import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { pageService } from 'src/app/modules/account/pages/pageService';
import { filter, tap } from 'rxjs/operators';
import { isAdmin } from 'src/data/isAdmin';
import { DatePipe } from '@angular/common';
import { AccountMenuService } from './account-menu.service';
import { USER_LOGIN_TOKEN } from 'src/app/shared/components/user-login/user-login.interface';
import { DatiUtente } from 'src/app/shared/models/UtenteModels';

@Component({
  selector: 'app-account-menu',
  templateUrl: './account-menu.component.html',
  styleUrls: ['./account-menu.component.scss'],
  providers: [pageService, DatePipe, AccountMenuService,
    { provide: USER_LOGIN_TOKEN, useExisting: AccountMenuService }
  ]
})
export class AccountMenuComponent implements OnInit {
  @Output() closeMenuAccount: EventEmitter<void> = new EventEmitter<void>();

  public utenteConnesso: DatiUtente;
  hide = true;
  public isAdmin = isAdmin;

  public listaMsg;
  public listaOrdiniRicevuti;
  public countNotifiche: number;

  constructor(
    public authService: AuthService,
    private pageService: pageService,
    public datepipe: DatePipe,
    public AccountMenuService: AccountMenuService
  ) {
  }


  ngOnInit(): void {
    this.authService.user$.pipe(
      tap((v: DatiUtente) => this.utenteConnesso = v),
      filter((v) => !!v),
      tap((v) => {
        if (v.email == isAdmin) {
          this.pageService.getAllMessaggiNonVisualizzati().pipe(
            filter((x) => !!x),
            tap((v) => this.listaMsg = v.length)
          ).subscribe();
          this.pageService.getAllOrdiniRicevutiInAccettazione().pipe(
            filter((x) => !!x),
            tap((v) => this.listaOrdiniRicevuti = v.length)
          ).subscribe();
        }
        this.pageService.getNotificaByIdUtenteNoAzione(v.uid).pipe(
          filter((v) => !!v),
          tap((v) => {
            this.countNotifiche = v.length;
          })
        ).subscribe();
      })
    ).subscribe();
    this.AccountMenuService.chiudiMenu$.pipe(
      tap(() => this.closeMenuAccount.emit())
    ).subscribe();
  }

  logOut() {
    this.closeMenuAccount.emit();
    this.authService.signOut();
  }

  chiudiMenu() {
    this.closeMenuAccount.emit();
  }
}
