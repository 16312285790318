<!-- .dropcart -->
<div class="dropcart">
  <ng-container *ngIf="!!utente else notUtente">
    <div class="dropcart__empty" *ngIf="carrelloFire?.length == 0 else carrello">
      Il tuo carrello è vuoto!
    </div>
    <ng-template #carrello>
      <div class="dropcart__products-list">
        <div *ngFor="let item of carrelloFire" class="dropcart__product">
          <div class="dropcart__product-image product-image">
            <a *ngIf="item.data?.immagineProdotto?.length" class="product-image__body">
              <img class="product-image__img immagineCarrello"
                [ngStyle]="{ 'background-image': 'url(' + item?.data?.immagineProdotto + ')'}" alt="">
            </a>
          </div>
          <div class="dropcart__product-info">
            <div class="dropcart__product-name">
              <a>{{ item?.data?.NomeProdotto }}</a>
            </div>
            <div class="dropcart__product-meta">
              <span class="dropcart__product-quantity">{{ item.data.quantita }}</span> ×
              <span class="dropcart__product-price">{{ item?.data?.prezzo |currencyFormat }}</span>
            </div>
          </div>

          <button type="button" style="min-width: 30px!important"
            class="dropcart__product-remove btn btn-light btn-sm btn-svg-icon" (click)="removeFire(item)"
            [ngClass]="{'btn-loading': removedItems.includes(item)}">
            <app-icon name="cross-10" size="10"></app-icon>
          </button>
        </div>
      </div>

      <div class="dropcart__totals">
        <table>
          <tr>
            <th>Sub Totale</th>
            <td>{{ sommaProdottiOrdine|currencyFormat }}</td>
          </tr>
          <tr>
            <th>Spedizione</th>
            <td>{{costoSpedizione > 0 ? (costoSpedizione |currencyFormat) : 'Gratis ' }}</td>
          </tr>
          <tr>
            <th>Totale</th>
            <td>{{ costoFinaleFire|currencyFormat }}</td>
          </tr>
        </table>
      </div>

      <div class="dropcart__buttons">
        <a class="btn btn-secondary" [routerLink]="root.cart()" (click)="closeMenu.emit()">carrello</a>
        <a class="btn btn-primary text-white" [routerLink]="root.checkout()" (click)="closeMenu.emit()">Checkout</a>
      </div>
    </ng-template>
  </ng-container>
  <ng-template #notUtente>
    <div class="dropcart__empty" *ngIf="!(cart.items$|async).length">
      Il tuo carrello è vuoto!
    </div>
    <ng-container *ngIf="(cart.items$|async).length">
      <div class="dropcart__products-list">
        <div *ngFor="let item of cart.items$|async" class="dropcart__product">
          <div class="dropcart__product-image product-image">
            <a *ngIf="item.cartItem.product?.data?.immagineProdotto?.length" class="product-image__body">
              <img class="product-image__img immagineCarrello"
                [ngStyle]="{ 'background-image': 'url(' + item?.cartItem?.product?.data?.immagineProdotto + ')'}"
                alt="">
            </a>
          </div>
          <div class="dropcart__product-info">
            <div class="dropcart__product-name">
              <a>{{ item?.cartItem?.product?.data?.NomeProdotto }}</a>
            </div>
            <div class="dropcart__product-meta">
              <span class="dropcart__product-quantity">{{ item.quantity }}</span> ×
              <span class="dropcart__product-price">{{ item?.cartItem?.product?.data?.prezzo |currencyFormat }}</span>
            </div>
          </div>

          <button type="button" style="min-width: 30px!important"
            class="dropcart__product-remove btn btn-light btn-sm btn-svg-icon" (click)="remove(item)"
            [ngClass]="{'btn-loading': removedItems.includes(item)}">
            <app-icon name="cross-10" size="10"></app-icon>
          </button>
        </div>
      </div>

      <div class="dropcart__totals">
        <table>
          <tr>
            <th>Sub Totale</th>
            <td>{{ sommaProdottiOrdine|currencyFormat }}</td>
          </tr>
          <tr>
            <th>Spedizione</th>
            <td>{{costoSpedizione > 0 ? (costoSpedizione |currencyFormat) : 'Gratis ' }}</td>
          </tr>
          <tr>
            <th>Totale</th>
            <td>{{ costoFinale|currencyFormat }}</td>
          </tr>
        </table>
      </div>

      <div class="dropcart__buttons">
        <a class="btn btn-secondary" [routerLink]="root.cart()" (click)="closeMenu.emit()">carrello</a>
        <a class="btn btn-primary text-white" [routerLink]="root.checkout()" (click)="closeMenu.emit()">Checkout</a>
      </div>
    </ng-container>
  </ng-template>
</div>
<!-- .dropcart / end -->