<ng-template #buttonContent>
  <span class="indicator__area" *ngIf="!label">
    <app-icon *ngIf="icon" [ngClass]="{'indicator__icon': iconWhenOpen}" [name]="icon" size="20"></app-icon>
    <app-icon *ngIf="iconWhenOpen" class="indicator__icon indicator__icon--open" [name]="iconWhenOpen" size="20">
    </app-icon>
    <span *ngIf="counter !== null" class="indicator__value">{{ counter }}</span>
  </span>
  <div *ngIf="label" class="d-flex align-items-center">
    <span class="nav-links__item-link">{{label}}</span>
    <app-icon class="pl-2 pr-2" *ngIf="icon" [ngClass]="{'indicator__icon': iconWhenOpen}" [name]="icon" size="8"></app-icon>
    <app-icon class="pl-2 pr-2" *ngIf="iconWhenOpen" class="indicator__icon indicator__icon--open" [name]="iconWhenOpen" size="15">
    </app-icon>
  </div>
</ng-template>

<button *ngIf="link === null" type="button" class="indicator__button" (click)="onClick($event)">
  <ng-container *ngTemplateOutlet="buttonContent"></ng-container>
</button>

<a *ngIf="link !== null && trigger === 'click'" [href]="link" class="indicator__button" (click)="onClick($event)"
  (appTouchClick)="onTouchClick($event)">
  <ng-container *ngTemplateOutlet="buttonContent"></ng-container>
</a>

<a *ngIf="link !== null && trigger !== 'click'" [routerLink]="link" class="indicator__button"
  (appTouchClick)="onTouchClick($event)">
  <ng-container *ngTemplateOutlet="buttonContent"></ng-container>
</a>

<div class="indicator__dropdown" #dropdownElement [ngClass]="ie11 ? 'rightFixIE11': 'rightAuto'">
  <ng-content></ng-content>
</div>